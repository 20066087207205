import React from "react";
import { BrowserRouter, Navigate, Route, Routes } from "react-router-dom";
import App from "../App";
import Dashboard from "../screens/dashboard/dashboard";
import Home from "../screens/home/home";
import Classifier from "../screens/classifier/Classifier";
import Legal from "../screens/legal/Legal";

export function Approutes() {
	return (
		<BrowserRouter>
			<Routes>
				<Route path='*' element={<Home />} />
				<Route path='/' element={<Home />} />
				<Route path='/home' element={<Home />} />
				{/* <Route path='/dashboard' element={<Dashboard />} />
				<Route path='/classifier' element={<Classifier />} /> */}
				<Route path='/legal' element={<Legal />} />
			</Routes>
		</BrowserRouter>
	);
}
