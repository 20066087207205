import React from "react";
import { Approutes } from "./App-route/app-route";
import "./App.css";
import Navigation from "./components/navigation/navigation";
import Home from "./screens/home/home";
import { inject } from "@vercel/analytics";

inject();

function App() {
	return (
		<>
			{/* <Navigation />
         <Home /> */}
			<Approutes></Approutes>
		</>
	);
}

export default App;
