import styled from "styled-components";
import authBackground from "../../assets/signup_bg.jpg";

const StyledHome = styled.div`
	background-color: #fafcff;

	.looper--bg {
		bottom: 0px;
		z-index: 1;
	}

	.hero__section-content {
		position: relative;
		z-index: 9;
	}

	.service-card {
		width: 29%;
		min-height: 370px;
		z-index: 9;
		cursor: pointer;
		background-color: $white;
		transition: 0.2s ease-in;
		&:hover {
			box-shadow: 0px 15px 30px rgba(12, 28, 58, 0.05);
			border-radius: 50px 0px;
			background-color: var(--dark);
			color: white;
		}
	}

	.left-stroke {
		top: 40px;
	}

	.right-stroke {
		bottom: 80px;
	}

	.right-stroke {
		transform: rotate(180deg);
	}

	.service {
		&__content {
			z-index: 999 !important;
		}
	}

	.cases-card {
		width: 29%;
		min-height: 330px;
		border: 1px solid #d9d9d9;
		border-radius: 4px;
		color: var(--black);
		cursor: pointer;
		transition: 0.2s ease-in;
		&:hover {
			background-color: var(--dark);
			color: white;
		}
	}

	.case-separator {
		top: 56%;
	}

	.auth__section {
		background-image: url(${authBackground});
		background-size: 100%;
		background-repeat: cover;
		height: 100%;
		&-btn {
			right: 10px;
		}
	}

	a {
		text-decoration: none;
	}

	@keyframes ani1 {
		0% {
			opacity: 0.83;
		}
		25% {
			opacity: 0.67;
		}
		50% {
			opacity: 0.5;
		}
		75% {
			opacity: 0.33;
		}
		100% {
			opacity: 0.17;
		}
	}

	.opacity-animation-1 {
		animation: ani1 3s infinite;
		animation-delay: 550ms;
	}
	.opacity-animation-2 {
		animation: ani1 3s infinite;
		animation-delay: 450ms;
	}
	.opacity-animation-3 {
		animation: ani1 3s infinite;
		animation-delay: 350ms;
	}
	.opacity-animation-4 {
		animation: ani1 3s infinite;
		animation-delay: 250ms;
	}
	.opacity-animation-5 {
		animation: ani1 3s infinite;
	}
	@media screen and (max-width: 1024px) {
		.service-card {
			width: 50%;
		}

		.cases-card {
			width: 50%;
		}
	}

	// @media screen and (max-width: 800px) {
	// 	.service-card {
	// 		width: 100%;
	// 	}
	// 	.cases-card {
	// 		width: 100%;
	// 	}
	// }

	/* Add styles specifically for mobile screens */

	@media screen and (max-width: 800px) {
		/* Make sure text and other elements are easy to read on small screens */
		font-size: 16px;
		line-height: 1.5;
		min-height: 200px;

		/* Make sure images and other media elements don't overflow the screen */
		img,
		video,
		iframe {
			max-width: 100%;
		}

		/* Make sure grid and other layout elements adapt to small screens */
		.grid,
		.layout {
			flex-wrap: wrap;
		}
	}
`;

export default StyledHome;
