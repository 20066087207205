import React from "react";
import StyledHome from "./home.style";
import { Button, Accordion } from "react-bootstrap";
import {
	ArrowRight,
	Twitter,
	Instagram,
	Facebook,
	Check2,
} from "react-bootstrap-icons";
import logo from "../../assets/logo_dark.png";
import brain from "../../assets/brain.png";
import looperBg from "../../assets/looper_bg.png";
import cardImg1 from "../../assets/card_img_1.png";
import cardImg2 from "../../assets/card_img_2.png";
import cardImg3 from "../../assets/card_img_3.png";
import bgStroke from "../../assets/bg__stroke.png";
import services from "../../assets/service.png";
import bgService from "../../assets/bg_service.png";
import cardImg4 from "../../assets/Group 85.png";
import cardImg5 from "../../assets/Group 86.png";
import cardImg6 from "../../assets/Group 85 (1).png";
import cardImg7 from "../../assets/Group 86 (1).png";
import cardImg8 from "../../assets/Group 85 (2).png";
import cardImg9 from "../../assets/Group 86 (2).png";
import caseHeader1 from "../../assets/Group 104.png";
import caseHeader2 from "../../assets/Group 105.png";
import caseSeparator from "../../assets/Group 204.png";
import casefooter1 from "../../assets/Group 106.png";
import casefooter5 from "../../assets/Group 107.png";
import signUpPic from "../../assets/group 12.png";
import Navigation from "../../components/navigation/navigation";
import Navbar from "../../components/Navbar";
import isoSvg from "../../assets/Isolation-Mode.svg";
import MailchimpSignup from "../../components/components/signupform/signupform";

import { Zoom } from "react-awesome-reveal";
import { Slide } from "react-awesome-reveal";

export default function Home() {
	const cards = [
		{
			image: cardImg1,
			title: "Recommendation Engine",
			description:
				"Want to build a recommendation algorithm for your audience? Build one super fast!",
		},
		{
			image: cardImg2,
			title: "Implement Fraud detection",
			description:
				"Struggling with Fraud in any transactional section of your Business? Make a Fraud detection Engine now!",
		},
		{
			image: cardImg3,
			title: "Customer Acquisition Cost",
			description:
				"Not Sure on Customer acquisition costs? Predict for the future based on past data :)",
		},
	];

	const cardTwo = [
		{
			image: cardImg4,
			title: "Predict Sales Revenue",
			description:
				"Wondering what the figures might look like for the next quarter? Let AI help you figure it out!",
		},
		{
			image: cardImg5,
			title: "Portfolio Optimization",
			description:
				"Asset management can be overwhelming. Don't worry, Codenull is ready to help! with asset value history it can optimize your portfolio for the best returns.",
		},
		{
			image: cardImg6,
			title: "Logistics Cost",
			description:
				"Train an AI model on past data of logistic costs and get accurate predictions on the same for future.",
		},
		{
			image: cardImg7,
			title: "Medical Classification",
			description:
				"Got past data on a disease and want to predict if a patient is suffering from it? Build an AI model to help predict.",
		},
		{
			image: cardImg8,
			title: "Robo Advisor",
			description:
				"Confused where to invest in? Make a Robo advisor that helps you purely customized to your needs.",
		},
		{
			image: cardImg9,
			title: "Any Possible AI Use Case",
			description:
				"We solve ANY possible AI use case. Get in touch, let's make AI models customized for your business.",
		},
	];

	const service = [
		{
			key: "0",
			title: "1. Prepare Your Data",
			info: "AI models are trained on your past data to predict the future or get recommendations.",
		},
		{
			key: "1",
			title: "2. Train Your Ai Model",
			info: "One step Train process to kick start AI model training on your data",
		},
		{
			key: "2",
			title: "3. Personalize Model For You",
			info: "Use this personalized AI model specially built for you!",
		},
	];
	// #devvvv
	return (
		<StyledHome>
			<div className='z-10 position-sticky top-0'>
				<Navigation />
				{/* #done by chojay */}
				{/* <Navbar /> */}
				{/* #done by smitha */}
			</div>
			<div className='hero__section w-100 bg-dark position-relative'>
				<div className='hero__section-content col-10 mx-auto pt-30 pb-100 bg-transparent text-white d-flex flex-wrap justify-content-between align-items-center'>
					<div className='col-12 col-md-6'>
						<h1 className='fw-bold fs--clash-display'>Codenull.ai</h1>
						<p className='display-30 mb-10 fs--clash-display text--white-60'>
							No-Code AI for Free!
						</p>
						<p className='mb-30 text--white-60'>
							Build Any AI model without writing a single line of code. Use
							these models for Portfolio optimization, Robo-advisors,
							Recommendation Engines, Fraud detection and much more
						</p>
						<a href='https://codenullai.streamlit.app'>
							<Button className='btn-primary d-flex align-items-center gap-16'>
								Try Free Beta
								<ArrowRight color='#FFFFFF' />
							</Button>
						</a>
					</div>
					<div className='col-12 col-md-6'>
						<Zoom>
							<img
								src={brain}
								alt='brain'
								width='100%'
								className='animate__zoomIn'
							/>
						</Zoom>
					</div>
				</div>
				<img
					src={looperBg}
					alt='background'
					className='position-absolute looper--bg'
					width='100%'
					height='700'
				/>
			</div>
			<div className='w-100 position-relative'>
				<img
					src={bgStroke}
					alt=''
					width='602'
					className='position-absolute start-0 left-stroke'
				/>
				<div className='py-100 col-10 mx-auto'>
					<h2 className='text-center text-black mb-16'>Our Main Service</h2>
					<div className='br--b-dark col-1 mx-auto'></div>
					<Zoom cascade>
						<div className='d-flex flex-wrap align-items-center justify-content-center gap-50 py-74'>
							{cards.map(({ image, title, description }) => (
								<div className='service-card px-24 py-74 text-center'>
									<img src={image} alt='icon' width='60' height='60' />
									<h4 className='mt-40 mb-30'>{title}</h4>
									<p className='text-grey'>{description}</p>
								</div>
							))}
						</div>
					</Zoom>
				</div>
				<img
					src={bgStroke}
					alt=''
					width='602'
					className='position-absolute end-0 right-stroke'
				/>
			</div>
			<div className='w-100 position-relative service'>
				<h2 className='text-center text-black mb-16'>
					Leverage your business with AI
				</h2>
				<div className='br--b-dark col-1 mx-auto'></div>
				<Slide direction='left'>
					<div className='service__content pt-30 col-10 mx-auto d-flex flex-wrap justify-content-between align-items-start'>
						<div className='col-12 col-md-5 d-flex flex-column gap-10 mt-40'>
							{service.map(({ key, title, info }) => (
								<Accordion defaultActiveKey='0'>
									<Accordion.Item eventKey={key}>
										<Accordion.Header>{title}</Accordion.Header>
										<Accordion.Body>{info}</Accordion.Body>
									</Accordion.Item>
								</Accordion>
							))}
						</div>
						<img
							src={services}
							alt='service'
							width='100%'
							className='col-12 col-md-4'
						/>
					</div>
				</Slide>
				<img
					src={bgService}
					alt=''
					width='100%'
					height='230'
					className='service__bg'
				/>
			</div>
			<div className='w-100 bg-white py-30 position-relative my-50'>
				<div className='w-100 position-absolute d-flex justify-content-between'>
					<img src={caseHeader2} alt='' width='395' />
					<img
						src={caseHeader1}
						alt=''
						width='395'
						className='d-none d-sm-block'
					/>
				</div>
				<div className='w-100 position-absolute case-separator'>
					<img src={caseSeparator} alt='' width='100%' height='31' />
				</div>
				<div className='w-100 position-absolute bottom-0 d-flex justify-content-between'>
					<img src={casefooter1} alt='' width='314' />
					<img
						src={casefooter5}
						alt=''
						width='314'
						className='d-none d-sm-block'
					/>
				</div>
				<h2 className='text-center mb-16'>Other Use Cases</h2>
				<div className='br--b-dark col-1 mx-auto'></div>
				<Zoom>
					<div className='col-10 mx-auto d-flex flex-wrap align-items-center justify-content-center gap-50 mt-50 py-74'>
						{cardTwo.map(({ image, title, description }) => (
							<div className='cases-card d-flex flex-column align-items-center p-24 text-center'>
								<img src={image} alt='icon' width='60' height='60' />
								<h4 className='mt-40 mb-30'>{title}</h4>
								<p className='text-grey'>{description}</p>
							</div>
						))}
					</div>
				</Zoom>
			</div>
			<div className='auth__section py-42 w-100 my-50' id='signup'>
				<div className='col-12 px-30 mx-auto d-flex flex-col flex-md-row align-items-center justify-content-between'>
					<svg
						width='453'
						height='444'
						viewBox='0 0 453 444'
						fill='none'
						xmlns='http://www.w3.org/2000/svg'>
						<path
							d='M373.702 234.675C456.39 282.495 456.819 360.304 374.646 408.124C292.474 455.944 158.38 455.901 75.6502 408.124C-7.08017 360.347 -7.50927 282.495 74.5774 234.675C156.664 186.855 290.972 186.898 373.702 234.675ZM77.9673 406.749C159.496 454.011 291.444 454.011 372.329 406.749C453.215 359.488 452.828 283.096 371.385 236.007C289.942 188.917 157.866 188.745 76.9803 236.007C-3.90485 283.268 -3.47576 359.703 77.9673 406.749Z'
							fill='#2AFBFE'
						/>
						<path
							d='M252.654 305.137C268.187 314.117 268.273 328.725 252.654 337.705C243.959 342.131 234.344 344.438 224.591 344.438C214.837 344.438 205.222 342.131 196.527 337.705C180.994 328.725 180.908 314.117 196.527 305.137C205.222 300.711 214.837 298.404 224.591 298.404C234.344 298.404 243.959 300.711 252.654 305.137ZM199.016 336.33C206.993 340.39 215.814 342.506 224.762 342.506C233.71 342.506 242.532 340.39 250.508 336.33C264.625 328.123 264.583 314.847 250.508 306.254C242.532 302.194 233.71 300.078 224.762 300.078C215.814 300.078 206.993 302.194 199.016 306.254C184.856 314.461 184.899 327.737 199.016 336.33Z'
							fill='#2AFBFE'
						/>
						<path
							d='M277.455 290.701C306.72 307.629 306.892 335.17 277.799 352.098C248.706 369.026 201.205 369.026 171.94 352.098C142.675 335.17 142.504 307.629 171.597 290.701C200.69 273.773 248.148 273.773 277.455 290.701ZM174.257 350.852C202.234 367.007 247.633 367.007 275.439 350.852C303.244 334.697 303.159 308.317 275.138 292.119C247.118 275.921 201.719 275.921 173.871 292.119C146.022 308.317 146.237 334.654 174.257 350.852Z'
							fill='#2AFBFE'
						/>
						<path
							d='M300.326 277.382C342.292 301.657 342.55 341.142 300.841 365.417C259.133 389.692 190.992 389.692 149.026 365.417C107.06 341.142 106.846 301.657 148.511 277.382C190.177 253.107 258.318 253.107 300.326 277.382ZM151.343 364.085C192.022 387.63 258.06 387.63 298.524 364.085C338.988 340.54 338.731 302.259 298.052 278.714C257.374 255.169 191.292 255.169 150.828 278.714C110.364 302.259 110.622 340.54 151.343 364.085Z'
							fill='#2AFBFE'
						/>
						<path
							d='M326.673 262.043C383.271 294.783 383.615 347.973 327.36 380.756C271.105 413.538 179.278 413.495 122.679 380.756C66.0813 348.016 65.7809 294.826 122.036 262.043C178.291 229.261 270.075 229.519 326.673 262.043ZM124.997 379.424C180.307 411.433 270.075 411.39 325.043 379.424C380.01 347.458 379.71 295.384 324.399 263.418C269.088 231.452 179.278 231.452 124.31 263.418C69.3424 295.384 69.6428 347.458 124.997 379.424Z'
							fill='#2AFBFE'
						/>
						<path
							d='M350.917 247.951C420.989 288.424 421.332 354.332 351.733 394.848C282.133 435.364 168.507 435.364 98.5641 394.848C28.6209 354.332 28.106 288.424 97.7059 247.951C167.306 207.478 280.845 207.435 350.917 247.951ZM100.71 393.516C169.365 433.302 280.931 433.302 349.587 393.516C418.243 353.731 417.599 289.025 348.772 249.283C279.944 209.54 168.55 209.497 99.8943 249.283C31.2384 289.068 31.925 353.731 100.71 393.516Z'
							fill='#2AFBFE'
						/>
						<g opacity='0.2'>
							<path
								d='M75.0065 330.143L168.421 382.13C172.204 383.663 176.259 384.408 180.339 384.319C184.419 384.23 188.438 383.31 192.151 381.615C233.473 358.758 355.208 290.787 355.208 290.787C361.173 287.436 361.216 282.194 355.208 278.843L260.806 226.297L75.0065 330.143Z'
								fill='black'
							/>
						</g>
						<path
							d='M400.135 306.039C400.051 306.9 399.741 307.724 399.236 308.425C398.731 309.127 398.049 309.683 397.26 310.035L392.24 312.957V306.555L382.199 300.712L372.329 306.426L377.951 309.691L382.242 307.242L386.533 309.82L382.242 312.269L372.201 306.426V306.856L367.395 304.063C367.014 302.555 366.421 301.108 365.635 299.767L368.854 297.919V291.388L358.813 285.545L356.539 286.834V284.213L346.498 278.37L344.266 279.659V277.081L334.182 271.195L324.313 276.909V278.499L322.94 277.726L313.071 283.397V285.373L311.698 284.557L301.828 290.271V291.861L300.455 291.045L290.586 296.759V298.263L289.213 297.446L279.344 303.161V304.75L277.97 303.977L268.058 309.691V311.152L266.728 310.379L256.816 316.093V317.683L255.4 316.867L245.531 322.581V324.042L244.2 323.268L234.288 328.94V330.572L232.915 329.756L223.046 335.47V337.06L221.716 336.244L211.889 342.044V343.634L210.516 342.86L200.647 348.575V350.121L199.274 349.348L189.404 355.019V356.652L188.031 355.836L178.162 361.55V367.393L173.056 364.472L163.186 370.186V376.201L158.466 373.451C157.697 373.094 157.034 372.541 156.545 371.848C156.055 371.154 155.756 370.344 155.677 369.499V376.029C155.752 376.882 156.054 377.699 156.552 378.395C157.05 379.09 157.726 379.638 158.509 379.982L216.18 413.409C218.316 414.489 220.675 415.052 223.067 415.052C225.46 415.052 227.819 414.489 229.954 413.409L397.303 316.566C398.089 316.215 398.767 315.659 399.265 314.956C399.763 314.253 400.064 313.429 400.135 312.57V306.039Z'
							fill='#41C0FD'
						/>
						<path
							d='M397.303 302.302L339.889 268.875C337.746 267.794 335.38 267.232 332.981 267.232C330.582 267.232 328.216 267.794 326.072 268.875L158.724 365.718C157.923 366.035 157.237 366.585 156.753 367.298C156.269 368.01 156.01 368.852 156.01 369.714C156.01 370.575 156.269 371.417 156.753 372.13C157.237 372.842 157.923 373.393 158.724 373.709L216.18 407.136C218.316 408.216 220.675 408.779 223.067 408.779C225.46 408.779 227.819 408.216 229.954 407.136L397.303 310.293C398.103 309.976 398.79 309.426 399.274 308.713C399.758 308.001 400.017 307.159 400.017 306.297C400.017 305.436 399.758 304.594 399.274 303.881C398.79 303.169 398.103 302.618 397.303 302.302Z'
							fill='#C5F9FF'
						/>
						<path
							d='M331.265 287.092C330.875 287.079 330.493 286.976 330.149 286.791L324.399 283.44C324.07 283.241 323.799 282.96 323.61 282.626C323.421 282.291 323.322 281.913 323.322 281.528C323.322 281.143 323.421 280.765 323.61 280.43C323.799 280.096 324.07 279.815 324.399 279.616L329.977 276.394C330.311 276.227 330.678 276.139 331.05 276.136C331.437 276.129 331.821 276.217 332.166 276.394L337.916 279.745C338.255 279.935 338.538 280.213 338.734 280.549C338.931 280.885 339.035 281.267 339.035 281.657C339.035 282.046 338.931 282.429 338.734 282.765C338.538 283.101 338.255 283.379 337.916 283.569L332.38 286.791C332.045 286.996 331.658 287.1 331.265 287.092Z'
							fill='#5C697C'
						/>
						<path
							d='M332.037 276.78L337.744 280.089C338.039 280.252 338.285 280.492 338.456 280.783C338.628 281.074 338.718 281.405 338.718 281.743C338.718 282.08 338.628 282.412 338.456 282.703C338.285 282.994 338.039 283.233 337.744 283.397L332.209 286.619C331.923 286.79 331.597 286.879 331.265 286.879C330.932 286.879 330.606 286.79 330.321 286.619L324.571 283.311C324.286 283.139 324.051 282.896 323.888 282.606C323.725 282.317 323.639 281.99 323.639 281.657C323.639 281.324 323.725 280.997 323.888 280.707C324.051 280.418 324.286 280.175 324.571 280.003L330.106 276.823C330.396 276.647 330.727 276.55 331.066 276.543C331.405 276.535 331.74 276.617 332.037 276.78Z'
							fill='#CBCFDA'
						/>
						<path
							d='M320.666 293.193C320.272 293.206 319.884 293.102 319.55 292.892L313.8 289.584C313.461 289.394 313.178 289.116 312.981 288.78C312.785 288.444 312.681 288.062 312.681 287.672C312.681 287.283 312.785 286.9 312.981 286.564C313.178 286.228 313.461 285.95 313.8 285.76L319.336 282.538C319.683 282.367 320.064 282.278 320.451 282.278C320.838 282.278 321.22 282.367 321.567 282.538L327.274 285.889C327.622 286.074 327.912 286.354 328.109 286.695C328.306 287.037 328.403 287.428 328.39 287.822C328.386 288.208 328.281 288.586 328.085 288.918C327.889 289.25 327.61 289.524 327.274 289.713L321.739 292.892C321.418 293.096 321.045 293.2 320.666 293.193Z'
							fill='#5C697C'
						/>
						<path
							d='M321.395 282.838L327.145 286.147C327.429 286.319 327.665 286.562 327.828 286.851C327.991 287.141 328.076 287.468 328.076 287.801C328.076 288.133 327.991 288.46 327.828 288.75C327.665 289.04 327.429 289.283 327.145 289.455L321.653 292.634C321.359 292.804 321.026 292.893 320.687 292.893C320.348 292.893 320.015 292.804 319.722 292.634L314.015 289.326C313.72 289.163 313.474 288.923 313.302 288.632C313.131 288.341 313.041 288.01 313.041 287.672C313.041 287.334 313.131 287.003 313.302 286.712C313.474 286.421 313.72 286.181 314.015 286.018L319.55 282.838C319.831 282.676 320.149 282.591 320.473 282.591C320.797 282.591 321.115 282.676 321.395 282.838Z'
							fill='#CBCFDA'
						/>
						<path
							d='M310.024 299.337C309.631 299.345 309.244 299.241 308.909 299.036L303.201 295.728C302.862 295.538 302.579 295.26 302.383 294.924C302.186 294.588 302.082 294.205 302.082 293.816C302.082 293.426 302.186 293.044 302.383 292.708C302.579 292.372 302.862 292.094 303.201 291.904L308.737 288.682C309.076 288.486 309.461 288.382 309.853 288.382C310.244 288.382 310.629 288.486 310.968 288.682L316.675 292.033C317.015 292.223 317.297 292.501 317.494 292.837C317.691 293.173 317.794 293.555 317.794 293.945C317.794 294.334 317.691 294.717 317.494 295.053C317.297 295.389 317.015 295.667 316.675 295.857L311.14 299.036C310.804 299.241 310.417 299.345 310.024 299.337Z'
							fill='#5C697C'
						/>
						<path
							d='M310.797 288.982L316.504 292.291C316.799 292.454 317.045 292.694 317.216 292.985C317.387 293.276 317.477 293.607 317.477 293.945C317.477 294.283 317.387 294.614 317.216 294.905C317.045 295.196 316.799 295.436 316.504 295.599L310.968 298.778C310.683 298.949 310.356 299.039 310.024 299.039C309.692 299.039 309.366 298.949 309.08 298.778L303.373 295.47C303.078 295.307 302.832 295.067 302.661 294.776C302.489 294.485 302.399 294.154 302.399 293.816C302.399 293.478 302.489 293.147 302.661 292.856C302.832 292.565 303.078 292.325 303.373 292.162L308.908 288.982C309.194 288.812 309.52 288.722 309.852 288.722C310.185 288.722 310.511 288.812 310.797 288.982Z'
							fill='#CBCFDA'
						/>
						<path
							d='M332.294 299.981C331.89 299.974 331.493 299.871 331.136 299.681L325.429 296.372C325.098 296.171 324.825 295.887 324.637 295.548C324.449 295.209 324.352 294.827 324.356 294.439C324.346 294.056 324.441 293.677 324.63 293.344C324.819 293.011 325.095 292.736 325.429 292.548L331.007 289.326C331.325 289.118 331.7 289.013 332.08 289.025C332.473 289.017 332.86 289.121 333.195 289.326L338.903 292.634C339.242 292.83 339.523 293.112 339.719 293.452C339.915 293.791 340.018 294.176 340.018 294.568C340.015 294.953 339.91 295.331 339.714 295.663C339.518 295.995 339.238 296.269 338.903 296.458L333.367 299.681C333.036 299.859 332.67 299.962 332.294 299.981Z'
							fill='#5C697C'
						/>
						<path
							d='M333.024 289.67L338.774 293.021C339.059 293.188 339.295 293.426 339.459 293.713C339.624 293.999 339.71 294.324 339.71 294.654C339.71 294.984 339.624 295.309 339.459 295.595C339.295 295.882 339.059 296.12 338.774 296.286L333.238 299.509C332.945 299.656 332.622 299.733 332.294 299.733C331.967 299.733 331.643 299.656 331.35 299.509L325.643 296.158C325.358 295.991 325.122 295.753 324.958 295.466C324.794 295.18 324.707 294.855 324.707 294.525C324.707 294.195 324.794 293.87 324.958 293.584C325.122 293.297 325.358 293.059 325.643 292.892L331.179 289.67C331.459 289.508 331.777 289.422 332.101 289.422C332.425 289.422 332.743 289.508 333.024 289.67Z'
							fill='#CBCFDA'
						/>
						<path
							d='M354.522 300.626C354.132 300.613 353.75 300.51 353.406 300.325L347.699 296.974C347.36 296.784 347.077 296.506 346.88 296.17C346.683 295.834 346.58 295.451 346.58 295.062C346.58 294.672 346.683 294.29 346.88 293.954C347.077 293.618 347.36 293.34 347.699 293.15L353.234 289.928C353.582 289.757 353.963 289.668 354.35 289.668C354.737 289.668 355.119 289.757 355.466 289.928L361.173 293.279C361.501 293.478 361.773 293.758 361.962 294.093C362.151 294.428 362.25 294.806 362.25 295.191C362.25 295.575 362.151 295.953 361.962 296.288C361.773 296.623 361.501 296.904 361.173 297.103L355.637 300.325C355.294 300.51 354.912 300.613 354.522 300.626Z'
							fill='#5C697C'
						/>
						<path
							d='M355.294 290.228L361.001 293.537C361.285 293.709 361.52 293.952 361.684 294.241C361.847 294.531 361.932 294.858 361.932 295.191C361.932 295.523 361.847 295.851 361.684 296.14C361.52 296.43 361.285 296.673 361.001 296.845L355.466 300.024C355.18 300.195 354.854 300.285 354.522 300.285C354.189 300.285 353.863 300.195 353.578 300.024L347.828 296.716C347.544 296.544 347.309 296.301 347.145 296.011C346.982 295.722 346.897 295.395 346.897 295.062C346.897 294.729 346.982 294.402 347.145 294.112C347.309 293.823 347.544 293.58 347.828 293.408L353.406 290.228C353.692 290.058 354.018 289.968 354.35 289.968C354.682 289.968 355.009 290.058 355.294 290.228Z'
							fill='#CBCFDA'
						/>
						<path
							d='M299.382 305.61C299.114 305.668 298.836 305.668 298.567 305.61L291.659 301.571C291.421 301.419 291.226 301.209 291.091 300.962C290.956 300.714 290.885 300.436 290.886 300.153C290.889 299.872 290.966 299.597 291.109 299.356C291.252 299.114 291.457 298.915 291.702 298.778L298.438 294.869C298.692 294.805 298.957 294.805 299.211 294.869C299.479 294.805 299.758 294.805 300.026 294.869L306.935 298.907C307.18 299.044 307.384 299.243 307.527 299.485C307.671 299.726 307.747 300.001 307.75 300.282C307.755 300.57 307.682 300.854 307.538 301.104C307.395 301.354 307.186 301.56 306.935 301.7L300.198 305.61C299.93 305.679 299.65 305.679 299.382 305.61Z'
							fill='#5C697C'
						/>
						<path
							d='M299.855 294.912L306.806 298.95C307.002 299.063 307.165 299.226 307.278 299.422C307.391 299.618 307.451 299.841 307.451 300.067C307.451 300.294 307.391 300.517 307.278 300.713C307.165 300.909 307.002 301.072 306.806 301.185L300.069 305.094C299.879 305.222 299.655 305.29 299.425 305.29C299.196 305.29 298.972 305.222 298.782 305.094L291.83 301.056C291.634 300.943 291.471 300.78 291.358 300.584C291.245 300.388 291.185 300.165 291.185 299.939C291.185 299.712 291.245 299.489 291.358 299.293C291.471 299.097 291.634 298.934 291.83 298.821L298.567 294.912C298.765 294.806 298.986 294.751 299.211 294.751C299.435 294.751 299.656 294.806 299.855 294.912Z'
							fill='#CBCFDA'
						/>
						<path
							d='M343.88 306.856C343.506 306.863 343.137 306.774 342.807 306.598L337.057 303.247C336.729 303.048 336.457 302.767 336.268 302.432C336.08 302.097 335.98 301.719 335.98 301.335C335.98 300.95 336.08 300.572 336.268 300.237C336.457 299.902 336.729 299.622 337.057 299.423L342.593 296.243C342.932 296.047 343.317 295.944 343.708 295.944C344.1 295.944 344.485 296.047 344.824 296.243L350.531 299.552C350.871 299.742 351.153 300.019 351.35 300.356C351.547 300.692 351.65 301.074 351.65 301.464C351.65 301.853 351.547 302.236 351.35 302.572C351.153 302.908 350.871 303.185 350.531 303.376L344.996 306.598C344.649 306.77 344.267 306.858 343.88 306.856Z'
							fill='#5C697C'
						/>
						<path
							d='M344.652 296.372L350.36 299.681C350.655 299.844 350.901 300.084 351.072 300.375C351.243 300.666 351.333 300.997 351.333 301.335C351.333 301.673 351.243 302.004 351.072 302.295C350.901 302.586 350.655 302.826 350.36 302.989L344.867 306.168C344.573 306.338 344.24 306.427 343.902 306.427C343.563 306.427 343.23 306.338 342.936 306.168L337.229 302.86C336.945 302.688 336.71 302.445 336.547 302.155C336.383 301.866 336.298 301.539 336.298 301.206C336.298 300.873 336.383 300.546 336.547 300.257C336.71 299.967 336.945 299.724 337.229 299.552L342.764 296.372C343.05 296.202 343.376 296.112 343.708 296.112C344.041 296.112 344.367 296.202 344.652 296.372Z'
							fill='#CBCFDA'
						/>
						<path
							d='M288.784 311.711C288.517 311.788 288.235 311.788 287.968 311.711L281.06 307.672C280.808 307.542 280.596 307.345 280.449 307.102C280.301 306.86 280.223 306.581 280.223 306.297C280.223 306.013 280.301 305.735 280.449 305.492C280.596 305.25 280.808 305.053 281.06 304.922L287.797 301.013C288.046 300.873 288.327 300.8 288.612 300.8C288.898 300.8 289.178 300.873 289.427 301.013L296.336 305.051C296.587 305.181 296.797 305.378 296.941 305.621C297.085 305.864 297.158 306.143 297.151 306.426C297.153 306.708 297.078 306.985 296.935 307.227C296.791 307.47 296.584 307.668 296.336 307.801L289.599 311.711C289.334 311.793 289.049 311.793 288.784 311.711Z'
							fill='#5C697C'
						/>
						<path
							d='M289.127 301.056L296.078 305.094C296.274 305.207 296.437 305.37 296.551 305.566C296.664 305.762 296.724 305.985 296.724 306.211C296.724 306.438 296.664 306.66 296.551 306.857C296.437 307.053 296.274 307.215 296.078 307.328L289.299 311.238C289.1 311.344 288.879 311.399 288.655 311.399C288.431 311.399 288.21 311.344 288.011 311.238L281.103 307.2C280.907 307.087 280.744 306.924 280.631 306.728C280.517 306.532 280.458 306.309 280.458 306.082C280.458 305.856 280.517 305.633 280.631 305.437C280.744 305.241 280.907 305.078 281.103 304.965L287.84 301.056C288.038 300.95 288.259 300.895 288.483 300.895C288.708 300.895 288.929 300.95 289.127 301.056Z'
							fill='#CBCFDA'
						/>
						<path
							d='M333.281 312.871C332.888 312.879 332.501 312.775 332.166 312.57L326.459 309.262C326.119 309.072 325.837 308.794 325.64 308.458C325.443 308.122 325.339 307.74 325.339 307.35C325.339 306.96 325.443 306.578 325.64 306.242C325.837 305.906 326.119 305.628 326.459 305.438L331.994 302.216C332.341 302.044 332.723 301.956 333.11 301.958C333.503 301.949 333.89 302.054 334.225 302.259L339.932 305.567C340.268 305.756 340.548 306.03 340.744 306.362C340.94 306.694 341.045 307.072 341.048 307.457C341.044 307.848 340.939 308.232 340.743 308.57C340.548 308.909 340.268 309.192 339.932 309.391L334.397 312.57C334.061 312.775 333.674 312.879 333.281 312.871Z'
							fill='#5C697C'
						/>
						<path
							d='M334.054 302.559L339.761 305.868C340.057 306.025 340.305 306.26 340.478 306.547C340.651 306.835 340.742 307.165 340.742 307.5C340.742 307.836 340.651 308.165 340.478 308.453C340.305 308.741 340.057 308.976 339.761 309.133L334.225 312.355C333.932 312.525 333.599 312.614 333.26 312.614C332.921 312.614 332.588 312.525 332.294 312.355L326.63 309.047C326.335 308.884 326.089 308.644 325.918 308.353C325.747 308.062 325.656 307.731 325.656 307.393C325.656 307.055 325.747 306.724 325.918 306.433C326.089 306.142 326.335 305.902 326.63 305.739L332.037 302.559C332.337 302.363 332.687 302.259 333.045 302.259C333.403 302.259 333.754 302.363 334.054 302.559Z'
							fill='#CBCFDA'
						/>
						<path
							d='M278.142 317.812C277.823 317.814 277.511 317.724 277.241 317.554L270.847 313.859C270.562 313.693 270.326 313.454 270.162 313.168C269.998 312.881 269.911 312.557 269.911 312.227C269.911 311.896 269.998 311.572 270.162 311.285C270.326 310.999 270.562 310.76 270.847 310.594L277.069 307.028C277.352 306.852 277.68 306.762 278.013 306.77C278.332 306.768 278.645 306.858 278.914 307.028L285.351 310.723C285.629 310.894 285.859 311.134 286.017 311.42C286.175 311.706 286.256 312.028 286.252 312.355C286.256 312.687 286.17 313.014 286.004 313.301C285.838 313.589 285.597 313.826 285.308 313.988L279.086 317.554C278.803 317.73 278.475 317.82 278.142 317.812Z'
							fill='#5C697C'
						/>
						<path
							d='M278.786 307.286L285.179 311.023C285.411 311.16 285.602 311.355 285.735 311.589C285.869 311.822 285.939 312.086 285.939 312.355C285.939 312.624 285.869 312.889 285.735 313.122C285.602 313.356 285.411 313.551 285.179 313.687L278.957 317.296C278.719 317.445 278.444 317.524 278.164 317.524C277.883 317.524 277.608 317.445 277.37 317.296L270.976 313.558C270.745 313.422 270.553 313.227 270.42 312.993C270.287 312.76 270.217 312.495 270.217 312.226C270.217 311.958 270.287 311.693 270.42 311.46C270.553 311.226 270.745 311.031 270.976 310.895L277.241 307.286C277.471 307.136 277.739 307.056 278.013 307.056C278.288 307.056 278.556 307.136 278.786 307.286Z'
							fill='#CBCFDA'
						/>
						<path
							d='M289.771 324.6C289.452 324.602 289.139 324.513 288.87 324.343L282.476 320.605C282.19 320.444 281.952 320.21 281.787 319.927C281.621 319.644 281.534 319.322 281.534 318.993C281.534 318.665 281.621 318.343 281.787 318.06C281.952 317.777 282.19 317.543 282.476 317.382L305.39 304.106C305.675 303.936 306.002 303.846 306.334 303.846C306.666 303.846 306.992 303.936 307.278 304.106L313.672 307.844C313.956 308.008 314.19 308.247 314.349 308.535C314.508 308.822 314.585 309.148 314.573 309.477C314.569 309.796 314.484 310.11 314.327 310.388C314.169 310.666 313.944 310.899 313.672 311.066L290.715 324.343C290.432 324.518 290.104 324.608 289.771 324.6Z'
							fill='#5C697C'
						/>
						<path
							d='M307.106 304.407L313.5 308.102C313.731 308.244 313.921 308.443 314.053 308.68C314.185 308.917 314.255 309.184 314.255 309.455C314.255 309.727 314.185 309.993 314.053 310.23C313.921 310.467 313.731 310.666 313.5 310.809L290.586 324.042C290.343 324.177 290.07 324.248 289.792 324.248C289.514 324.248 289.241 324.177 288.998 324.042L282.605 320.347C282.374 320.205 282.184 320.005 282.051 319.769C281.919 319.532 281.85 319.265 281.85 318.993C281.85 318.722 281.919 318.455 282.051 318.218C282.184 317.982 282.374 317.782 282.605 317.64L305.562 304.407C305.791 304.257 306.06 304.177 306.334 304.177C306.608 304.177 306.876 304.257 307.106 304.407Z'
							fill='#CBCFDA'
						/>
						<path
							d='M256.945 330.1C256.612 330.101 256.286 330.012 256.001 329.842L249.607 326.104C249.321 325.944 249.083 325.71 248.918 325.426C248.752 325.143 248.665 324.821 248.665 324.493C248.665 324.165 248.752 323.843 248.918 323.559C249.083 323.276 249.321 323.042 249.607 322.882L255.829 319.273C256.115 319.103 256.441 319.014 256.773 319.015C257.092 319.013 257.404 319.103 257.674 319.273L264.111 323.011C264.394 323.179 264.63 323.418 264.795 323.704C264.96 323.99 265.05 324.313 265.055 324.643C265.051 324.968 264.961 325.286 264.796 325.565C264.63 325.844 264.394 326.074 264.111 326.233L257.889 329.842C257.603 330.012 257.277 330.101 256.945 330.1Z'
							fill='#5C697C'
						/>
						<path
							d='M257.545 319.745L263.939 323.44C264.17 323.583 264.36 323.782 264.492 324.019C264.624 324.255 264.694 324.522 264.694 324.794C264.694 325.065 264.624 325.332 264.492 325.569C264.36 325.806 264.17 326.005 263.939 326.147L257.717 329.756C257.479 329.884 257.214 329.951 256.945 329.951C256.675 329.951 256.41 329.884 256.172 329.756L249.779 326.018C249.548 325.876 249.357 325.677 249.225 325.44C249.093 325.203 249.024 324.936 249.024 324.665C249.024 324.393 249.093 324.127 249.225 323.89C249.357 323.653 249.548 323.454 249.779 323.311L256.001 319.702C256.239 319.573 256.507 319.509 256.779 319.516C257.05 319.524 257.314 319.603 257.545 319.745Z'
							fill='#CBCFDA'
						/>
						<path
							d='M279.172 330.744C278.84 330.745 278.514 330.656 278.228 330.487L271.834 326.749C271.556 326.578 271.327 326.337 271.169 326.051C271.011 325.765 270.93 325.443 270.933 325.116C270.928 324.789 271.013 324.468 271.18 324.187C271.347 323.906 271.588 323.678 271.877 323.526L278.056 319.917C278.344 319.753 278.669 319.665 279 319.659C279.333 319.652 279.661 319.741 279.944 319.917L286.338 323.612C286.627 323.774 286.868 324.011 287.034 324.299C287.2 324.586 287.286 324.913 287.282 325.245C287.277 325.575 287.187 325.898 287.022 326.184C286.857 326.47 286.621 326.709 286.338 326.877L280.116 330.487C279.83 330.656 279.504 330.745 279.172 330.744Z'
							fill='#5C697C'
						/>
						<path
							d='M279.816 320.175L286.166 323.913C286.408 324.047 286.609 324.243 286.749 324.481C286.889 324.719 286.963 324.99 286.963 325.266C286.963 325.543 286.889 325.814 286.749 326.052C286.609 326.29 286.408 326.486 286.166 326.62L279.944 330.186C279.714 330.336 279.446 330.415 279.172 330.415C278.898 330.415 278.629 330.336 278.4 330.186L272.006 326.491C271.775 326.348 271.585 326.149 271.453 325.912C271.321 325.676 271.251 325.409 271.251 325.137C271.251 324.866 271.321 324.599 271.453 324.362C271.585 324.125 271.775 323.926 272.006 323.784L278.228 320.175C278.473 320.047 278.745 319.98 279.022 319.98C279.298 319.98 279.57 320.047 279.816 320.175Z'
							fill='#CBCFDA'
						/>
						<path
							d='M323.455 332.248C323.065 332.235 322.683 332.132 322.339 331.947L313.328 326.706L318.134 323.87L310.367 319.573C310.037 319.386 309.763 319.114 309.571 318.786C309.38 318.458 309.28 318.085 309.28 317.705C309.28 317.325 309.38 316.951 309.571 316.623C309.763 316.295 310.037 316.023 310.367 315.836L317.576 311.539C317.92 311.354 318.302 311.251 318.692 311.238C319.068 311.251 319.436 311.355 319.765 311.539L339.804 323.183L324.528 332.033C324.192 332.188 323.824 332.262 323.455 332.248Z'
							fill='#5C697C'
						/>
						<path
							d='M317.877 311.711C318.162 311.564 318.478 311.487 318.799 311.487C319.12 311.487 319.436 311.564 319.722 311.711L329.806 317.597L336.113 321.249L339.289 323.097L324.485 331.69C324.199 331.837 323.883 331.913 323.562 331.913C323.241 331.913 322.925 331.837 322.64 331.69L314.058 326.706L318.906 323.87L310.582 319.101C310.296 318.94 310.058 318.706 309.893 318.423C309.727 318.14 309.64 317.818 309.64 317.49C309.64 317.161 309.727 316.839 309.893 316.556C310.058 316.273 310.296 316.039 310.582 315.878L317.877 311.711Z'
							fill='#CBCFDA'
						/>
						<path
							d='M246.217 336.244C245.871 336.246 245.531 336.157 245.23 335.986L238.879 332.248C238.594 332.088 238.356 331.854 238.19 331.57C238.025 331.287 237.937 330.965 237.937 330.637C237.937 330.309 238.025 329.987 238.19 329.703C238.356 329.42 238.594 329.186 238.879 329.026L245.101 325.417C245.371 325.247 245.684 325.157 246.003 325.159C246.336 325.151 246.664 325.241 246.947 325.417L253.34 329.155C253.63 329.317 253.87 329.554 254.036 329.841C254.202 330.129 254.288 330.455 254.284 330.787C254.289 331.114 254.204 331.435 254.037 331.716C253.871 331.997 253.629 332.226 253.34 332.377L247.161 335.986C246.873 336.15 246.548 336.239 246.217 336.244Z'
							fill='#5C697C'
						/>
						<path
							d='M246.947 325.674L253.34 329.412C253.571 329.555 253.761 329.754 253.893 329.991C254.026 330.228 254.095 330.494 254.095 330.766C254.095 331.037 254.026 331.304 253.893 331.541C253.761 331.778 253.571 331.977 253.34 332.119L247.118 335.728C246.873 335.856 246.601 335.923 246.324 335.923C246.048 335.923 245.776 335.856 245.531 335.728L239.137 331.99C238.906 331.848 238.716 331.649 238.584 331.412C238.452 331.175 238.382 330.908 238.382 330.637C238.382 330.366 238.452 330.099 238.584 329.862C238.716 329.625 238.906 329.426 239.137 329.284L245.359 325.674C245.604 325.546 245.876 325.48 246.153 325.48C246.429 325.48 246.701 325.546 246.947 325.674Z'
							fill='#CBCFDA'
						/>
						<path
							d='M268.573 336.931C268.24 336.939 267.912 336.849 267.629 336.674L261.236 332.979C260.951 332.812 260.714 332.574 260.55 332.287C260.386 332.001 260.299 331.676 260.299 331.346C260.299 331.016 260.386 330.691 260.55 330.405C260.714 330.118 260.951 329.88 261.236 329.713L267.458 326.147C267.741 325.971 268.069 325.882 268.402 325.889C268.733 325.895 269.057 325.983 269.346 326.147L275.739 329.842C276.014 330.017 276.24 330.258 276.397 330.543C276.554 330.829 276.636 331.149 276.636 331.475C276.636 331.801 276.554 332.121 276.397 332.406C276.24 332.692 276.014 332.933 275.739 333.107L269.517 336.717C269.219 336.848 268.899 336.921 268.573 336.931Z'
							fill='#5C697C'
						/>
						<path
							d='M269.174 326.319L275.567 330.057C275.799 330.194 275.991 330.388 276.124 330.622C276.257 330.856 276.327 331.12 276.327 331.389C276.327 331.658 276.257 331.922 276.124 332.156C275.991 332.389 275.799 332.584 275.567 332.721L269.346 336.33C269.116 336.48 268.847 336.559 268.573 336.559C268.299 336.559 268.031 336.48 267.801 336.33L261.407 332.592C261.176 332.455 260.984 332.26 260.851 332.027C260.718 331.793 260.648 331.529 260.648 331.26C260.648 330.991 260.718 330.727 260.851 330.493C260.984 330.259 261.176 330.065 261.407 329.928L267.629 326.319C267.867 326.191 268.132 326.124 268.402 326.124C268.671 326.124 268.937 326.191 269.174 326.319Z'
							fill='#CBCFDA'
						/>
						<path
							d='M273.551 336.029L283.506 330.272L293.633 336.158L283.677 341.915L273.551 336.029Z'
							fill='#5C697C'
						/>
						<path
							d='M283.506 330.658L292.989 336.158L283.677 341.572L274.194 336.029L283.506 330.658Z'
							fill='#CBCFDA'
						/>
						<path
							d='M235.704 342.388C235.375 342.369 235.053 342.281 234.76 342.13L228.367 338.392C228.082 338.226 227.845 337.987 227.681 337.701C227.517 337.414 227.43 337.09 227.43 336.76C227.43 336.429 227.517 336.105 227.681 335.818C227.845 335.532 228.082 335.293 228.367 335.127L234.588 331.561C234.874 331.391 235.2 331.302 235.533 331.303C235.864 331.308 236.188 331.397 236.477 331.561L242.827 335.299C243.113 335.459 243.351 335.693 243.517 335.976C243.682 336.26 243.769 336.582 243.769 336.91C243.769 337.238 243.682 337.56 243.517 337.843C243.351 338.127 243.113 338.361 242.827 338.521L236.648 342.13C236.355 342.281 236.033 342.369 235.704 342.388Z'
							fill='#5C697C'
						/>
						<path
							d='M236.305 331.818L242.698 335.556C242.929 335.699 243.12 335.898 243.252 336.135C243.384 336.372 243.453 336.638 243.453 336.91C243.453 337.181 243.384 337.448 243.252 337.685C243.12 337.922 242.929 338.121 242.698 338.263L236.477 341.872C236.239 342 235.974 342.067 235.704 342.067C235.435 342.067 235.169 342 234.932 341.872L228.538 338.134C228.308 337.992 228.117 337.793 227.985 337.556C227.853 337.319 227.783 337.052 227.783 336.781C227.783 336.51 227.853 336.243 227.985 336.006C228.117 335.769 228.308 335.57 228.538 335.428L234.76 331.818C234.998 331.691 235.263 331.624 235.533 331.624C235.802 331.624 236.068 331.691 236.305 331.818Z'
							fill='#CBCFDA'
						/>
						<path
							d='M257.931 342.989C257.599 342.99 257.273 342.901 256.987 342.732L250.594 339.037C250.319 338.862 250.093 338.621 249.936 338.336C249.779 338.05 249.697 337.73 249.697 337.404C249.697 337.078 249.779 336.758 249.936 336.472C250.093 336.187 250.319 335.946 250.594 335.771L256.859 332.205C257.128 332.035 257.441 331.946 257.76 331.947C258.093 331.94 258.421 332.029 258.704 332.205L265.097 335.9C265.387 336.062 265.627 336.299 265.793 336.587C265.959 336.874 266.045 337.201 266.041 337.533C266.041 337.864 265.953 338.189 265.787 338.475C265.622 338.762 265.384 339 265.097 339.165L258.876 342.732C258.59 342.901 258.264 342.99 257.931 342.989Z'
							fill='#5C697C'
						/>
						<path
							d='M258.575 332.635L264.969 336.373C265.211 336.5 265.415 336.692 265.557 336.926C265.698 337.161 265.773 337.43 265.773 337.705C265.773 337.979 265.698 338.248 265.557 338.483C265.415 338.718 265.211 338.909 264.969 339.037L258.747 342.646C258.509 342.773 258.244 342.84 257.974 342.84C257.705 342.84 257.439 342.773 257.202 342.646L250.808 338.908C250.578 338.765 250.387 338.566 250.255 338.329C250.123 338.092 250.054 337.826 250.054 337.554C250.054 337.283 250.123 337.016 250.255 336.779C250.387 336.542 250.578 336.343 250.808 336.201L257.03 332.635C257.264 332.495 257.531 332.421 257.803 332.421C258.075 332.421 258.342 332.495 258.575 332.635Z'
							fill='#CBCFDA'
						/>
						<path
							d='M263.038 342.087L273.036 336.33L283.12 342.216L273.165 348.016L263.038 342.087Z'
							fill='#5C697C'
						/>
						<path
							d='M273.036 336.717L282.519 342.216L273.165 347.63L263.681 342.087L273.036 336.717Z'
							fill='#CBCFDA'
						/>
						<path
							d='M225.105 348.489C224.796 348.573 224.47 348.573 224.161 348.489L217.768 344.751C217.483 344.584 217.247 344.346 217.082 344.06C216.918 343.773 216.832 343.449 216.832 343.118C216.832 342.788 216.918 342.463 217.082 342.177C217.247 341.89 217.483 341.652 217.768 341.486L223.99 337.877C224.301 337.808 224.623 337.808 224.934 337.877C225.265 337.882 225.59 337.97 225.878 338.134L232.271 341.829C232.546 342.004 232.772 342.245 232.929 342.53C233.086 342.816 233.168 343.136 233.168 343.462C233.168 343.788 233.086 344.108 232.929 344.394C232.772 344.679 232.546 344.92 232.271 345.095L226.049 348.704C225.724 348.694 225.403 348.621 225.105 348.489Z'
							fill='#5C697C'
						/>
						<path
							d='M225.706 337.962L232.1 341.7C232.331 341.837 232.523 342.032 232.656 342.266C232.789 342.499 232.859 342.763 232.859 343.032C232.859 343.301 232.789 343.566 232.656 343.799C232.523 344.033 232.331 344.228 232.1 344.364L225.878 347.973C225.64 348.122 225.365 348.201 225.084 348.201C224.803 348.201 224.528 348.122 224.29 347.973L217.897 344.235C217.665 344.099 217.474 343.904 217.34 343.67C217.207 343.437 217.137 343.172 217.137 342.903C217.137 342.634 217.207 342.37 217.34 342.137C217.474 341.903 217.665 341.708 217.897 341.572L224.119 337.962C224.357 337.814 224.632 337.735 224.912 337.735C225.193 337.735 225.468 337.814 225.706 337.962Z'
							fill='#CBCFDA'
						/>
						<path
							d='M247.333 349.133C247.001 349.134 246.674 349.045 246.389 348.876L239.995 345.138C239.709 344.983 239.47 344.753 239.304 344.473C239.138 344.193 239.051 343.874 239.051 343.548C239.056 343.218 239.146 342.894 239.311 342.609C239.476 342.323 239.712 342.084 239.995 341.915L246.217 338.306C246.503 338.136 246.829 338.046 247.161 338.046C247.493 338.046 247.82 338.136 248.105 338.306L254.499 342.044C254.777 342.215 255.006 342.455 255.164 342.742C255.322 343.028 255.403 343.35 255.4 343.677C255.399 344.003 255.316 344.324 255.159 344.609C255.001 344.895 254.774 345.136 254.499 345.31L248.277 348.876C247.991 349.045 247.665 349.134 247.333 349.133Z'
							fill='#5C697C'
						/>
						<path
							d='M247.933 338.607L254.327 342.302C254.558 342.444 254.748 342.643 254.88 342.88C255.013 343.117 255.082 343.384 255.082 343.655C255.082 343.927 255.013 344.193 254.88 344.43C254.748 344.667 254.558 344.866 254.327 345.009L248.105 348.618C247.868 348.746 247.602 348.813 247.333 348.813C247.063 348.813 246.798 348.746 246.56 348.618L240.167 344.88C239.936 344.737 239.746 344.538 239.614 344.301C239.481 344.065 239.412 343.798 239.412 343.526C239.412 343.255 239.481 342.988 239.614 342.751C239.746 342.514 239.936 342.315 240.167 342.173L246.389 338.607C246.619 338.457 246.887 338.377 247.161 338.377C247.435 338.377 247.704 338.457 247.933 338.607Z'
							fill='#CBCFDA'
						/>
						<path
							d='M310.11 353.301C309.737 353.303 309.369 353.214 309.037 353.043L303.201 349.649C302.871 349.462 302.597 349.19 302.406 348.862C302.214 348.533 302.114 348.16 302.114 347.78C302.114 347.4 302.214 347.027 302.406 346.698C302.597 346.37 302.871 346.098 303.201 345.911L318.091 337.318C318.399 337.079 318.775 336.944 319.164 336.931C319.543 336.924 319.916 337.028 320.237 337.232L326.03 340.626C326.363 340.803 326.64 341.07 326.83 341.396C327.02 341.723 327.114 342.096 327.102 342.474C327.108 342.856 327.011 343.233 326.822 343.565C326.634 343.897 326.36 344.173 326.03 344.364L311.183 352.957C310.861 353.164 310.491 353.282 310.11 353.301Z'
							fill='#5C697C'
						/>
						<path
							d='M320.065 337.576L325.901 340.97C326.177 341.133 326.405 341.366 326.564 341.645C326.722 341.923 326.806 342.239 326.806 342.56C326.806 342.881 326.722 343.196 326.564 343.475C326.405 343.753 326.177 343.986 325.901 344.149L311.011 352.742C310.732 352.909 310.413 352.997 310.089 352.997C309.764 352.997 309.445 352.909 309.166 352.742L303.373 349.348C303.086 349.194 302.846 348.964 302.679 348.685C302.512 348.405 302.424 348.085 302.424 347.758C302.424 347.432 302.512 347.112 302.679 346.832C302.846 346.552 303.086 346.323 303.373 346.169L318.22 337.576C318.505 337.429 318.822 337.352 319.143 337.352C319.463 337.352 319.78 337.429 320.065 337.576Z'
							fill='#CBCFDA'
						/>
						<path
							d='M262.652 353.731C262.286 353.737 261.927 353.632 261.622 353.43L255.7 349.993C255.375 349.81 255.106 349.541 254.924 349.215C254.742 348.889 254.655 348.518 254.67 348.145C254.671 347.781 254.767 347.423 254.947 347.107C255.128 346.79 255.387 346.527 255.7 346.341L261.45 343.032C261.763 342.848 262.117 342.745 262.48 342.732C262.856 342.745 263.224 342.848 263.553 343.032L269.431 346.47C269.758 346.643 270.031 346.903 270.22 347.221C270.41 347.539 270.508 347.904 270.504 348.274C270.511 348.651 270.414 349.022 270.225 349.348C270.036 349.673 269.762 349.941 269.431 350.122L263.724 353.43C263.406 353.638 263.032 353.743 262.652 353.731Z'
							fill='#5C697C'
						/>
						<path
							d='M263.381 343.29L269.26 346.727C269.536 346.885 269.766 347.113 269.926 347.389C270.086 347.664 270.17 347.977 270.17 348.295C270.17 348.614 270.086 348.927 269.926 349.202C269.766 349.478 269.536 349.706 269.26 349.864L263.553 353.172C263.267 353.319 262.951 353.396 262.63 353.396C262.309 353.396 261.993 353.319 261.708 353.172L255.829 349.735C255.552 349.577 255.322 349.349 255.163 349.074C255.003 348.798 254.918 348.485 254.918 348.167C254.918 347.848 255.003 347.535 255.163 347.26C255.322 346.984 255.552 346.756 255.829 346.598L261.536 343.29C261.821 343.143 262.138 343.066 262.459 343.066C262.779 343.066 263.096 343.143 263.381 343.29Z'
							fill='#CBCFDA'
						/>
						<path
							d='M214.464 354.547C214.057 354.559 213.655 354.454 213.305 354.246L207.856 351.11C207.498 350.898 207.203 350.596 207 350.234C206.797 349.872 206.692 349.463 206.697 349.047C206.692 348.627 206.801 348.213 207.013 347.85C207.224 347.486 207.53 347.188 207.899 346.985L213.091 343.978C213.461 343.782 213.873 343.68 214.292 343.68C214.711 343.68 215.123 343.782 215.494 343.978L220.9 347.114C221.297 347.315 221.631 347.622 221.864 348.001C222.098 348.38 222.221 348.817 222.221 349.262C222.221 349.708 222.098 350.144 221.864 350.524C221.631 350.903 221.297 351.21 220.9 351.411L215.665 354.418C215.284 354.569 214.869 354.613 214.464 354.547Z'
							fill='#5C697C'
						/>
						<path
							d='M215.322 344.235L220.729 347.415C221.04 347.596 221.299 347.856 221.479 348.169C221.659 348.482 221.753 348.837 221.753 349.198C221.753 349.559 221.659 349.913 221.479 350.226C221.299 350.539 221.04 350.799 220.729 350.981L215.494 353.988C215.177 354.158 214.823 354.246 214.464 354.246C214.105 354.246 213.751 354.158 213.434 353.988L208.027 350.852C207.726 350.657 207.479 350.39 207.307 350.075C207.136 349.76 207.046 349.406 207.046 349.047C207.046 348.688 207.136 348.335 207.307 348.02C207.479 347.705 207.726 347.438 208.027 347.243L213.262 344.235C213.579 344.066 213.933 343.978 214.292 343.978C214.651 343.978 215.005 344.066 215.322 344.235Z'
							fill='#CBCFDA'
						/>
						<path
							d='M236.734 355.191C236.31 355.189 235.894 355.07 235.532 354.848L230.126 351.754C229.806 351.541 229.541 351.255 229.354 350.918C229.168 350.582 229.064 350.206 229.053 349.821C229.048 349.405 229.153 348.996 229.356 348.634C229.559 348.272 229.855 347.97 230.212 347.758L235.447 344.751C235.808 344.528 236.224 344.409 236.648 344.407C237.071 344.421 237.483 344.539 237.85 344.751L243.213 347.887C243.64 348.062 244.005 348.359 244.261 348.741C244.518 349.124 244.655 349.575 244.655 350.036C244.655 350.497 244.518 350.947 244.261 351.33C244.005 351.712 243.64 352.01 243.213 352.184L237.978 355.234C237.566 355.316 237.14 355.301 236.734 355.191Z'
							fill='#5C697C'
						/>
						<path
							d='M237.635 344.88L242.999 348.016C243.311 348.198 243.569 348.458 243.749 348.771C243.929 349.084 244.024 349.438 244.024 349.799C244.024 350.16 243.929 350.515 243.749 350.828C243.569 351.141 243.311 351.401 242.999 351.582L237.807 354.59C237.489 354.779 237.125 354.879 236.755 354.879C236.385 354.879 236.022 354.779 235.704 354.59L230.34 351.453C230.029 351.272 229.77 351.012 229.59 350.699C229.41 350.386 229.316 350.031 229.316 349.67C229.316 349.309 229.41 348.955 229.59 348.642C229.77 348.329 230.029 348.069 230.34 347.887L235.575 344.88C235.885 344.69 236.242 344.589 236.605 344.589C236.969 344.589 237.325 344.69 237.635 344.88Z'
							fill='#CBCFDA'
						/>
						<path
							d='M252.182 359.789C251.802 359.801 251.427 359.696 251.109 359.488L245.23 356.051C244.909 355.866 244.643 355.6 244.457 355.28C244.272 354.959 244.174 354.595 244.174 354.225C244.174 353.854 244.272 353.49 244.457 353.17C244.643 352.849 244.909 352.583 245.23 352.399L250.937 349.09C251.26 348.893 251.631 348.789 252.01 348.789C252.388 348.789 252.76 348.893 253.083 349.09L258.961 352.528C259.272 352.72 259.528 352.989 259.706 353.309C259.883 353.628 259.977 353.988 259.977 354.354C259.977 354.719 259.883 355.079 259.706 355.399C259.528 355.718 259.272 355.987 258.961 356.18L253.211 359.488C252.907 359.69 252.547 359.795 252.182 359.789Z'
							fill='#5C697C'
						/>
						<path
							d='M252.911 349.348L258.79 352.785C259.066 352.943 259.296 353.171 259.456 353.447C259.616 353.722 259.7 354.035 259.7 354.354C259.7 354.672 259.616 354.985 259.456 355.261C259.296 355.536 259.066 355.764 258.79 355.922L253.083 359.23C252.809 359.389 252.498 359.472 252.182 359.472C251.865 359.472 251.554 359.389 251.28 359.23L245.359 355.793C245.082 355.635 244.852 355.407 244.692 355.132C244.533 354.856 244.448 354.543 244.448 354.225C244.448 353.906 244.533 353.593 244.692 353.318C244.852 353.042 245.082 352.814 245.359 352.657L251.109 349.348C251.387 349.201 251.696 349.125 252.01 349.125C252.324 349.125 252.633 349.201 252.911 349.348Z'
							fill='#CBCFDA'
						/>
						<path
							d='M203.865 360.691C203.445 360.7 203.03 360.596 202.664 360.39L197.3 357.254C196.935 357.048 196.631 356.748 196.42 356.386C196.209 356.023 196.098 355.611 196.098 355.191C196.093 354.771 196.202 354.357 196.414 353.994C196.625 353.63 196.932 353.332 197.3 353.129L202.492 350.079C202.864 349.888 203.275 349.785 203.693 349.778C204.103 349.786 204.504 349.904 204.852 350.122L210.259 353.258C210.627 353.46 210.933 353.759 211.145 354.123C211.356 354.486 211.465 354.9 211.46 355.32C211.456 355.739 211.344 356.15 211.133 356.511C210.922 356.873 210.621 357.173 210.259 357.383L205.024 360.39C204.674 360.598 204.272 360.703 203.865 360.691Z'
							fill='#5C697C'
						/>
						<path
							d='M204.723 350.379L210.13 353.516C210.431 353.705 210.68 353.968 210.852 354.28C211.024 354.592 211.115 354.942 211.115 355.299C211.115 355.655 211.024 356.006 210.852 356.318C210.68 356.629 210.431 356.892 210.13 357.082L204.895 360.132C204.582 360.313 204.227 360.409 203.865 360.409C203.503 360.409 203.148 360.313 202.835 360.132L197.429 356.996C197.117 356.815 196.858 356.554 196.678 356.241C196.498 355.929 196.404 355.574 196.404 355.213C196.404 354.852 196.498 354.497 196.678 354.184C196.858 353.871 197.117 353.611 197.429 353.43L202.621 350.422C202.935 350.227 203.296 350.119 203.666 350.112C204.036 350.104 204.401 350.197 204.723 350.379Z'
							fill='#CBCFDA'
						/>
						<path
							d='M226.092 361.335C225.668 361.333 225.252 361.214 224.891 360.992L219.484 357.898C219.127 357.677 218.832 357.366 218.629 356.998C218.426 356.629 218.322 356.214 218.326 355.793C218.325 355.373 218.436 354.961 218.647 354.599C218.858 354.236 219.162 353.937 219.527 353.731L224.719 350.723C225.088 350.524 225.501 350.419 225.921 350.419C226.34 350.419 226.753 350.524 227.122 350.723L232.486 353.86C232.897 354.048 233.245 354.351 233.489 354.732C233.733 355.112 233.863 355.555 233.863 356.008C233.863 356.46 233.733 356.903 233.489 357.284C233.245 357.665 232.897 357.968 232.486 358.156L227.251 361.164C226.888 361.327 226.487 361.387 226.092 361.335Z'
							fill='#5C697C'
						/>
						<path
							d='M226.951 351.024L232.357 354.16C232.659 354.35 232.907 354.613 233.079 354.925C233.251 355.236 233.342 355.587 233.342 355.943C233.342 356.3 233.251 356.65 233.079 356.962C232.907 357.274 232.659 357.537 232.357 357.726L227.122 360.734C226.812 360.924 226.456 361.024 226.092 361.024C225.729 361.024 225.373 360.924 225.063 360.734L219.656 357.597C219.344 357.416 219.085 357.156 218.906 356.843C218.726 356.53 218.631 356.175 218.631 355.814C218.631 355.453 218.726 355.099 218.906 354.786C219.085 354.473 219.344 354.213 219.656 354.031L224.891 351.024C225.201 350.834 225.557 350.734 225.921 350.734C226.284 350.734 226.64 350.834 226.951 351.024Z'
							fill='#CBCFDA'
						/>
						<path
							d='M241.669 365.804C241.248 365.817 240.832 365.713 240.467 365.503L235.103 362.367C234.693 362.178 234.344 361.875 234.1 361.495C233.856 361.114 233.727 360.671 233.727 360.218C233.727 359.766 233.856 359.323 234.1 358.942C234.344 358.561 234.693 358.259 235.103 358.07L240.338 355.063C240.69 354.859 241.091 354.755 241.497 354.762C241.918 354.749 242.333 354.853 242.698 355.063L248.062 358.156C248.428 358.372 248.731 358.68 248.941 359.049C249.152 359.418 249.263 359.836 249.264 360.261C249.271 360.676 249.162 361.085 248.95 361.442C248.737 361.799 248.43 362.089 248.062 362.281L242.827 365.331C242.497 365.6 242.093 365.765 241.669 365.804Z'
							fill='#5C697C'
						/>
						<path
							d='M242.57 355.492L247.933 358.629C248.245 358.81 248.504 359.07 248.684 359.383C248.864 359.696 248.958 360.051 248.958 360.412C248.958 360.773 248.864 361.127 248.684 361.44C248.504 361.753 248.245 362.013 247.933 362.195L242.698 365.202C242.388 365.392 242.032 365.493 241.669 365.493C241.305 365.493 240.949 365.392 240.639 365.202L235.275 362.109C234.953 361.93 234.685 361.669 234.498 361.352C234.311 361.034 234.213 360.673 234.213 360.304C234.213 359.936 234.311 359.574 234.498 359.257C234.685 358.939 234.953 358.678 235.275 358.5L240.467 355.492C240.785 355.303 241.148 355.203 241.518 355.203C241.888 355.203 242.252 355.303 242.57 355.492Z'
							fill='#CBCFDA'
						/>
						<path
							d='M193.223 367.007C192.817 367.014 192.416 366.91 192.065 366.706L186.658 363.527C186.292 363.337 185.987 363.046 185.782 362.688C185.576 362.33 185.478 361.92 185.5 361.507C185.49 361.091 185.593 360.68 185.797 360.317C186 359.954 186.298 359.653 186.658 359.445L191.893 356.437C192.241 356.22 192.642 356.101 193.052 356.094C193.475 356.102 193.889 356.221 194.253 356.437L199.66 359.574C200.057 359.774 200.391 360.081 200.624 360.461C200.857 360.84 200.981 361.277 200.981 361.722C200.981 362.167 200.857 362.604 200.624 362.983C200.391 363.363 200.057 363.67 199.66 363.87L194.425 366.878C194.043 367.029 193.628 367.073 193.223 367.007Z'
							fill='#5C697C'
						/>
						<path
							d='M194.082 356.523L199.488 359.66C199.8 359.841 200.059 360.101 200.238 360.414C200.418 360.727 200.513 361.082 200.513 361.443C200.513 361.804 200.418 362.159 200.238 362.472C200.059 362.784 199.8 363.045 199.488 363.226L194.253 366.233C193.943 366.423 193.587 366.524 193.223 366.524C192.86 366.524 192.504 366.423 192.194 366.233L186.83 363.097C186.518 362.916 186.259 362.655 186.079 362.343C185.9 362.03 185.805 361.675 185.805 361.314C185.805 360.953 185.9 360.598 186.079 360.285C186.259 359.973 186.518 359.712 186.83 359.531L192.022 356.523C192.339 356.354 192.692 356.266 193.052 356.266C193.411 356.266 193.765 356.354 194.082 356.523Z'
							fill='#CBCFDA'
						/>
						<path
							d='M215.494 367.479C215.069 367.477 214.654 367.358 214.292 367.136L208.928 363.999C208.518 363.811 208.169 363.508 207.925 363.127C207.681 362.746 207.552 362.303 207.552 361.851C207.552 361.398 207.681 360.955 207.925 360.575C208.169 360.194 208.518 359.891 208.928 359.703L214.12 356.695C214.485 356.486 214.901 356.381 215.322 356.394C215.729 356.383 216.131 356.487 216.481 356.695L221.887 359.832C222.284 360.032 222.618 360.339 222.851 360.718C223.084 361.098 223.208 361.534 223.208 361.98C223.208 362.425 223.084 362.862 222.851 363.241C222.618 363.62 222.284 363.928 221.887 364.128L216.652 367.136C216.304 367.353 215.904 367.471 215.494 367.479Z'
							fill='#5C697C'
						/>
						<path
							d='M216.18 357.168L221.544 360.304C221.856 360.486 222.114 360.746 222.294 361.059C222.474 361.372 222.569 361.726 222.569 362.087C222.569 362.448 222.474 362.803 222.294 363.116C222.114 363.429 221.856 363.689 221.544 363.87L216.352 366.878C216.035 367.047 215.681 367.136 215.322 367.136C214.963 367.136 214.609 367.047 214.292 366.878L208.885 363.741C208.574 363.56 208.315 363.3 208.135 362.987C207.955 362.674 207.861 362.319 207.861 361.958C207.861 361.597 207.955 361.243 208.135 360.93C208.315 360.617 208.574 360.357 208.885 360.175L214.078 357.168C214.392 356.969 214.757 356.864 215.129 356.864C215.501 356.864 215.865 356.969 216.18 357.168Z'
							fill='#CBCFDA'
						/>
						<path
							d='M231.199 371.905C230.774 371.903 230.359 371.784 229.997 371.561L224.591 368.425C224.233 368.213 223.938 367.911 223.735 367.549C223.531 367.187 223.427 366.778 223.432 366.362C223.427 365.942 223.536 365.528 223.747 365.165C223.959 364.801 224.265 364.502 224.633 364.3L229.825 361.292C230.19 361.083 230.606 360.979 231.027 360.992C231.446 360.989 231.86 361.092 232.228 361.292L237.592 364.429C238.003 364.617 238.351 364.92 238.595 365.301C238.839 365.682 238.969 366.125 238.969 366.577C238.969 367.03 238.839 367.473 238.595 367.853C238.351 368.234 238.003 368.537 237.592 368.725L232.357 371.733C231.994 371.897 231.593 371.956 231.199 371.905Z'
							fill='#5C697C'
						/>
						<path
							d='M232.057 361.55L237.421 364.687C237.732 364.874 237.989 365.138 238.168 365.455C238.346 365.771 238.44 366.128 238.44 366.491C238.44 366.855 238.346 367.212 238.168 367.528C237.989 367.844 237.732 368.109 237.421 368.296L232.228 371.303C231.912 371.473 231.558 371.561 231.199 371.561C230.839 371.561 230.486 371.473 230.169 371.303L224.762 368.167C224.461 367.977 224.212 367.714 224.04 367.403C223.868 367.091 223.777 366.74 223.777 366.384C223.777 366.027 223.868 365.677 224.04 365.365C224.212 365.053 224.461 364.79 224.762 364.601L229.997 361.55C230.314 361.381 230.668 361.292 231.027 361.292C231.386 361.292 231.74 361.381 232.057 361.55Z'
							fill='#CBCFDA'
						/>
						<path
							d='M179.149 374.998C178.731 374.991 178.32 374.888 177.947 374.698L172.541 371.561C172.156 371.35 171.835 371.039 171.611 370.661C171.387 370.283 171.269 369.852 171.269 369.413C171.269 368.973 171.387 368.542 171.611 368.164C171.835 367.786 172.156 367.476 172.541 367.265L177.776 364.257C178.127 364.053 178.528 363.949 178.934 363.956C179.355 363.939 179.773 364.043 180.136 364.257L185.542 367.436C185.898 367.642 186.196 367.936 186.406 368.289C186.617 368.643 186.733 369.044 186.744 369.456C186.735 369.879 186.621 370.294 186.411 370.662C186.201 371.029 185.902 371.339 185.542 371.561L180.307 374.569C179.962 374.804 179.564 374.951 179.149 374.998Z'
							fill='#5C697C'
						/>
						<path
							d='M180.007 364.687L185.371 367.823C185.683 368.005 185.941 368.265 186.121 368.578C186.301 368.89 186.396 369.245 186.396 369.606C186.396 369.967 186.301 370.322 186.121 370.635C185.941 370.948 185.683 371.208 185.371 371.389L180.179 374.397C179.861 374.586 179.497 374.686 179.127 374.686C178.757 374.686 178.394 374.586 178.076 374.397L172.712 371.26C172.401 371.079 172.142 370.819 171.962 370.506C171.782 370.193 171.688 369.838 171.688 369.477C171.688 369.116 171.782 368.762 171.962 368.449C172.142 368.136 172.401 367.876 172.712 367.694L177.905 364.687C178.223 364.498 178.586 364.398 178.956 364.398C179.326 364.398 179.689 364.498 180.007 364.687Z'
							fill='#CBCFDA'
						/>
						<path
							d='M220.686 377.963C220.263 377.949 219.85 377.831 219.484 377.619L214.121 374.483C213.723 374.282 213.39 373.975 213.156 373.596C212.923 373.217 212.8 372.78 212.8 372.334C212.8 371.889 212.923 371.452 213.156 371.073C213.39 370.694 213.723 370.387 214.121 370.186L219.313 367.179C219.678 367.025 220.077 366.966 220.471 367.007C220.892 366.994 221.308 367.098 221.673 367.308L227.036 370.444C227.404 370.646 227.711 370.945 227.922 371.309C228.134 371.672 228.243 372.086 228.238 372.506C228.234 372.925 228.121 373.336 227.911 373.697C227.7 374.059 227.399 374.359 227.036 374.569L221.844 377.576C221.497 377.801 221.098 377.934 220.686 377.963Z'
							fill='#5C697C'
						/>
						<path
							d='M221.544 367.651L226.951 370.745C227.262 370.932 227.519 371.196 227.698 371.513C227.876 371.829 227.97 372.186 227.97 372.549C227.97 372.913 227.876 373.27 227.698 373.586C227.519 373.902 227.262 374.167 226.951 374.354L221.758 377.361C221.445 377.542 221.09 377.638 220.729 377.638C220.367 377.638 220.012 377.542 219.699 377.361L214.335 374.268C214.023 374.086 213.765 373.826 213.585 373.513C213.405 373.2 213.31 372.846 213.31 372.485C213.31 372.124 213.405 371.769 213.585 371.456C213.765 371.143 214.023 370.883 214.335 370.702L219.527 367.694C219.828 367.506 220.175 367.403 220.53 367.395C220.885 367.388 221.235 367.476 221.544 367.651Z'
							fill='#CBCFDA'
						/>
						<path
							d='M263.381 380.369C263.002 380.376 262.629 380.272 262.308 380.068L256.43 376.631C256.104 376.448 255.836 376.179 255.654 375.853C255.472 375.527 255.384 375.157 255.4 374.783C255.401 374.419 255.496 374.061 255.677 373.745C255.857 373.429 256.117 373.165 256.43 372.979L298.438 348.704C298.757 348.495 299.131 348.39 299.511 348.403C299.877 348.396 300.236 348.501 300.541 348.704L306.42 352.141C306.741 352.325 307.007 352.591 307.193 352.912C307.378 353.232 307.476 353.596 307.476 353.967C307.476 354.337 307.378 354.701 307.193 355.022C307.007 355.343 306.741 355.608 306.42 355.793L264.411 379.896C264.123 380.153 263.764 380.318 263.381 380.369Z'
							fill='#5C697C'
						/>
						<path
							d='M300.369 348.961L306.248 352.399C306.525 352.556 306.755 352.784 306.914 353.06C307.074 353.335 307.159 353.648 307.159 353.967C307.159 354.285 307.074 354.598 306.914 354.874C306.755 355.149 306.525 355.377 306.248 355.535L264.239 379.896C263.961 380.043 263.652 380.12 263.338 380.12C263.024 380.12 262.715 380.043 262.437 379.896L256.558 376.459C256.282 376.301 256.052 376.073 255.892 375.798C255.732 375.522 255.648 375.209 255.648 374.891C255.648 374.572 255.732 374.259 255.892 373.984C256.052 373.708 256.282 373.48 256.558 373.323L298.524 349.047C298.803 348.887 299.115 348.795 299.436 348.781C299.757 348.766 300.077 348.828 300.369 348.961Z'
							fill='#CBCFDA'
						/>
						<path
							d='M190.735 381.744C190.315 381.747 189.902 381.643 189.533 381.443L184.169 378.307C183.772 378.106 183.438 377.799 183.205 377.42C182.972 377.04 182.849 376.604 182.849 376.158C182.849 375.713 182.972 375.276 183.205 374.897C183.438 374.518 183.772 374.211 184.169 374.01L203.05 363.097C203.401 362.893 203.802 362.789 204.208 362.796C204.626 362.804 205.037 362.906 205.41 363.097L210.816 366.233C211.214 366.434 211.547 366.741 211.781 367.12C212.014 367.5 212.137 367.936 212.137 368.382C212.137 368.827 212.014 369.264 211.781 369.643C211.547 370.022 211.214 370.329 210.816 370.53L191.936 381.443C191.571 381.653 191.155 381.757 190.735 381.744Z'
							fill='#5C697C'
						/>
						<path
							d='M205.281 363.57L210.645 366.706C210.957 366.887 211.215 367.148 211.395 367.461C211.575 367.773 211.67 368.128 211.67 368.489C211.67 368.85 211.575 369.205 211.395 369.518C211.215 369.831 210.957 370.091 210.645 370.272L191.764 381.142C191.454 381.332 191.098 381.433 190.735 381.433C190.371 381.433 190.015 381.332 189.705 381.142L184.341 378.006C184.029 377.824 183.771 377.564 183.591 377.251C183.411 376.938 183.316 376.584 183.316 376.223C183.316 375.862 183.411 375.507 183.591 375.194C183.771 374.881 184.029 374.621 184.341 374.44L203.221 363.57C203.531 363.38 203.888 363.279 204.251 363.279C204.615 363.279 204.971 363.38 205.281 363.57Z'
							fill='#CBCFDA'
						/>
						<path
							d='M202.363 388.489C201.96 388.475 201.564 388.373 201.205 388.188L195.798 385.009C195.435 384.816 195.133 384.523 194.929 384.166C194.724 383.809 194.623 383.401 194.639 382.99C194.634 382.574 194.739 382.165 194.942 381.803C195.146 381.441 195.441 381.139 195.798 380.927L208.671 373.409C209.036 373.199 209.452 373.095 209.872 373.108C210.279 373.096 210.681 373.2 211.031 373.409L216.438 376.545C216.801 376.749 217.101 377.05 217.305 377.414C217.51 377.777 217.61 378.19 217.596 378.607C217.601 379.023 217.497 379.432 217.293 379.794C217.09 380.156 216.795 380.458 216.438 380.67L203.565 388.188C203.192 388.379 202.781 388.482 202.363 388.489Z'
							fill='#5C697C'
						/>
						<path
							d='M211.031 373.709L216.395 376.846C216.706 377.027 216.965 377.287 217.145 377.6C217.325 377.913 217.419 378.268 217.419 378.629C217.419 378.99 217.325 379.345 217.145 379.657C216.965 379.97 216.706 380.23 216.395 380.412L203.522 387.931C203.212 388.121 202.855 388.221 202.492 388.221C202.128 388.221 201.772 388.121 201.462 387.931L196.098 384.794C195.787 384.613 195.528 384.353 195.348 384.04C195.168 383.727 195.074 383.372 195.074 383.011C195.074 382.65 195.168 382.296 195.348 381.983C195.528 381.67 195.787 381.41 196.098 381.228L208.971 373.709C209.288 373.54 209.642 373.452 210.001 373.452C210.36 373.452 210.714 373.54 211.031 373.709Z'
							fill='#CBCFDA'
						/>
						<path
							d='M246.217 390.294C245.851 390.301 245.492 390.196 245.187 389.993L239.266 386.599C238.949 386.406 238.688 386.135 238.508 385.811C238.327 385.487 238.234 385.122 238.236 384.751C238.229 384.375 238.325 384.003 238.515 383.678C238.704 383.352 238.978 383.084 239.309 382.904L251.409 375.901C251.732 375.701 252.103 375.597 252.482 375.6C252.862 375.587 253.237 375.692 253.555 375.901L259.433 379.295C259.755 379.485 260.022 379.755 260.21 380.079C260.398 380.402 260.5 380.768 260.506 381.142C260.513 381.519 260.416 381.89 260.227 382.216C260.038 382.542 259.764 382.809 259.433 382.99L247.29 389.993C246.972 390.202 246.597 390.307 246.217 390.294Z'
							fill='#5C697C'
						/>
						<path
							d='M253.297 376.158L259.219 379.596C259.495 379.753 259.725 379.981 259.885 380.257C260.045 380.532 260.129 380.845 260.129 381.164C260.129 381.482 260.045 381.795 259.885 382.071C259.725 382.346 259.495 382.574 259.219 382.732L247.118 389.735C246.839 389.902 246.52 389.99 246.196 389.99C245.871 389.99 245.552 389.902 245.273 389.735L239.394 386.341C239.119 386.178 238.89 385.945 238.732 385.666C238.573 385.387 238.489 385.072 238.489 384.751C238.489 384.43 238.573 384.115 238.732 383.836C238.89 383.558 239.119 383.325 239.394 383.162L251.538 376.158C251.81 376.019 252.112 375.946 252.418 375.946C252.723 375.946 253.025 376.019 253.297 376.158Z'
							fill='#CBCFDA'
						/>
						<path
							d='M225.706 402.109C225.282 402.107 224.866 401.988 224.505 401.765L219.141 398.629C218.773 398.427 218.467 398.128 218.255 397.764C218.043 397.401 217.934 396.987 217.939 396.567C217.939 396.147 218.05 395.735 218.261 395.372C218.472 395.01 218.776 394.71 219.141 394.504L233.559 386.169C233.91 385.965 234.311 385.861 234.717 385.868C235.138 385.855 235.554 385.96 235.919 386.169L241.325 389.306C241.682 389.517 241.978 389.819 242.181 390.181C242.384 390.543 242.489 390.953 242.484 391.368C242.489 391.788 242.38 392.202 242.168 392.566C241.957 392.929 241.651 393.228 241.282 393.43L226.865 401.765C226.517 401.983 226.116 402.101 225.706 402.109Z'
							fill='#5C697C'
						/>
						<path
							d='M235.747 386.47L241.154 389.563C241.465 389.75 241.722 390.015 241.901 390.331C242.079 390.647 242.173 391.005 242.173 391.368C242.173 391.731 242.079 392.088 241.901 392.405C241.722 392.721 241.465 392.985 241.154 393.172L226.736 401.379C226.419 401.548 226.065 401.637 225.706 401.637C225.347 401.637 224.993 401.548 224.676 401.379L219.27 398.242C218.958 398.061 218.699 397.801 218.519 397.488C218.34 397.175 218.245 396.82 218.245 396.459C218.245 396.098 218.34 395.744 218.519 395.431C218.699 395.118 218.958 394.858 219.27 394.676L233.687 386.083C234.034 385.957 234.408 385.927 234.77 385.995C235.132 386.063 235.47 386.227 235.747 386.47Z'
							fill='#CBCFDA'
						/>
						<path
							d='M342.893 293.966C342.5 293.98 342.111 293.875 341.777 293.666L336.07 290.357C335.731 290.161 335.45 289.879 335.254 289.54C335.058 289.201 334.955 288.816 334.955 288.424C334.963 288.034 335.07 287.652 335.265 287.314C335.46 286.976 335.737 286.693 336.07 286.49L341.606 283.311C341.937 283.132 342.303 283.03 342.679 283.01C343.07 283.013 343.454 283.116 343.794 283.311L349.544 286.619C349.883 286.816 350.164 287.098 350.359 287.437C350.555 287.776 350.657 288.161 350.657 288.553C350.657 288.944 350.555 289.329 350.359 289.669C350.164 290.008 349.883 290.29 349.544 290.486L344.009 293.666C343.676 293.877 343.287 293.982 342.893 293.966Z'
							fill='#5C697C'
						/>
						<path
							d='M343.666 283.483L349.373 286.791C349.657 286.963 349.892 287.206 350.055 287.496C350.218 287.786 350.304 288.113 350.304 288.445C350.304 288.778 350.218 289.105 350.055 289.395C349.892 289.684 349.657 289.927 349.373 290.099L343.837 293.279C343.552 293.449 343.225 293.539 342.893 293.539C342.561 293.539 342.235 293.449 341.949 293.279L336.199 289.928C335.914 289.761 335.678 289.523 335.514 289.236C335.349 288.95 335.263 288.625 335.263 288.295C335.263 287.965 335.349 287.64 335.514 287.354C335.678 287.067 335.914 286.829 336.199 286.662L341.692 283.483C341.99 283.305 342.331 283.211 342.679 283.211C343.026 283.211 343.367 283.305 343.666 283.483Z'
							fill='#CBCFDA'
						/>
						<path
							d='M321.653 306.125C321.26 306.134 320.873 306.029 320.537 305.825L314.873 302.559C314.534 302.363 314.253 302.081 314.058 301.742C313.863 301.402 313.76 301.018 313.76 300.626C313.76 300.234 313.863 299.849 314.058 299.51C314.253 299.171 314.534 298.889 314.873 298.692L320.408 295.513C320.748 295.317 321.132 295.214 321.524 295.214C321.916 295.214 322.3 295.317 322.64 295.513L328.347 298.821C328.686 299.017 328.967 299.299 329.163 299.639C329.359 299.978 329.462 300.363 329.462 300.755C329.45 301.144 329.342 301.524 329.147 301.862C328.952 302.199 328.677 302.483 328.347 302.688L322.811 305.868C322.459 306.065 322.056 306.155 321.653 306.125Z'
							fill='#5C697C'
						/>
						<path
							d='M322.425 295.728L328.132 299.036C328.427 299.2 328.673 299.439 328.845 299.73C329.016 300.021 329.106 300.353 329.106 300.69C329.106 301.028 329.016 301.36 328.845 301.65C328.673 301.941 328.427 302.181 328.132 302.344L322.597 305.567C322.296 305.715 321.966 305.792 321.631 305.792C321.297 305.792 320.966 305.715 320.666 305.567L314.959 302.216C314.674 302.049 314.438 301.811 314.273 301.524C314.109 301.238 314.023 300.913 314.023 300.583C314.023 300.253 314.109 299.928 314.273 299.642C314.438 299.355 314.674 299.117 314.959 298.95L320.537 295.728C320.823 295.558 321.149 295.468 321.481 295.468C321.813 295.468 322.14 295.558 322.425 295.728Z'
							fill='#CBCFDA'
						/>
						<path
							d='M376.492 314.804C376.113 314.807 375.741 314.703 375.419 314.504L369.626 311.152C369.295 310.961 369.022 310.685 368.833 310.353C368.645 310.021 368.548 309.644 368.553 309.262C368.552 308.88 368.651 308.505 368.839 308.174C369.027 307.842 369.298 307.566 369.626 307.371L375.247 304.149C375.565 303.94 375.94 303.835 376.32 303.848C376.713 303.84 377.1 303.944 377.436 304.149L383.228 307.5C383.556 307.695 383.827 307.971 384.016 308.303C384.204 308.634 384.302 309.009 384.301 309.391C384.307 309.773 384.21 310.15 384.021 310.482C383.833 310.814 383.559 311.09 383.228 311.281L377.564 314.504C377.246 314.712 376.872 314.817 376.492 314.804Z'
							fill='#5C697C'
						/>
						<path
							d='M377.264 304.407L383.057 307.801C383.332 307.97 383.559 308.207 383.717 308.489C383.874 308.771 383.957 309.089 383.957 309.412C383.957 309.735 383.874 310.053 383.717 310.335C383.559 310.617 383.332 310.854 383.057 311.023L377.436 314.246C377.157 314.412 376.838 314.5 376.513 314.5C376.188 314.5 375.869 314.412 375.59 314.246L369.755 310.895C369.48 310.725 369.252 310.489 369.095 310.207C368.937 309.924 368.854 309.607 368.854 309.283C368.854 308.96 368.937 308.642 369.095 308.36C369.252 308.078 369.48 307.841 369.755 307.672L375.419 304.407C375.698 304.24 376.017 304.152 376.341 304.152C376.666 304.152 376.985 304.24 377.264 304.407Z'
							fill='#CBCFDA'
						/>
						<path
							d='M365.936 320.905C365.543 320.914 365.156 320.809 364.82 320.605L359.113 317.253C358.774 317.068 358.492 316.794 358.296 316.462C358.099 316.129 357.996 315.749 357.997 315.363C357.998 314.971 358.101 314.586 358.297 314.247C358.493 313.907 358.774 313.625 359.113 313.429L364.648 310.25C364.977 310.066 365.345 309.962 365.721 309.949C366.126 309.957 366.523 310.06 366.88 310.25L372.587 313.601C372.917 313.792 373.191 314.068 373.38 314.401C373.568 314.733 373.665 315.11 373.66 315.492C373.664 315.88 373.567 316.262 373.379 316.601C373.191 316.94 372.918 317.224 372.587 317.425L367.051 320.605C366.716 320.809 366.329 320.914 365.936 320.905Z'
							fill='#5C697C'
						/>
						<path
							d='M366.708 310.508L372.415 313.859C372.7 314.026 372.936 314.264 373.101 314.551C373.265 314.837 373.351 315.162 373.351 315.492C373.351 315.822 373.265 316.147 373.101 316.433C372.936 316.72 372.7 316.958 372.415 317.125L366.88 320.347C366.594 320.517 366.268 320.607 365.936 320.607C365.603 320.607 365.277 320.517 364.992 320.347L359.242 316.996C358.957 316.829 358.721 316.591 358.556 316.304C358.392 316.018 358.306 315.693 358.306 315.363C358.306 315.033 358.392 314.708 358.556 314.422C358.721 314.135 358.957 313.897 359.242 313.73L364.82 310.508C365.106 310.338 365.432 310.248 365.764 310.248C366.096 310.248 366.423 310.338 366.708 310.508Z'
							fill='#CBCFDA'
						/>
						<path
							d='M355.637 314.804C355.272 314.811 354.912 314.706 354.608 314.504L348.772 311.152C348.441 310.961 348.168 310.685 347.979 310.353C347.79 310.021 347.694 309.644 347.699 309.262C347.698 308.881 347.797 308.506 347.985 308.174C348.173 307.843 348.444 307.566 348.772 307.371L354.393 304.149C354.729 303.944 355.116 303.84 355.509 303.848C355.887 303.846 356.259 303.95 356.581 304.149L362.374 307.5C362.702 307.695 362.973 307.971 363.161 308.303C363.349 308.635 363.448 309.009 363.447 309.391C363.452 309.773 363.356 310.15 363.167 310.482C362.979 310.814 362.705 311.09 362.374 311.281L356.753 314.504C356.422 314.718 356.032 314.823 355.637 314.804Z'
							fill='#5C697C'
						/>
						<path
							d='M356.41 304.407L362.203 307.801C362.488 307.962 362.726 308.196 362.892 308.479C363.058 308.762 363.145 309.084 363.145 309.412C363.145 309.74 363.058 310.063 362.892 310.346C362.726 310.629 362.488 310.863 362.203 311.023L356.581 314.246C356.302 314.412 355.984 314.5 355.659 314.5C355.334 314.5 355.015 314.412 354.736 314.246L348.943 310.895C348.658 310.734 348.42 310.5 348.254 310.217C348.089 309.934 348.001 309.612 348.001 309.283C348.001 308.955 348.089 308.633 348.254 308.35C348.42 308.067 348.658 307.833 348.943 307.672L354.565 304.407C354.844 304.24 355.162 304.152 355.487 304.152C355.812 304.152 356.131 304.24 356.41 304.407Z'
							fill='#CBCFDA'
						/>
						<path
							d='M267.672 324.042C267.353 324.044 267.041 323.954 266.771 323.784L260.334 320.046C260.047 319.886 259.81 319.648 259.65 319.359C259.491 319.07 259.416 318.743 259.433 318.413C259.429 318.092 259.51 317.775 259.668 317.496C259.826 317.216 260.056 316.984 260.334 316.824L266.556 313.215C266.844 313.051 267.169 312.962 267.5 312.957C267.833 312.949 268.161 313.039 268.444 313.215L274.838 316.953C275.127 317.115 275.368 317.352 275.534 317.639C275.7 317.927 275.786 318.253 275.782 318.585C275.792 318.913 275.709 319.236 275.542 319.518C275.375 319.8 275.13 320.028 274.838 320.175L268.616 323.784C268.333 323.96 268.005 324.049 267.672 324.042Z'
							fill='#5C697C'
						/>
						<path
							d='M268.187 313.429L274.581 317.124C274.822 317.258 275.023 317.454 275.163 317.692C275.304 317.93 275.377 318.202 275.377 318.478C275.377 318.754 275.304 319.025 275.163 319.263C275.023 319.501 274.822 319.697 274.581 319.831L268.359 323.44C268.121 323.568 267.856 323.635 267.586 323.635C267.317 323.635 267.051 323.568 266.814 323.44L260.377 319.745C260.147 319.603 259.956 319.404 259.824 319.167C259.692 318.93 259.623 318.663 259.623 318.392C259.623 318.121 259.692 317.854 259.824 317.617C259.956 317.38 260.147 317.181 260.377 317.039L266.599 313.472C266.833 313.317 267.106 313.231 267.387 313.224C267.668 313.216 267.945 313.287 268.187 313.429Z'
							fill='#CBCFDA'
						/>
						<path
							d='M355.637 326.834C355.265 326.831 354.898 326.743 354.565 326.577L348.858 323.225C348.518 323.035 348.236 322.758 348.039 322.422C347.842 322.086 347.738 321.703 347.738 321.313C347.738 320.924 347.842 320.541 348.039 320.205C348.236 319.869 348.518 319.592 348.858 319.402L354.393 316.179C354.737 315.994 355.119 315.891 355.509 315.878C355.885 315.892 356.253 315.995 356.581 316.179L362.331 319.53C362.667 319.719 362.947 319.994 363.143 320.326C363.339 320.658 363.444 321.035 363.447 321.421C363.442 321.817 363.333 322.205 363.129 322.545C362.926 322.884 362.635 323.164 362.288 323.354L356.753 326.577C356.409 326.756 356.025 326.845 355.637 326.834Z'
							fill='#5C697C'
						/>
						<path
							d='M356.453 316.48L362.074 319.745C362.369 319.909 362.615 320.148 362.786 320.439C362.958 320.73 363.048 321.062 363.048 321.399C363.048 321.737 362.958 322.069 362.786 322.36C362.615 322.65 362.369 322.89 362.074 323.054L356.539 326.233C356.253 326.403 355.927 326.493 355.595 326.493C355.262 326.493 354.936 326.403 354.651 326.233L348.901 322.925C348.616 322.752 348.381 322.51 348.218 322.22C348.055 321.93 347.969 321.603 347.969 321.271C347.969 320.938 348.055 320.611 348.218 320.321C348.381 320.031 348.616 319.789 348.901 319.616L354.479 316.437C354.77 316.234 355.118 316.128 355.473 316.136C355.828 316.144 356.171 316.264 356.453 316.48Z'
							fill='#CBCFDA'
						/>
						<path
							d='M294.534 323.913L304.532 318.156L314.616 324.042L304.66 329.799L294.534 323.913Z'
							fill='#5C697C'
						/>
						<path
							d='M304.532 318.499L313.972 324.042L304.66 329.412L295.177 323.913L304.532 318.499Z'
							fill='#CBCFDA'
						/>
						<path
							d='M284.021 329.971L293.976 324.214L304.145 330.1L294.147 335.857L284.021 329.971Z'
							fill='#5C697C'
						/>
						<path
							d='M293.976 324.6L303.502 330.1L294.148 335.513L284.664 329.971L293.976 324.6Z'
							fill='#CBCFDA'
						/>
						<path
							d='M330.578 341.486C330.191 341.488 329.809 341.399 329.462 341.228L323.67 337.834C323.342 337.639 323.071 337.363 322.882 337.031C322.694 336.699 322.596 336.325 322.597 335.943C322.59 335.566 322.686 335.195 322.875 334.869C323.065 334.544 323.339 334.276 323.67 334.096L339.246 325.073C339.569 324.876 339.94 324.771 340.319 324.771C340.697 324.771 341.068 324.876 341.391 325.073L347.227 328.467C347.561 328.655 347.837 328.93 348.026 329.263C348.215 329.596 348.31 329.975 348.3 330.358C348.298 330.733 348.197 331.1 348.009 331.425C347.821 331.749 347.551 332.018 347.227 332.205L331.651 341.228C331.317 341.394 330.951 341.483 330.578 341.486Z'
							fill='#5C697C'
						/>
						<path
							d='M341.22 325.331L347.055 328.725C347.341 328.886 347.579 329.119 347.745 329.403C347.91 329.686 347.998 330.008 347.998 330.336C347.998 330.664 347.91 330.987 347.745 331.27C347.579 331.553 347.341 331.787 347.055 331.947L331.479 340.927C331.2 341.093 330.881 341.181 330.557 341.181C330.232 341.181 329.913 341.093 329.634 340.927L323.841 337.533C323.554 337.378 323.314 337.149 323.147 336.869C322.98 336.589 322.892 336.269 322.892 335.943C322.892 335.617 322.98 335.297 323.147 335.017C323.314 334.737 323.554 334.508 323.841 334.353L339.375 325.331C339.66 325.184 339.976 325.107 340.297 325.107C340.618 325.107 340.934 325.184 341.22 325.331Z'
							fill='#CBCFDA'
						/>
						<path
							d='M238.279 381.271C237.861 381.258 237.452 381.156 237.077 380.97L231.671 377.791C231.273 377.59 230.94 377.283 230.707 376.904C230.473 376.525 230.35 376.088 230.35 375.643C230.35 375.197 230.473 374.761 230.707 374.381C230.94 374.002 231.273 373.695 231.671 373.495L238.064 369.8L244.629 373.666C244.992 373.876 245.293 374.176 245.504 374.538C245.714 374.9 245.827 375.31 245.831 375.729C245.823 376.147 245.708 376.555 245.498 376.916C245.288 377.277 244.989 377.578 244.629 377.791L239.394 380.799C239.065 381.043 238.683 381.205 238.279 381.271Z'
							fill='#5C697C'
						/>
						<path
							d='M238.107 370.358L244.544 374.096C244.855 374.277 245.114 374.538 245.294 374.85C245.474 375.163 245.568 375.518 245.568 375.879C245.568 376.24 245.474 376.595 245.294 376.908C245.114 377.221 244.855 377.481 244.544 377.662L239.351 380.67C239.041 380.859 238.685 380.96 238.322 380.96C237.958 380.96 237.602 380.859 237.292 380.67L231.885 377.533C231.573 377.352 231.315 377.092 231.135 376.779C230.955 376.466 230.86 376.111 230.86 375.75C230.86 375.389 230.955 375.034 231.135 374.722C231.315 374.409 231.573 374.149 231.885 373.967L238.15 370.358H238.107Z'
							fill='#CBCFDA'
						/>
						<path
							d='M248.663 375.299C248.29 375.301 247.922 375.212 247.59 375.041L241.712 371.604C241.387 371.417 241.118 371.147 240.929 370.823C240.741 370.499 240.641 370.131 240.639 369.757C240.632 369.38 240.728 369.009 240.918 368.683C241.107 368.357 241.381 368.09 241.712 367.909L247.419 364.601C247.732 364.417 248.086 364.313 248.448 364.3C248.826 364.308 249.195 364.411 249.521 364.601L255.443 368.038C255.774 368.219 256.05 368.486 256.243 368.811C256.436 369.137 256.537 369.508 256.537 369.885C256.537 370.263 256.436 370.634 256.243 370.96C256.05 371.285 255.774 371.552 255.443 371.733L249.693 375.041C249.377 375.212 249.022 375.301 248.663 375.299Z'
							fill='#5C697C'
						/>
						<path
							d='M249.392 364.901L255.271 368.296C255.548 368.453 255.778 368.681 255.937 368.957C256.097 369.232 256.182 369.545 256.182 369.864C256.182 370.183 256.097 370.495 255.937 370.771C255.778 371.046 255.548 371.275 255.271 371.432L249.564 374.74C249.293 374.908 248.981 374.996 248.663 374.996C248.345 374.996 248.033 374.908 247.762 374.74L241.926 371.303C241.65 371.146 241.42 370.917 241.26 370.642C241.1 370.367 241.015 370.054 241.015 369.735C241.015 369.416 241.1 369.103 241.26 368.828C241.42 368.553 241.65 368.324 241.926 368.167L247.633 364.859C247.908 364.722 248.212 364.655 248.518 364.662C248.825 364.67 249.125 364.752 249.392 364.901Z'
							fill='#CBCFDA'
						/>
						<path
							d='M259.09 369.241C258.711 369.248 258.338 369.144 258.017 368.94L252.139 365.546C251.807 365.365 251.531 365.098 251.338 364.772C251.146 364.447 251.044 364.076 251.044 363.698C251.044 363.321 251.146 362.95 251.338 362.624C251.531 362.299 251.807 362.032 252.139 361.851L257.846 358.543C258.169 358.345 258.54 358.241 258.918 358.241C259.297 358.241 259.668 358.345 259.991 358.543L265.87 361.98C266.2 362.16 266.475 362.428 266.664 362.754C266.853 363.079 266.949 363.451 266.943 363.827C266.951 364.199 266.855 364.565 266.665 364.884C266.476 365.203 266.2 365.462 265.87 365.632L260.12 368.94C259.818 369.148 259.457 369.253 259.09 369.241Z'
							fill='#5C697C'
						/>
						<path
							d='M259.862 358.8L265.784 362.238C266.061 362.395 266.29 362.623 266.45 362.899C266.61 363.174 266.695 363.487 266.695 363.806C266.695 364.124 266.61 364.437 266.45 364.713C266.29 364.988 266.061 365.216 265.784 365.374L260.034 368.682C259.763 368.85 259.451 368.938 259.133 368.938C258.815 368.938 258.503 368.85 258.232 368.682L252.353 365.245C252.077 365.088 251.847 364.859 251.687 364.584C251.527 364.309 251.443 363.996 251.443 363.677C251.443 363.358 251.527 363.045 251.687 362.77C251.847 362.495 252.077 362.266 252.353 362.109L258.06 358.8C258.338 358.654 258.647 358.577 258.961 358.577C259.275 358.577 259.585 358.654 259.862 358.8Z'
							fill='#CBCFDA'
						/>
						<path
							d='M269.517 363.226C269.143 363.233 268.774 363.144 268.444 362.968L262.523 359.531C262.213 359.333 261.958 359.06 261.781 358.737C261.604 358.414 261.511 358.052 261.511 357.683C261.511 357.315 261.604 356.953 261.781 356.63C261.958 356.307 262.213 356.034 262.523 355.836L268.273 352.528C268.603 352.351 268.972 352.263 269.346 352.27C269.719 352.268 270.087 352.356 270.418 352.528L276.297 355.965C276.627 356.145 276.902 356.413 277.091 356.739C277.28 357.064 277.376 357.436 277.37 357.812C277.367 358.187 277.267 358.555 277.079 358.879C276.891 359.203 276.621 359.472 276.297 359.66L270.547 362.968C270.231 363.139 269.877 363.228 269.517 363.226Z'
							fill='#5C697C'
						/>
						<path
							d='M270.247 352.828L276.168 356.266C276.434 356.432 276.654 356.663 276.806 356.937C276.958 357.211 277.038 357.52 277.038 357.834C277.038 358.148 276.958 358.456 276.806 358.731C276.654 359.005 276.434 359.236 276.168 359.402L270.418 362.71C270.141 362.857 269.831 362.934 269.517 362.934C269.203 362.934 268.894 362.857 268.616 362.71L262.695 359.23C262.417 359.079 262.185 358.855 262.024 358.583C261.862 358.311 261.777 358 261.777 357.683C261.777 357.367 261.862 357.056 262.024 356.784C262.185 356.512 262.417 356.288 262.695 356.137L268.444 352.828C268.715 352.661 269.027 352.573 269.346 352.573C269.664 352.573 269.976 352.661 270.247 352.828Z'
							fill='#CBCFDA'
						/>
						<path
							d='M280.545 356.996C280.169 356.983 279.801 356.88 279.472 356.695L273.551 353.258C273.241 353.06 272.985 352.787 272.809 352.464C272.632 352.141 272.539 351.779 272.539 351.41C272.539 351.042 272.632 350.68 272.809 350.357C272.985 350.034 273.241 349.761 273.551 349.563L279.301 346.298C279.606 346.095 279.965 345.99 280.33 345.997C280.71 345.984 281.085 346.089 281.403 346.298L287.325 349.692C287.635 349.89 287.89 350.163 288.067 350.486C288.244 350.809 288.337 351.171 288.337 351.539C288.337 351.908 288.244 352.27 288.067 352.593C287.89 352.916 287.635 353.189 287.325 353.387L281.618 356.695C281.287 356.874 280.92 356.977 280.545 356.996Z'
							fill='#5C697C'
						/>
						<path
							d='M281.06 346.555L286.982 349.993C287.258 350.15 287.488 350.378 287.648 350.654C287.808 350.929 287.892 351.242 287.892 351.561C287.892 351.879 287.808 352.192 287.648 352.468C287.488 352.743 287.258 352.971 286.982 353.129L281.275 356.437C280.997 356.584 280.688 356.661 280.373 356.661C280.059 356.661 279.75 356.584 279.472 356.437L273.551 353C273.274 352.843 273.044 352.614 272.884 352.339C272.724 352.064 272.64 351.751 272.64 351.432C272.64 351.113 272.724 350.8 272.884 350.525C273.044 350.25 273.274 350.021 273.551 349.864L279.258 346.555C279.529 346.388 279.841 346.3 280.159 346.3C280.477 346.3 280.789 346.388 281.06 346.555Z'
							fill='#CBCFDA'
						/>
						<path
							d='M290.758 350.981C290.378 350.994 290.003 350.889 289.685 350.68L283.763 347.243C283.453 347.05 283.197 346.781 283.019 346.462C282.841 346.142 282.748 345.783 282.748 345.417C282.748 345.051 282.841 344.692 283.019 344.372C283.197 344.052 283.453 343.784 283.763 343.591L289.47 340.283C289.802 340.111 290.17 340.023 290.543 340.025C290.917 340.018 291.286 340.106 291.616 340.283L297.537 343.72C297.854 343.913 298.115 344.184 298.295 344.508C298.476 344.832 298.569 345.197 298.567 345.567C298.574 345.944 298.478 346.315 298.288 346.641C298.099 346.967 297.825 347.234 297.494 347.415L291.787 350.68C291.483 350.883 291.123 350.988 290.758 350.981Z'
							fill='#5C697C'
						/>
						<path
							d='M291.444 340.54L297.366 343.978C297.642 344.135 297.872 344.363 298.032 344.639C298.192 344.914 298.276 345.227 298.276 345.546C298.276 345.864 298.192 346.177 298.032 346.453C297.872 346.728 297.642 346.956 297.366 347.114L291.659 350.422C291.38 350.589 291.061 350.677 290.736 350.677C290.411 350.677 290.093 350.589 289.814 350.422L283.935 346.985C283.658 346.827 283.428 346.599 283.268 346.324C283.109 346.048 283.024 345.735 283.024 345.417C283.024 345.098 283.109 344.785 283.268 344.51C283.428 344.234 283.658 344.006 283.935 343.849L289.642 340.54C289.92 340.394 290.229 340.317 290.543 340.317C290.857 340.317 291.166 340.394 291.444 340.54Z'
							fill='#CBCFDA'
						/>
						<path
							d='M301.228 344.923C300.852 344.904 300.486 344.801 300.155 344.622L294.319 341.228C293.989 341.037 293.715 340.761 293.526 340.429C293.338 340.096 293.241 339.72 293.246 339.337C293.24 338.961 293.336 338.589 293.525 338.264C293.714 337.938 293.989 337.67 294.319 337.49L299.983 334.225C300.31 334.035 300.679 333.932 301.056 333.924C301.432 333.937 301.8 334.04 302.129 334.225L307.964 337.619C308.295 337.806 308.569 338.078 308.76 338.406C308.951 338.735 309.052 339.108 309.052 339.488C309.052 339.868 308.951 340.241 308.76 340.569C308.569 340.898 308.295 341.169 307.964 341.357L302.3 344.622C301.974 344.812 301.605 344.915 301.228 344.923Z'
							fill='#5C697C'
						/>
						<path
							d='M302 334.482L307.879 337.877C308.154 338.04 308.383 338.273 308.542 338.551C308.7 338.83 308.784 339.145 308.784 339.466C308.784 339.787 308.7 340.102 308.542 340.381C308.383 340.66 308.154 340.892 307.879 341.056L302.215 344.364C301.929 344.511 301.613 344.588 301.292 344.588C300.971 344.588 300.655 344.511 300.369 344.364L294.491 340.927C294.215 340.764 293.987 340.531 293.828 340.252C293.669 339.974 293.586 339.658 293.586 339.337C293.586 339.016 293.669 338.701 293.828 338.422C293.987 338.144 294.215 337.911 294.491 337.748L300.155 334.482C300.438 334.328 300.755 334.247 301.077 334.247C301.4 334.247 301.717 334.328 302 334.482Z'
							fill='#CBCFDA'
						/>
						<path
							d='M311.612 338.908C311.232 338.921 310.857 338.816 310.539 338.607L304.66 335.213C304.33 335.025 304.056 334.754 303.865 334.425C303.673 334.097 303.573 333.724 303.573 333.344C303.573 332.964 303.673 332.591 303.865 332.262C304.056 331.934 304.33 331.662 304.66 331.475L310.582 328.338C310.908 328.149 311.277 328.045 311.655 328.038C312.03 328.057 312.397 328.16 312.727 328.338L318.563 331.733C318.891 331.927 319.162 332.204 319.35 332.535C319.538 332.867 319.637 333.242 319.636 333.623C319.634 333.998 319.533 334.366 319.345 334.69C319.157 335.014 318.887 335.283 318.563 335.471L312.899 338.779C312.491 338.943 312.044 338.988 311.612 338.908Z'
							fill='#5C697C'
						/>
						<path
							d='M312.341 328.338L318.177 331.733C318.464 331.887 318.704 332.116 318.871 332.396C319.038 332.676 319.126 332.996 319.126 333.322C319.126 333.648 319.038 333.968 318.871 334.248C318.704 334.528 318.464 334.758 318.177 334.912L312.513 338.177C312.234 338.344 311.915 338.432 311.59 338.432C311.266 338.432 310.947 338.344 310.668 338.177L304.832 334.783C304.556 334.62 304.328 334.387 304.169 334.108C304.011 333.83 303.927 333.514 303.927 333.193C303.927 332.873 304.011 332.557 304.169 332.278C304.328 332 304.556 331.767 304.832 331.604L310.582 328.338C310.848 328.179 311.152 328.095 311.462 328.095C311.771 328.095 312.075 328.179 312.341 328.338Z'
							fill='#CBCFDA'
						/>
						<path
							d='M213.992 395.278C213.572 395.28 213.159 395.177 212.79 394.977L205.753 390.681C205.554 390.582 205.388 390.428 205.274 390.238C205.16 390.048 205.103 389.828 205.109 389.606C205.113 389.381 205.175 389.16 205.287 388.965C205.4 388.769 205.56 388.606 205.753 388.489L226.307 376.631C226.659 376.427 227.059 376.323 227.465 376.33C227.884 376.338 228.295 376.44 228.667 376.631L234.074 379.767C234.471 379.968 234.805 380.275 235.038 380.654C235.271 381.034 235.394 381.47 235.394 381.916C235.394 382.361 235.271 382.798 235.038 383.177C234.805 383.556 234.471 383.863 234.074 384.064L215.193 394.977C214.828 395.187 214.412 395.291 213.992 395.278Z'
							fill='#5C697C'
						/>
						<path
							d='M226.436 377.061C226.76 376.891 227.121 376.803 227.487 376.803C227.853 376.803 228.214 376.891 228.538 377.061L233.902 380.24C234.214 380.421 234.472 380.682 234.652 380.995C234.832 381.307 234.927 381.662 234.927 382.023C234.927 382.384 234.832 382.739 234.652 383.052C234.472 383.365 234.214 383.625 233.902 383.806L215.022 394.719C214.705 394.888 214.351 394.977 213.992 394.977C213.633 394.977 213.279 394.888 212.962 394.719L205.882 390.423C205.739 390.339 205.621 390.22 205.539 390.077C205.457 389.934 205.414 389.772 205.414 389.606C205.414 389.441 205.457 389.279 205.539 389.136C205.621 388.992 205.739 388.873 205.882 388.79L226.436 377.061Z'
							fill='#CBCFDA'
						/>
						<path
							d='M332.037 275.878L337.744 279.186C338.039 279.35 338.285 279.589 338.456 279.88C338.628 280.171 338.718 280.503 338.718 280.84C338.718 281.178 338.628 281.51 338.456 281.801C338.285 282.092 338.039 282.331 337.744 282.495L332.209 285.674C331.923 285.844 331.597 285.934 331.265 285.934C330.932 285.934 330.606 285.844 330.321 285.674L324.571 282.323C324.286 282.156 324.049 281.918 323.885 281.631C323.721 281.345 323.634 281.02 323.634 280.69C323.634 280.36 323.721 280.035 323.885 279.749C324.049 279.462 324.286 279.224 324.571 279.057L330.106 275.835C330.407 275.684 330.74 275.608 331.077 275.616C331.413 275.623 331.743 275.713 332.037 275.878Z'
							fill='#41C0FD'
						/>
						<path
							d='M321.395 281.979L327.145 285.33C327.43 285.497 327.666 285.735 327.831 286.022C327.995 286.308 328.081 286.633 328.081 286.963C328.081 287.293 327.995 287.618 327.831 287.904C327.666 288.191 327.43 288.429 327.145 288.596L321.653 291.818C321.359 291.988 321.026 292.077 320.687 292.077C320.348 292.077 320.015 291.988 319.722 291.818L314.015 288.51C313.72 288.346 313.474 288.107 313.302 287.816C313.131 287.525 313.041 287.193 313.041 286.856C313.041 286.518 313.131 286.186 313.302 285.896C313.474 285.605 313.72 285.365 314.015 285.201L319.55 281.979C319.831 281.817 320.149 281.732 320.473 281.732C320.797 281.732 321.115 281.817 321.395 281.979Z'
							fill='#41C0FD'
						/>
						<path
							d='M310.797 288.123L316.504 291.474C316.8 291.632 317.048 291.867 317.221 292.154C317.394 292.442 317.485 292.771 317.485 293.107C317.485 293.443 317.394 293.772 317.221 294.06C317.048 294.348 316.8 294.583 316.504 294.74L310.968 297.962C310.683 298.132 310.356 298.222 310.024 298.222C309.692 298.222 309.366 298.132 309.08 297.962L303.373 294.611C303.077 294.454 302.829 294.219 302.656 293.931C302.483 293.643 302.392 293.314 302.392 292.978C302.392 292.642 302.483 292.313 302.656 292.025C302.829 291.738 303.077 291.503 303.373 291.346L308.908 288.123C309.194 287.953 309.52 287.863 309.852 287.863C310.185 287.863 310.511 287.953 310.797 288.123Z'
							fill='#41C0FD'
						/>
						<path
							d='M333.024 288.768L338.774 292.076C339.058 292.248 339.293 292.491 339.456 292.781C339.619 293.07 339.705 293.397 339.705 293.73C339.705 294.063 339.619 294.39 339.456 294.679C339.293 294.969 339.058 295.212 338.774 295.384L333.238 298.564C332.953 298.734 332.627 298.824 332.294 298.824C331.962 298.824 331.636 298.734 331.35 298.564L325.643 295.255C325.359 295.083 325.124 294.84 324.961 294.551C324.798 294.261 324.712 293.934 324.712 293.601C324.712 293.268 324.798 292.941 324.961 292.652C325.124 292.362 325.359 292.119 325.643 291.947L331.179 288.768C331.459 288.605 331.777 288.52 332.101 288.52C332.425 288.52 332.743 288.605 333.024 288.768Z'
							fill='#41C0FD'
						/>
						<path
							d='M355.294 289.369L361.001 292.72C361.286 292.887 361.522 293.125 361.687 293.412C361.851 293.698 361.937 294.023 361.937 294.353C361.937 294.683 361.851 295.008 361.687 295.294C361.522 295.581 361.286 295.819 361.001 295.986L355.466 299.208C355.18 299.378 354.854 299.468 354.522 299.468C354.189 299.468 353.863 299.378 353.578 299.208L347.828 295.857C347.543 295.69 347.306 295.452 347.142 295.165C346.978 294.879 346.892 294.554 346.892 294.224C346.892 293.894 346.978 293.569 347.142 293.283C347.306 292.996 347.543 292.758 347.828 292.591L353.406 289.369C353.696 289.211 354.02 289.128 354.35 289.128C354.68 289.128 355.004 289.211 355.294 289.369Z'
							fill='#41C0FD'
						/>
						<path
							d='M299.855 293.966L306.806 298.005C307.002 298.118 307.165 298.281 307.278 298.477C307.391 298.673 307.451 298.896 307.451 299.122C307.451 299.349 307.391 299.571 307.278 299.767C307.165 299.963 307.002 300.126 306.806 300.239L300.069 304.149C299.871 304.254 299.65 304.309 299.425 304.309C299.201 304.309 298.98 304.254 298.782 304.149L291.83 300.11C291.634 299.997 291.471 299.835 291.358 299.638C291.245 299.442 291.185 299.22 291.185 298.993C291.185 298.767 291.245 298.544 291.358 298.348C291.471 298.152 291.634 297.989 291.83 297.876L298.567 293.966C298.765 293.861 298.986 293.806 299.211 293.806C299.435 293.806 299.656 293.861 299.855 293.966Z'
							fill='#41C0FD'
						/>
						<path
							d='M344.652 295.513L350.359 298.864C350.656 299.021 350.904 299.257 351.077 299.544C351.25 299.832 351.341 300.161 351.341 300.497C351.341 300.833 351.25 301.162 351.077 301.45C350.904 301.737 350.656 301.973 350.359 302.13L344.867 305.352C344.573 305.522 344.24 305.611 343.901 305.611C343.563 305.611 343.23 305.522 342.936 305.352L337.229 302.001C336.944 301.834 336.708 301.596 336.543 301.309C336.379 301.023 336.293 300.698 336.293 300.368C336.293 300.038 336.379 299.713 336.543 299.427C336.708 299.14 336.944 298.902 337.229 298.735L342.764 295.513C343.057 295.366 343.381 295.289 343.708 295.289C344.036 295.289 344.359 295.366 344.652 295.513Z'
							fill='#41C0FD'
						/>
						<path
							d='M289.127 300.239L296.078 304.235C296.274 304.354 296.435 304.521 296.547 304.72C296.659 304.92 296.718 305.145 296.718 305.374C296.718 305.602 296.659 305.827 296.547 306.027C296.435 306.226 296.274 306.393 296.078 306.512L289.299 310.379C289.108 310.506 288.884 310.574 288.655 310.574C288.426 310.574 288.202 310.506 288.011 310.379L281.103 306.383C280.908 306.264 280.746 306.097 280.634 305.898C280.522 305.698 280.463 305.473 280.463 305.245C280.463 305.016 280.522 304.791 280.634 304.591C280.746 304.392 280.908 304.225 281.103 304.106L287.84 300.239C288.03 300.112 288.254 300.044 288.483 300.044C288.713 300.044 288.936 300.112 289.127 300.239Z'
							fill='#41C0FD'
						/>
						<path
							d='M334.054 301.657L339.761 304.965C340.056 305.129 340.302 305.369 340.473 305.659C340.644 305.95 340.735 306.282 340.735 306.62C340.735 306.957 340.644 307.289 340.473 307.58C340.302 307.871 340.056 308.11 339.761 308.274L334.225 311.453C333.932 311.623 333.599 311.712 333.26 311.712C332.921 311.712 332.588 311.623 332.294 311.453L326.63 308.145C326.334 307.988 326.086 307.753 325.913 307.465C325.74 307.177 325.649 306.848 325.649 306.512C325.649 306.176 325.74 305.847 325.913 305.559C326.086 305.272 326.334 305.037 326.63 304.879L332.166 301.657C332.451 301.487 332.777 301.397 333.11 301.397C333.442 301.397 333.768 301.487 334.054 301.657Z'
							fill='#41C0FD'
						/>
						<path
							d='M278.786 306.426L285.179 310.164C285.41 310.306 285.6 310.506 285.733 310.743C285.865 310.979 285.934 311.246 285.934 311.518C285.934 311.789 285.865 312.056 285.733 312.293C285.6 312.53 285.41 312.729 285.179 312.871L278.957 316.48C278.712 316.608 278.44 316.675 278.164 316.675C277.887 316.675 277.615 316.608 277.37 316.48L270.976 312.742C270.745 312.6 270.555 312.401 270.423 312.164C270.291 311.927 270.221 311.66 270.221 311.389C270.221 311.117 270.291 310.851 270.423 310.614C270.555 310.377 270.745 310.178 270.976 310.035L277.241 306.426C277.478 306.298 277.744 306.231 278.013 306.231C278.283 306.231 278.548 306.298 278.786 306.426Z'
							fill='#41C0FD'
						/>
						<path
							d='M307.106 303.547L313.5 307.285C313.731 307.428 313.921 307.627 314.053 307.864C314.185 308.101 314.255 308.367 314.255 308.639C314.255 308.91 314.185 309.177 314.053 309.414C313.921 309.651 313.731 309.85 313.5 309.992L290.586 323.225C290.348 323.374 290.073 323.453 289.792 323.453C289.511 323.453 289.237 323.374 288.998 323.225L282.605 319.487C282.373 319.351 282.182 319.156 282.049 318.922C281.915 318.689 281.845 318.424 281.845 318.156C281.845 317.887 281.915 317.622 282.049 317.389C282.182 317.155 282.373 316.96 282.605 316.824L305.562 303.547C305.799 303.42 306.064 303.353 306.334 303.353C306.603 303.353 306.869 303.42 307.106 303.547Z'
							fill='#41C0FD'
						/>
						<path
							d='M257.545 318.714L263.939 322.452C264.17 322.589 264.362 322.784 264.495 323.017C264.628 323.251 264.698 323.515 264.698 323.784C264.698 324.053 264.628 324.317 264.495 324.551C264.362 324.784 264.17 324.979 263.939 325.116L257.717 328.725C257.487 328.875 257.219 328.955 256.945 328.955C256.67 328.955 256.402 328.875 256.172 328.725L249.779 325.03C249.548 324.888 249.357 324.689 249.225 324.452C249.093 324.215 249.024 323.948 249.024 323.677C249.024 323.405 249.093 323.138 249.225 322.902C249.357 322.665 249.548 322.466 249.779 322.323L256.001 318.714C256.235 318.578 256.502 318.507 256.773 318.507C257.044 318.507 257.31 318.578 257.545 318.714Z'
							fill='#41C0FD'
						/>
						<path
							d='M279.816 319.359L286.166 323.054C286.408 323.187 286.609 323.383 286.749 323.621C286.889 323.859 286.963 324.131 286.963 324.407C286.963 324.683 286.889 324.955 286.749 325.193C286.609 325.431 286.408 325.627 286.166 325.76L279.944 329.369C279.707 329.497 279.441 329.564 279.172 329.564C278.902 329.564 278.637 329.497 278.4 329.369L272.006 325.631C271.775 325.489 271.585 325.29 271.453 325.053C271.321 324.816 271.251 324.549 271.251 324.278C271.251 324.007 271.321 323.74 271.453 323.503C271.585 323.266 271.775 323.067 272.006 322.925L278.228 319.359C278.466 319.21 278.741 319.131 279.022 319.131C279.302 319.131 279.577 319.21 279.816 319.359Z'
							fill='#41C0FD'
						/>
						<path
							d='M317.877 310.895C318.156 310.728 318.474 310.64 318.799 310.64C319.124 310.64 319.443 310.728 319.722 310.895L329.806 316.738L336.113 320.433L339.289 322.28L324.485 330.873C324.206 331.04 323.887 331.128 323.562 331.128C323.237 331.128 322.919 331.04 322.64 330.873L314.058 325.889L318.907 323.097L310.582 318.242C310.295 318.087 310.055 317.858 309.888 317.578C309.721 317.298 309.633 316.978 309.633 316.652C309.633 316.326 309.721 316.006 309.888 315.726C310.055 315.446 310.295 315.216 310.582 315.062L317.877 310.895Z'
							fill='#41C0FD'
						/>
						<path
							d='M246.947 324.858L253.34 328.596C253.571 328.738 253.761 328.938 253.893 329.175C254.026 329.411 254.095 329.678 254.095 329.95C254.095 330.221 254.026 330.488 253.893 330.725C253.761 330.961 253.571 331.161 253.34 331.303L247.118 334.869C246.88 335.018 246.605 335.096 246.324 335.096C246.044 335.096 245.769 335.018 245.531 334.869L239.137 331.131C238.906 330.994 238.714 330.8 238.581 330.566C238.448 330.332 238.378 330.068 238.378 329.799C238.378 329.53 238.448 329.266 238.581 329.032C238.714 328.799 238.906 328.604 239.137 328.467L245.359 324.858C245.604 324.73 245.876 324.663 246.153 324.663C246.429 324.663 246.701 324.73 246.947 324.858Z'
							fill='#41C0FD'
						/>
						<path
							d='M269.174 325.503L275.567 329.198C275.798 329.34 275.989 329.539 276.121 329.776C276.253 330.013 276.322 330.28 276.322 330.551C276.322 330.822 276.253 331.089 276.121 331.326C275.989 331.563 275.798 331.762 275.567 331.904L269.346 335.514C269.108 335.641 268.843 335.708 268.573 335.708C268.304 335.708 268.038 335.641 267.801 335.514L261.407 331.776C261.176 331.633 260.986 331.434 260.854 331.197C260.722 330.96 260.652 330.694 260.652 330.422C260.652 330.151 260.722 329.884 260.854 329.647C260.986 329.41 261.176 329.211 261.407 329.069L267.629 325.503C267.859 325.353 268.127 325.273 268.402 325.273C268.676 325.273 268.944 325.353 269.174 325.503Z'
							fill='#41C0FD'
						/>
						<path
							d='M283.506 329.799L292.989 335.342L283.677 340.712L274.194 335.213L283.506 329.799Z'
							fill='#41C0FD'
						/>
						<path
							d='M236.305 331.002L242.698 334.74C242.93 334.877 243.122 335.072 243.255 335.305C243.388 335.539 243.458 335.803 243.458 336.072C243.458 336.341 243.388 336.605 243.255 336.839C243.122 337.072 242.93 337.267 242.698 337.404L236.477 341.013C236.239 341.141 235.974 341.208 235.704 341.208C235.435 341.208 235.169 341.141 234.932 341.013L228.538 337.275C228.308 337.133 228.117 336.934 227.985 336.697C227.853 336.46 227.783 336.193 227.783 335.922C227.783 335.65 227.853 335.384 227.985 335.147C228.117 334.91 228.308 334.711 228.538 334.568L234.76 331.002C234.99 330.852 235.258 330.773 235.533 330.773C235.807 330.773 236.075 330.852 236.305 331.002Z'
							fill='#41C0FD'
						/>
						<path
							d='M258.575 331.604L264.969 335.342C265.21 335.475 265.411 335.671 265.552 335.909C265.692 336.147 265.766 336.419 265.766 336.695C265.766 336.971 265.692 337.243 265.552 337.481C265.411 337.719 265.21 337.915 264.969 338.048L258.747 341.614C258.517 341.764 258.249 341.844 257.974 341.844C257.7 341.844 257.432 341.764 257.202 341.614L250.808 337.92C250.578 337.777 250.387 337.578 250.255 337.341C250.123 337.104 250.054 336.837 250.054 336.566C250.054 336.295 250.123 336.028 250.255 335.791C250.387 335.554 250.578 335.355 250.808 335.213L257.03 331.604C257.27 331.483 257.534 331.42 257.803 331.42C258.071 331.42 258.336 331.483 258.575 331.604Z'
							fill='#41C0FD'
						/>
						<path
							d='M273.036 335.857L282.519 341.4L273.165 346.813L263.681 341.271L273.036 335.857Z'
							fill='#41C0FD'
						/>
						<path
							d='M225.706 336.931L232.1 340.669C232.33 340.812 232.521 341.011 232.653 341.248C232.785 341.484 232.855 341.751 232.855 342.023C232.855 342.294 232.785 342.561 232.653 342.798C232.521 343.035 232.33 343.234 232.1 343.376L225.878 346.985C225.633 347.113 225.36 347.18 225.084 347.18C224.808 347.18 224.535 347.113 224.29 346.985L217.897 343.247C217.666 343.105 217.475 342.906 217.343 342.669C217.211 342.432 217.142 342.165 217.142 341.894C217.142 341.622 217.211 341.356 217.343 341.119C217.475 340.882 217.666 340.683 217.897 340.54L224.119 336.931C224.364 336.803 224.636 336.736 224.912 336.736C225.189 336.736 225.461 336.803 225.706 336.931Z'
							fill='#41C0FD'
						/>
						<path
							d='M247.933 337.748L254.327 341.486C254.558 341.628 254.748 341.827 254.88 342.064C255.013 342.301 255.082 342.568 255.082 342.839C255.082 343.11 255.013 343.377 254.88 343.614C254.748 343.851 254.558 344.05 254.327 344.192L248.105 347.758C247.875 347.908 247.607 347.988 247.333 347.988C247.059 347.988 246.79 347.908 246.56 347.758L240.167 344.064C239.936 343.921 239.746 343.722 239.614 343.485C239.481 343.248 239.412 342.981 239.412 342.71C239.412 342.439 239.481 342.172 239.614 341.935C239.746 341.698 239.936 341.499 240.167 341.357L246.389 337.748C246.619 337.598 246.887 337.518 247.161 337.518C247.435 337.518 247.704 337.598 247.933 337.748Z'
							fill='#41C0FD'
						/>
						<path
							d='M320.065 336.931L325.901 340.326C326.177 340.489 326.405 340.722 326.564 341C326.722 341.279 326.806 341.594 326.806 341.915C326.806 342.236 326.722 342.551 326.564 342.83C326.405 343.109 326.177 343.342 325.901 343.505L311.011 352.098C310.729 352.256 310.412 352.339 310.089 352.339C309.765 352.339 309.448 352.256 309.166 352.098L303.373 348.704C303.086 348.549 302.846 348.32 302.679 348.04C302.512 347.76 302.424 347.44 302.424 347.114C302.424 346.788 302.512 346.468 302.679 346.188C302.846 345.908 303.086 345.679 303.373 345.524L318.22 336.931C318.499 336.765 318.818 336.677 319.143 336.677C319.467 336.677 319.786 336.765 320.065 336.931Z'
							fill='#41C0FD'
						/>
						<path
							d='M263.381 342.474L269.26 345.868C269.536 346.026 269.766 346.254 269.926 346.529C270.086 346.805 270.17 347.118 270.17 347.436C270.17 347.755 270.086 348.068 269.926 348.343C269.766 348.619 269.536 348.847 269.26 349.004L263.553 352.356C263.271 352.514 262.953 352.597 262.63 352.597C262.307 352.597 261.989 352.514 261.708 352.356L255.829 348.919C255.551 348.767 255.32 348.543 255.158 348.271C254.997 347.999 254.912 347.688 254.912 347.372C254.912 347.055 254.997 346.745 255.158 346.472C255.32 346.2 255.551 345.977 255.829 345.825L261.536 342.517C261.811 342.344 262.128 342.248 262.453 342.241C262.777 342.233 263.098 342.314 263.381 342.474Z'
							fill='#41C0FD'
						/>
						<path
							d='M215.322 343.419L220.729 346.555C221.04 346.737 221.299 346.997 221.479 347.31C221.659 347.623 221.753 347.978 221.753 348.339C221.753 348.7 221.659 349.054 221.479 349.367C221.299 349.68 221.04 349.94 220.729 350.122L215.494 353.129C215.184 353.319 214.827 353.419 214.464 353.419C214.1 353.419 213.744 353.319 213.434 353.129L208.027 349.821C207.726 349.631 207.477 349.368 207.305 349.056C207.133 348.745 207.043 348.394 207.043 348.038C207.043 347.681 207.133 347.331 207.305 347.019C207.477 346.707 207.726 346.444 208.027 346.255L213.262 343.247C213.588 343.082 213.953 343.011 214.316 343.042C214.68 343.072 215.028 343.203 215.322 343.419Z'
							fill='#41C0FD'
						/>
						<path
							d='M237.635 344.063L242.999 347.157C243.31 347.344 243.567 347.609 243.746 347.925C243.925 348.241 244.018 348.598 244.018 348.961C244.018 349.325 243.925 349.682 243.746 349.998C243.567 350.314 243.31 350.579 242.999 350.766L237.807 353.774C237.482 353.943 237.122 354.031 236.755 354.031C236.389 354.031 236.029 353.943 235.704 353.774L230.34 350.637C230.029 350.456 229.77 350.196 229.59 349.883C229.41 349.57 229.316 349.215 229.316 348.854C229.316 348.493 229.41 348.138 229.59 347.825C229.77 347.513 230.029 347.252 230.34 347.071L235.575 344.02C235.892 343.846 236.249 343.758 236.611 343.766C236.973 343.773 237.326 343.876 237.635 344.063Z'
							fill='#41C0FD'
						/>
						<path
							d='M252.911 348.532L258.79 351.969C259.066 352.127 259.296 352.355 259.456 352.63C259.616 352.906 259.7 353.219 259.7 353.537C259.7 353.856 259.616 354.169 259.456 354.444C259.296 354.72 259.066 354.948 258.79 355.106L253.083 358.414C252.805 358.561 252.496 358.637 252.182 358.637C251.868 358.637 251.558 358.561 251.28 358.414L245.359 354.934C245.081 354.782 244.85 354.559 244.688 354.286C244.527 354.014 244.442 353.704 244.442 353.387C244.442 353.07 244.527 352.76 244.688 352.487C244.85 352.215 245.081 351.992 245.359 351.84L251.109 348.532C251.38 348.365 251.692 348.276 252.01 348.276C252.328 348.276 252.64 348.365 252.911 348.532Z'
							fill='#41C0FD'
						/>
						<path
							d='M204.723 349.563L210.13 352.699C210.431 352.889 210.68 353.152 210.852 353.464C211.024 353.776 211.115 354.126 211.115 354.483C211.115 354.839 211.024 355.189 210.852 355.501C210.68 355.813 210.431 356.076 210.13 356.266L204.895 359.273C204.585 359.463 204.228 359.563 203.865 359.563C203.502 359.563 203.145 359.463 202.835 359.273L197.429 356.137C197.117 355.955 196.858 355.695 196.678 355.382C196.498 355.069 196.404 354.715 196.404 354.354C196.404 353.993 196.498 353.638 196.678 353.325C196.858 353.012 197.117 352.752 197.429 352.571L202.621 349.563C202.939 349.374 203.302 349.274 203.672 349.274C204.042 349.274 204.405 349.374 204.723 349.563Z'
							fill='#41C0FD'
						/>
						<path
							d='M226.951 350.165L232.357 353.301C232.659 353.49 232.907 353.753 233.079 354.065C233.251 354.377 233.342 354.728 233.342 355.084C233.342 355.44 233.251 355.791 233.079 356.103C232.907 356.415 232.659 356.678 232.357 356.867L227.122 359.918C226.805 360.087 226.452 360.175 226.092 360.175C225.733 360.175 225.379 360.087 225.063 359.918L219.656 356.781C219.344 356.6 219.085 356.34 218.906 356.027C218.726 355.714 218.631 355.359 218.631 354.998C218.631 354.637 218.726 354.282 218.906 353.97C219.085 353.657 219.344 353.396 219.656 353.215L224.891 350.165C225.208 349.995 225.562 349.907 225.921 349.907C226.28 349.907 226.634 349.995 226.951 350.165Z'
							fill='#41C0FD'
						/>
						<path
							d='M242.57 354.676L247.933 357.812C248.245 357.994 248.504 358.254 248.684 358.567C248.864 358.88 248.958 359.234 248.958 359.595C248.958 359.956 248.864 360.311 248.684 360.624C248.504 360.937 248.245 361.197 247.933 361.378L242.698 364.386C242.382 364.555 242.028 364.644 241.669 364.644C241.309 364.644 240.956 364.555 240.639 364.386L235.275 361.249C234.963 361.068 234.705 360.808 234.525 360.495C234.345 360.182 234.25 359.827 234.25 359.466C234.25 359.105 234.345 358.751 234.525 358.438C234.705 358.125 234.963 357.865 235.275 357.683L240.467 354.676C240.785 354.487 241.148 354.387 241.518 354.387C241.888 354.387 242.252 354.487 242.57 354.676Z'
							fill='#41C0FD'
						/>
						<path
							d='M194.082 355.707L199.488 358.844C199.8 359.025 200.059 359.285 200.238 359.598C200.418 359.911 200.513 360.266 200.513 360.627C200.513 360.988 200.418 361.342 200.238 361.655C200.059 361.968 199.8 362.228 199.488 362.41L194.253 365.417C193.936 365.586 193.583 365.675 193.223 365.675C192.864 365.675 192.51 365.586 192.194 365.417L186.83 362.281C186.518 362.099 186.259 361.839 186.079 361.526C185.9 361.213 185.805 360.859 185.805 360.498C185.805 360.137 185.9 359.782 186.079 359.469C186.259 359.156 186.518 358.896 186.83 358.715L192.022 355.707C192.332 355.517 192.688 355.417 193.052 355.417C193.415 355.417 193.772 355.517 194.082 355.707Z'
							fill='#41C0FD'
						/>
						<path
							d='M216.18 356.308L221.544 359.445C221.856 359.626 222.114 359.886 222.294 360.199C222.474 360.512 222.569 360.867 222.569 361.228C222.569 361.589 222.474 361.944 222.294 362.257C222.114 362.569 221.856 362.83 221.544 363.011L216.352 366.062C216.039 366.243 215.684 366.338 215.322 366.338C214.96 366.338 214.605 366.243 214.292 366.062L208.885 362.925C208.574 362.744 208.315 362.484 208.135 362.171C207.955 361.858 207.861 361.503 207.861 361.142C207.861 360.781 207.955 360.426 208.135 360.113C208.315 359.801 208.574 359.54 208.885 359.359L214.078 356.351C214.396 356.168 214.756 356.068 215.123 356.061C215.491 356.053 215.854 356.138 216.18 356.308Z'
							fill='#41C0FD'
						/>
						<path
							d='M232.057 360.734L237.421 363.87C237.732 364.052 237.991 364.312 238.171 364.625C238.351 364.938 238.445 365.292 238.445 365.653C238.445 366.014 238.351 366.369 238.171 366.682C237.991 366.995 237.732 367.255 237.421 367.436L232.228 370.444C231.918 370.634 231.562 370.734 231.199 370.734C230.835 370.734 230.479 370.634 230.169 370.444L224.762 367.308C224.461 367.118 224.212 366.855 224.04 366.543C223.868 366.231 223.777 365.881 223.777 365.525C223.777 365.168 223.868 364.818 224.04 364.506C224.212 364.194 224.461 363.931 224.762 363.741L229.997 360.734C230.307 360.544 230.664 360.444 231.027 360.444C231.39 360.444 231.747 360.544 232.057 360.734Z'
							fill='#41C0FD'
						/>
						<path
							d='M180.007 363.827L185.371 367.007C185.683 367.188 185.941 367.448 186.121 367.761C186.301 368.074 186.396 368.429 186.396 368.79C186.396 369.151 186.301 369.506 186.121 369.818C185.941 370.131 185.683 370.391 185.371 370.573L180.179 373.58C179.854 373.75 179.494 373.838 179.127 373.838C178.761 373.838 178.401 373.75 178.076 373.58L172.712 370.444C172.401 370.263 172.142 370.002 171.962 369.69C171.782 369.377 171.688 369.022 171.688 368.661C171.688 368.3 171.782 367.945 171.962 367.632C172.142 367.319 172.401 367.059 172.712 366.878L177.905 363.827C178.229 363.658 178.59 363.569 178.956 363.569C179.322 363.569 179.683 363.658 180.007 363.827Z'
							fill='#41C0FD'
						/>
						<path
							d='M221.544 367.007L226.951 370.143C227.262 370.325 227.521 370.585 227.701 370.898C227.881 371.211 227.975 371.565 227.975 371.926C227.975 372.287 227.881 372.642 227.701 372.955C227.521 373.268 227.262 373.528 226.951 373.709L221.758 376.717C221.448 376.907 221.092 377.007 220.729 377.007C220.365 377.007 220.009 376.907 219.699 376.717L214.335 373.58C214.023 373.399 213.765 373.139 213.585 372.826C213.405 372.513 213.31 372.158 213.31 371.797C213.31 371.436 213.405 371.082 213.585 370.769C213.765 370.456 214.023 370.196 214.335 370.014L219.527 367.007C219.839 366.845 220.185 366.76 220.536 366.76C220.887 366.76 221.232 366.845 221.544 367.007Z'
							fill='#41C0FD'
						/>
						<path
							d='M300.369 348.145L306.248 351.539C306.525 351.697 306.755 351.925 306.914 352.201C307.074 352.476 307.159 352.789 307.159 353.108C307.159 353.426 307.074 353.739 306.914 354.015C306.755 354.29 306.525 354.518 306.248 354.676L264.239 378.951C263.968 379.118 263.656 379.207 263.338 379.207C263.02 379.207 262.708 379.118 262.437 378.951L256.558 375.514C256.281 375.362 256.049 375.139 255.888 374.867C255.726 374.594 255.641 374.284 255.641 373.967C255.641 373.651 255.726 373.34 255.888 373.068C256.049 372.795 256.281 372.572 256.558 372.42L298.524 348.145C298.803 347.979 299.122 347.891 299.447 347.891C299.772 347.891 300.09 347.979 300.369 348.145Z'
							fill='#41C0FD'
						/>
						<path
							d='M205.281 362.71L210.645 365.847C210.957 366.028 211.215 366.288 211.395 366.601C211.575 366.914 211.67 367.269 211.67 367.63C211.67 367.991 211.575 368.346 211.395 368.658C211.215 368.971 210.957 369.231 210.645 369.413L191.764 380.326C191.454 380.516 191.098 380.616 190.735 380.616C190.371 380.616 190.015 380.516 189.705 380.326L184.341 377.19C184.029 377.008 183.771 376.748 183.591 376.435C183.411 376.122 183.316 375.768 183.316 375.406C183.316 375.045 183.411 374.691 183.591 374.378C183.771 374.065 184.029 373.805 184.341 373.623L203.307 362.71C203.613 362.556 203.951 362.475 204.294 362.475C204.637 362.475 204.975 362.556 205.281 362.71Z'
							fill='#41C0FD'
						/>
						<path
							d='M211.031 372.893L216.395 376.029C216.706 376.211 216.965 376.471 217.145 376.784C217.325 377.097 217.419 377.451 217.419 377.812C217.419 378.173 217.325 378.528 217.145 378.841C216.965 379.154 216.706 379.414 216.395 379.596L203.522 387.114C203.205 387.284 202.851 387.372 202.492 387.372C202.133 387.372 201.779 387.284 201.462 387.114L196.098 383.978C195.787 383.797 195.528 383.536 195.348 383.223C195.168 382.911 195.074 382.556 195.074 382.195C195.074 381.834 195.168 381.479 195.348 381.166C195.528 380.853 195.787 380.593 196.098 380.412L208.971 372.893C209.281 372.703 209.638 372.603 210.001 372.603C210.365 372.603 210.721 372.703 211.031 372.893Z'
							fill='#41C0FD'
						/>
						<path
							d='M253.297 375.342L259.219 378.779C259.496 378.931 259.728 379.154 259.89 379.426C260.051 379.699 260.136 380.009 260.136 380.326C260.136 380.642 260.051 380.953 259.89 381.225C259.728 381.498 259.496 381.721 259.219 381.873L247.118 388.919C246.833 389.066 246.517 389.143 246.196 389.143C245.875 389.143 245.558 389.066 245.273 388.919L239.394 385.482C239.118 385.324 238.888 385.096 238.728 384.82C238.568 384.545 238.484 384.232 238.484 383.913C238.484 383.595 238.568 383.282 238.728 383.006C238.888 382.731 239.118 382.503 239.394 382.345L251.538 375.342C251.804 375.183 252.108 375.099 252.418 375.099C252.727 375.099 253.031 375.183 253.297 375.342Z'
							fill='#41C0FD'
						/>
						<path
							d='M235.747 385.611L241.154 388.747C241.465 388.928 241.724 389.189 241.904 389.502C242.084 389.814 242.178 390.169 242.178 390.53C242.178 390.891 242.084 391.246 241.904 391.559C241.724 391.872 241.465 392.132 241.154 392.313L226.736 400.648C226.426 400.838 226.07 400.939 225.706 400.939C225.343 400.939 224.986 400.838 224.676 400.648L219.27 397.512C218.958 397.331 218.699 397.07 218.519 396.757C218.34 396.445 218.245 396.09 218.245 395.729C218.245 395.368 218.34 395.013 218.519 394.7C218.699 394.387 218.958 394.127 219.27 393.946L233.687 385.611C233.997 385.421 234.354 385.32 234.717 385.32C235.081 385.32 235.437 385.421 235.747 385.611Z'
							fill='#41C0FD'
						/>
						<path
							d='M343.666 282.624L349.373 285.975C349.657 286.141 349.894 286.38 350.058 286.666C350.222 286.953 350.309 287.277 350.309 287.608C350.309 287.938 350.222 288.262 350.058 288.549C349.894 288.835 349.657 289.074 349.373 289.24L343.837 292.42C343.552 292.59 343.225 292.68 342.893 292.68C342.561 292.68 342.235 292.59 341.949 292.42L336.199 289.111C335.915 288.939 335.68 288.696 335.517 288.407C335.354 288.117 335.268 287.79 335.268 287.457C335.268 287.125 335.354 286.798 335.517 286.508C335.68 286.218 335.915 285.975 336.199 285.803L341.692 282.624C341.99 282.446 342.331 282.352 342.679 282.352C343.026 282.352 343.367 282.446 343.666 282.624Z'
							fill='#41C0FD'
						/>
						<path
							d='M322.425 294.912L328.132 298.22C328.427 298.383 328.673 298.623 328.844 298.914C329.016 299.205 329.106 299.536 329.106 299.874C329.106 300.212 329.016 300.543 328.844 300.834C328.673 301.125 328.427 301.365 328.132 301.528L322.597 304.708C322.303 304.877 321.97 304.967 321.631 304.967C321.292 304.967 320.959 304.877 320.666 304.708L314.959 301.399C314.675 301.227 314.44 300.984 314.276 300.695C314.113 300.405 314.027 300.078 314.027 299.745C314.027 299.413 314.113 299.085 314.276 298.796C314.44 298.506 314.675 298.263 314.959 298.091L320.537 294.912C320.823 294.741 321.149 294.651 321.481 294.651C321.813 294.651 322.14 294.741 322.425 294.912Z'
							fill='#41C0FD'
						/>
						<path
							d='M377.264 303.591L383.057 306.942C383.332 307.111 383.559 307.348 383.717 307.63C383.874 307.912 383.957 308.23 383.957 308.553C383.957 308.876 383.874 309.194 383.717 309.476C383.559 309.758 383.332 309.995 383.057 310.164L377.436 313.43C377.15 313.576 376.834 313.653 376.513 313.653C376.192 313.653 375.876 313.576 375.59 313.43L369.755 310.035C369.48 309.866 369.252 309.629 369.095 309.347C368.937 309.065 368.854 308.747 368.854 308.424C368.854 308.101 368.937 307.783 369.095 307.501C369.252 307.219 369.48 306.982 369.755 306.813L375.419 303.591C375.698 303.424 376.017 303.336 376.341 303.336C376.666 303.336 376.985 303.424 377.264 303.591Z'
							fill='#41C0FD'
						/>
						<path
							d='M366.708 309.691L372.415 313.043C372.7 313.209 372.936 313.448 373.101 313.734C373.265 314.021 373.351 314.345 373.351 314.675C373.351 315.006 373.265 315.33 373.101 315.617C372.936 315.903 372.7 316.142 372.415 316.308L366.88 319.53C366.598 319.71 366.27 319.806 365.936 319.806C365.601 319.806 365.274 319.71 364.992 319.53L359.242 316.222C358.958 316.05 358.723 315.807 358.559 315.517C358.396 315.228 358.311 314.901 358.311 314.568C358.311 314.235 358.396 313.908 358.559 313.619C358.723 313.329 358.958 313.086 359.242 312.914L364.82 309.734C365.102 309.558 365.426 309.46 365.758 309.453C366.091 309.445 366.419 309.528 366.708 309.691Z'
							fill='#41C0FD'
						/>
						<path
							d='M356.41 303.59L362.074 306.856C362.36 307.016 362.598 307.25 362.763 307.533C362.929 307.817 363.016 308.139 363.016 308.467C363.016 308.795 362.929 309.117 362.763 309.401C362.598 309.684 362.36 309.918 362.074 310.078L356.453 313.344C356.167 313.491 355.851 313.567 355.53 313.567C355.209 313.567 354.893 313.491 354.608 313.344L348.815 309.949C348.529 309.789 348.291 309.555 348.125 309.272C347.96 308.988 347.872 308.666 347.872 308.338C347.872 308.01 347.96 307.688 348.125 307.405C348.291 307.121 348.529 306.888 348.815 306.727L354.436 303.505C354.738 303.325 355.085 303.237 355.436 303.253C355.786 303.268 356.125 303.385 356.41 303.59Z'
							fill='#41C0FD'
						/>
						<path
							d='M268.187 312.57L274.581 316.308C274.822 316.442 275.023 316.638 275.163 316.876C275.304 317.114 275.377 317.385 275.377 317.662C275.377 317.938 275.304 318.209 275.163 318.447C275.023 318.685 274.822 318.881 274.581 319.015L268.359 322.581C268.129 322.731 267.86 322.811 267.586 322.811C267.312 322.811 267.044 322.731 266.814 322.581L260.42 318.886C260.19 318.744 259.999 318.545 259.867 318.308C259.735 318.071 259.665 317.804 259.665 317.533C259.665 317.261 259.735 316.995 259.867 316.758C259.999 316.521 260.19 316.322 260.42 316.179L266.642 312.57C266.882 312.449 267.146 312.386 267.415 312.386C267.683 312.386 267.947 312.449 268.187 312.57Z'
							fill='#41C0FD'
						/>
						<path
							d='M356.453 315.449L362.16 318.8C362.456 318.957 362.704 319.192 362.877 319.48C363.05 319.768 363.141 320.097 363.141 320.433C363.141 320.769 363.05 321.098 362.877 321.386C362.704 321.673 362.456 321.908 362.16 322.065L356.624 325.288C356.339 325.458 356.013 325.548 355.68 325.548C355.348 325.548 355.022 325.458 354.736 325.288L348.986 321.937C348.701 321.77 348.465 321.532 348.301 321.245C348.137 320.959 348.05 320.634 348.05 320.304C348.05 319.974 348.137 319.649 348.301 319.363C348.465 319.076 348.701 318.838 348.986 318.671L354.565 315.449C354.85 315.279 355.176 315.189 355.509 315.189C355.841 315.189 356.167 315.279 356.453 315.449Z'
							fill='#41C0FD'
						/>
						<path
							d='M304.532 317.683L313.972 323.183L304.66 328.596L295.177 323.097L304.532 317.683Z'
							fill='#41C0FD'
						/>
						<path
							d='M293.976 323.741L303.502 329.284L294.148 334.654L284.664 329.112L293.976 323.741Z'
							fill='#41C0FD'
						/>
						<path
							d='M341.22 324.514L347.055 327.909C347.342 328.063 347.582 328.292 347.749 328.572C347.917 328.852 348.005 329.172 348.005 329.498C348.005 329.824 347.917 330.145 347.749 330.424C347.582 330.704 347.342 330.934 347.055 331.088L331.479 340.111C331.194 340.258 330.877 340.334 330.557 340.334C330.236 340.334 329.919 340.258 329.634 340.111L323.841 336.716C323.554 336.562 323.314 336.333 323.147 336.053C322.98 335.773 322.892 335.453 322.892 335.127C322.892 334.801 322.98 334.481 323.147 334.201C323.314 333.921 323.554 333.691 323.841 333.537L339.375 324.514C339.654 324.348 339.972 324.26 340.297 324.26C340.622 324.26 340.941 324.348 341.22 324.514Z'
							fill='#41C0FD'
						/>
						<path
							d='M238.107 369.542L244.544 373.28C244.855 373.461 245.114 373.721 245.294 374.034C245.474 374.347 245.568 374.702 245.568 375.063C245.568 375.424 245.474 375.778 245.294 376.091C245.114 376.404 244.855 376.664 244.544 376.846L239.351 379.853C239.035 380.023 238.681 380.111 238.322 380.111C237.962 380.111 237.609 380.023 237.292 379.853L231.885 376.674C231.573 376.493 231.315 376.232 231.135 375.919C230.955 375.607 230.86 375.252 230.86 374.891C230.86 374.53 230.955 374.175 231.135 373.862C231.315 373.549 231.573 373.289 231.885 373.108L238.15 369.542H238.107Z'
							fill='#41C0FD'
						/>
						<path
							d='M249.392 364.042L255.271 367.479C255.548 367.637 255.778 367.865 255.937 368.141C256.097 368.416 256.182 368.729 256.182 369.048C256.182 369.366 256.097 369.679 255.937 369.955C255.778 370.23 255.548 370.458 255.271 370.616L249.564 373.924C249.286 374.071 248.977 374.148 248.663 374.148C248.349 374.148 248.04 374.071 247.762 373.924L241.84 370.487C241.564 370.329 241.334 370.101 241.174 369.826C241.014 369.55 240.93 369.237 240.93 368.919C240.93 368.6 241.014 368.287 241.174 368.012C241.334 367.736 241.564 367.508 241.84 367.351L247.547 364.042C247.833 363.895 248.149 363.819 248.47 363.819C248.791 363.819 249.107 363.895 249.392 364.042Z'
							fill='#41C0FD'
						/>
						<path
							d='M259.862 357.984L265.784 361.421C266.061 361.579 266.29 361.807 266.45 362.083C266.61 362.358 266.695 362.671 266.695 362.99C266.695 363.308 266.61 363.621 266.45 363.897C266.29 364.172 266.061 364.4 265.784 364.558L260.034 367.866C259.756 368.013 259.447 368.09 259.133 368.09C258.819 368.09 258.51 368.013 258.232 367.866L252.353 364.429C252.077 364.271 251.847 364.043 251.687 363.768C251.527 363.492 251.443 363.179 251.443 362.861C251.443 362.542 251.527 362.229 251.687 361.954C251.847 361.678 252.077 361.45 252.353 361.292L258.06 357.984C258.331 357.817 258.643 357.728 258.961 357.728C259.28 357.728 259.592 357.817 259.862 357.984Z'
							fill='#41C0FD'
						/>
						<path
							d='M270.247 351.969L276.168 355.406C276.434 355.572 276.654 355.803 276.806 356.078C276.958 356.352 277.038 356.661 277.038 356.974C277.038 357.288 276.958 357.597 276.806 357.871C276.654 358.146 276.434 358.377 276.168 358.543L270.418 361.851C270.147 362.018 269.835 362.107 269.517 362.107C269.199 362.107 268.887 362.018 268.616 361.851L262.694 358.414C262.418 358.256 262.188 358.028 262.028 357.753C261.868 357.477 261.784 357.164 261.784 356.846C261.784 356.527 261.868 356.214 262.028 355.939C262.188 355.663 262.418 355.435 262.694 355.277L268.444 351.969C268.722 351.822 269.031 351.746 269.346 351.746C269.66 351.746 269.969 351.822 270.247 351.969Z'
							fill='#41C0FD'
						/>
						<path
							d='M281.06 345.739L286.982 349.133C287.258 349.291 287.488 349.519 287.648 349.795C287.808 350.07 287.892 350.383 287.892 350.702C287.892 351.02 287.808 351.333 287.648 351.609C287.488 351.884 287.258 352.112 286.982 352.27L281.275 355.578C281.004 355.745 280.692 355.834 280.373 355.834C280.055 355.834 279.743 355.745 279.472 355.578L273.551 352.141C273.274 351.983 273.044 351.755 272.884 351.48C272.724 351.204 272.64 350.891 272.64 350.573C272.64 350.254 272.724 349.941 272.884 349.666C273.044 349.39 273.274 349.162 273.551 349.005L279.258 345.739C279.529 345.572 279.841 345.483 280.159 345.483C280.477 345.483 280.789 345.572 281.06 345.739Z'
							fill='#41C0FD'
						/>
						<path
							d='M291.444 339.724L297.366 343.161C297.642 343.319 297.872 343.547 298.032 343.822C298.192 344.098 298.276 344.411 298.276 344.729C298.276 345.048 298.192 345.361 298.032 345.636C297.872 345.912 297.642 346.14 297.366 346.298L291.659 349.563C291.38 349.73 291.061 349.817 290.736 349.817C290.411 349.817 290.093 349.73 289.814 349.563L283.935 346.169C283.658 346.011 283.428 345.783 283.268 345.508C283.109 345.232 283.024 344.919 283.024 344.601C283.024 344.282 283.109 343.969 283.268 343.694C283.428 343.418 283.658 343.19 283.935 343.032L289.642 339.724C289.913 339.557 290.225 339.468 290.543 339.468C290.861 339.468 291.173 339.557 291.444 339.724Z'
							fill='#41C0FD'
						/>
						<path
							d='M302 333.666L307.879 337.06C308.154 337.224 308.383 337.456 308.542 337.735C308.7 338.014 308.784 338.329 308.784 338.65C308.784 338.971 308.7 339.286 308.542 339.565C308.383 339.844 308.154 340.076 307.879 340.24L302.215 343.505C301.936 343.671 301.617 343.759 301.292 343.759C300.967 343.759 300.648 343.671 300.369 343.505L294.491 340.111C294.215 339.947 293.987 339.715 293.828 339.436C293.669 339.157 293.586 338.842 293.586 338.521C293.586 338.2 293.669 337.885 293.828 337.606C293.987 337.327 294.215 337.095 294.491 336.931L300.155 333.666C300.431 333.49 300.751 333.397 301.077 333.397C301.404 333.397 301.724 333.49 302 333.666Z'
							fill='#41C0FD'
						/>
						<path
							d='M312.341 327.651L318.177 331.045C318.464 331.199 318.704 331.429 318.871 331.709C319.038 331.989 319.126 332.309 319.126 332.635C319.126 332.961 319.038 333.281 318.871 333.561C318.704 333.841 318.464 334.07 318.177 334.225L312.513 337.533C312.231 337.691 311.913 337.774 311.59 337.774C311.267 337.774 310.95 337.691 310.668 337.533L304.832 334.139C304.556 333.975 304.328 333.743 304.169 333.464C304.011 333.185 303.927 332.87 303.927 332.549C303.927 332.228 304.011 331.913 304.169 331.634C304.328 331.355 304.556 331.123 304.832 330.959L310.539 327.694C310.806 327.52 311.116 327.424 311.434 327.416C311.752 327.409 312.067 327.49 312.341 327.651Z'
							fill='#41C0FD'
						/>
						<path
							d='M226.436 376.244C226.754 376.055 227.117 375.955 227.487 375.955C227.857 375.955 228.22 376.055 228.538 376.244L233.902 379.381C234.214 379.562 234.472 379.822 234.652 380.135C234.832 380.448 234.927 380.803 234.927 381.164C234.927 381.525 234.832 381.879 234.652 382.192C234.472 382.505 234.214 382.765 233.902 382.947L215.022 393.86C214.712 394.05 214.355 394.15 213.992 394.15C213.628 394.15 213.272 394.05 212.962 393.86L205.882 389.563C205.749 389.478 205.64 389.36 205.564 389.221C205.488 389.082 205.449 388.927 205.449 388.769C205.449 388.61 205.488 388.455 205.564 388.316C205.64 388.177 205.749 388.059 205.882 387.974L226.436 376.244Z'
							fill='#41C0FD'
						/>
						<path
							d='M174.858 319.144L165.375 311.152C162.695 308.935 160.536 306.152 159.052 303.003C157.569 299.854 156.797 296.417 156.793 292.935V229.519H159.797V293.064C159.793 296.103 160.456 299.106 161.738 301.861C163.021 304.615 164.892 307.054 167.22 309.004L177.862 317.941L174.858 319.144Z'
							fill='#C5F9FF'
						/>
						<path
							d='M190.263 329.198H190.22V331.303L174.901 319.187L174.815 319.101L176.36 318.199L176.66 318.456L190.263 329.198Z'
							fill='#F3E7E0'
						/>
						<path
							d='M190.263 329.198H190.22V331.303L174.901 319.187L174.815 319.101V316.91L175.845 317.769L176.36 318.199L176.66 318.456L190.263 329.198Z'
							fill='#C5F9FF'
						/>
						<path
							d='M266.513 285.373L190.434 329.155L176.875 318.413L176.574 318.156L176.059 317.726L175.158 316.953L166.576 309.777L166.276 309.52C163.978 307.609 162.124 305.221 160.842 302.52C159.56 299.819 158.881 296.871 158.852 293.88V232.312L160.226 231.538L207.641 205.287L224.548 189.862H227.594V253.407C227.572 253.779 227.572 254.152 227.594 254.524C227.73 257.367 228.456 260.149 229.727 262.694C230.998 265.239 232.785 267.49 234.975 269.304C235.299 269.574 235.643 269.818 236.004 270.035C239.995 272.871 252.911 279.1 266.513 285.373Z'
							fill='#41C0FD'
						/>
						<path
							d='M227.294 254.524L158.638 293.88V236.866C163.487 232.097 167.22 228.917 167.563 229.433C168.679 230.765 212.533 218.563 227.38 216.543V253.407C227.329 253.778 227.3 254.151 227.294 254.524Z'
							fill='#41C0FD'
						/>
						<path
							d='M190.263 329.198L266.513 285.459V287.607L190.22 331.303L190.263 329.198Z'
							fill='#C5F9FF'
						/>
						<path
							d='M235.704 270.035L166.233 309.777L165.933 309.52C163.635 307.609 161.78 305.221 160.498 302.52C159.216 299.819 158.537 296.871 158.509 293.88L227.165 254.525C227.301 257.367 228.027 260.149 229.298 262.694C230.569 265.239 232.356 267.491 234.546 269.304C234.911 269.58 235.298 269.824 235.704 270.035Z'
							fill='#41C0FD'
						/>
						<path
							d='M311.569 4.98397L309.895 5.28472L306.291 6.10105C306.291 6.48773 306.291 6.87442 306.506 7.2611C306.575 8.30541 306.575 9.35323 306.506 10.3975L306.162 46.617L305.304 170.098C305.256 174.994 304.063 179.81 301.82 184.16C299.577 188.51 296.346 192.273 292.388 195.147L70.5868 322.753H70.2006L71.917 328.94L64.3649 324.643C62.9389 323.619 61.7836 322.262 60.9986 320.69C60.2136 319.118 59.8226 317.378 59.8594 315.621C59.8594 315.105 59.8594 314.504 59.8594 313.945L61.0608 154.502V154.201C61.3254 147.231 63.7555 140.517 68.0122 134.996C69.7504 132.751 71.8713 130.831 74.277 129.325L296.078 1.54675C297.284 0.732418 298.676 0.238177 300.125 0.110415C301.574 -0.0173468 303.031 0.22557 304.36 0.816358L310.625 4.51135L311.569 4.98397Z'
							fill='#41C0FD'
						/>
						<path
							d='M310.582 4.42542L309.853 5.28472L306.591 7.13221L75.6502 138.562L68.0122 134.996C69.7504 132.751 71.8713 130.831 74.277 129.325L296.078 1.54675C297.284 0.732418 298.676 0.238177 300.125 0.110415C301.574 -0.0173468 303.031 0.22557 304.36 0.816358L310.582 4.42542Z'
							fill='#41C0FD'
						/>
						<path
							d='M85.3478 143.331L77.0662 163.654L61.0179 154.545V154.244C61.286 147.255 63.7317 140.524 68.0122 134.996L85.3478 143.331Z'
							fill='#41C0FD'
						/>
						<path
							d='M316.16 15.7252V31.6222V51.9017L314.873 175.426C314.774 180.341 313.518 185.164 311.207 189.502C308.895 193.839 305.594 197.57 301.571 200.389L220.471 246.705L133.192 296.2L131.176 297.446L79.9412 328.338C78.8537 329.186 77.5604 329.729 76.1941 329.911C74.8278 330.093 73.4378 329.908 72.1666 329.374C70.8955 328.841 69.7891 327.978 68.961 326.875C68.1328 325.771 67.6129 324.467 67.4544 323.097C67.1756 322.102 67.0171 321.078 66.9824 320.046C66.9824 319.487 66.9824 318.929 66.9824 318.37V300.196L67.3256 253.364L68.0122 158.627C68.1313 153.715 69.3962 148.9 71.7059 144.565C74.0157 140.231 77.3062 136.498 81.3143 133.664L172.841 81.7194L204.122 63.1155L243.771 39.5278L289.599 13.491L302.858 5.97213C303.719 5.296 304.715 4.81288 305.778 4.55548C306.841 4.29807 307.947 4.27237 309.022 4.48013C310.096 4.6879 311.113 5.12427 312.004 5.75972C312.895 6.39517 313.639 7.21487 314.186 8.16334C315.463 10.4802 316.141 13.0794 316.16 15.7252Z'
							fill='#C5F9FF'
						/>
						<path
							d='M307.364 18.389V181.097L164.13 264.879L75.4786 316.738V154.072C204.637 78.4541 267.758 41.5472 305.69 19.3772L307.364 18.389Z'
							fill='url(#paint0_linear_51_182)'
						/>
						<path
							d='M304.06 20.3225V179.293L75.4785 313.043V316.781L307.364 181.097V18.389L304.06 20.3225Z'
							fill='#41C0FD'
						/>
						<path
							d='M75.4785 316.781L307.364 181.097L304.06 179.293L75.4785 313.043V316.781Z'
							fill='#41C0FD'
						/>
						<g opacity='0.6'>
							<path
								opacity='0.17'
								d='M335.47 221.957C335.47 223.998 336.075 225.994 337.208 227.69C338.342 229.387 339.953 230.709 341.837 231.488C343.721 232.267 345.794 232.469 347.793 232.067C349.792 231.666 351.627 230.68 353.065 229.233C354.504 227.787 355.481 225.945 355.874 223.942C356.266 221.939 356.056 219.865 355.27 217.981C354.484 216.098 353.157 214.49 351.458 213.362C349.759 212.235 347.764 211.637 345.725 211.645C343.001 211.657 340.393 212.748 338.471 214.681C336.549 216.613 335.47 219.23 335.47 221.957Z'
								fill='white'
							/>
							<path
								opacity='0.33'
								d='M336.886 222.043C336.877 223.814 337.395 225.548 338.372 227.024C339.349 228.5 340.743 229.652 342.376 230.334C344.008 231.016 345.806 231.196 347.542 230.853C349.277 230.509 350.871 229.657 352.122 228.405C353.373 227.153 354.224 225.556 354.567 223.819C354.91 222.081 354.729 220.281 354.049 218.646C353.368 217.011 352.217 215.616 350.743 214.637C349.268 213.659 347.537 213.141 345.768 213.149C343.416 213.161 341.163 214.101 339.5 215.767C337.836 217.432 336.897 219.688 336.886 222.043Z'
								fill='white'
							/>
							<path
								opacity='0.5'
								d='M338.302 222.172C338.302 223.659 338.742 225.113 339.567 226.349C340.392 227.586 341.565 228.549 342.937 229.118C344.309 229.688 345.819 229.836 347.276 229.546C348.733 229.256 350.071 228.54 351.121 227.489C352.171 226.437 352.886 225.097 353.176 223.639C353.466 222.18 353.317 220.668 352.749 219.295C352.18 217.921 351.218 216.746 349.983 215.92C348.748 215.094 347.296 214.653 345.811 214.653C343.819 214.653 341.909 215.445 340.501 216.855C339.093 218.265 338.302 220.178 338.302 222.172Z'
								fill='white'
							/>
							<path
								opacity='0.67'
								d='M339.718 222.301C339.718 223.516 340.078 224.704 340.752 225.714C341.426 226.725 342.384 227.512 343.506 227.977C344.627 228.442 345.861 228.564 347.051 228.327C348.241 228.09 349.335 227.505 350.193 226.645C351.051 225.786 351.635 224.691 351.872 223.499C352.109 222.308 351.987 221.072 351.523 219.95C351.059 218.827 350.272 217.867 349.263 217.192C348.254 216.517 347.068 216.157 345.854 216.157C345.048 216.157 344.25 216.316 343.506 216.625C342.761 216.933 342.085 217.386 341.515 217.956C340.945 218.527 340.493 219.204 340.185 219.95C339.877 220.695 339.718 221.494 339.718 222.301Z'
								fill='white'
							/>
							<path
								opacity='0.83'
								d='M341.134 222.43C341.246 223.611 341.794 224.708 342.671 225.506C343.548 226.304 344.69 226.747 345.875 226.747C347.06 226.747 348.203 226.304 349.08 225.506C349.956 224.708 350.505 223.611 350.617 222.43C350.68 221.768 350.604 221.101 350.394 220.471C350.185 219.84 349.846 219.261 349.399 218.769C348.953 218.278 348.409 217.885 347.802 217.616C347.195 217.347 346.539 217.208 345.875 217.208C345.212 217.208 344.556 217.347 343.949 217.616C343.342 217.885 342.798 218.278 342.351 218.769C341.905 219.261 341.566 219.84 341.356 220.471C341.147 221.101 341.071 221.768 341.134 222.43Z'
								fill='white'
							/>
							<path
								d='M342.55 222.516C342.55 223.404 342.902 224.257 343.53 224.885C344.158 225.514 345.009 225.867 345.897 225.867C346.784 225.867 347.636 225.514 348.263 224.885C348.891 224.257 349.244 223.404 349.244 222.516C349.244 221.627 348.891 220.774 348.263 220.146C347.636 219.517 346.784 219.164 345.897 219.164C345.009 219.164 344.158 219.517 343.53 220.146C342.902 220.774 342.55 221.627 342.55 222.516Z'
								fill='white'
							/>
						</g>
						<path
							d='M23.0856 321.249C23.8127 321.507 24.4421 321.985 24.8872 322.615C25.3324 323.246 25.5714 324 25.5714 324.772C25.5714 325.545 25.3324 326.298 24.8872 326.929C24.4421 327.56 23.8127 328.037 23.0856 328.295C21.2037 329.25 19.1236 329.748 17.0138 329.748C14.9041 329.748 12.8239 329.25 10.942 328.295C7.98126 326.577 7.59507 323.999 9.86929 321.98C10.1871 321.702 10.5322 321.457 10.8992 321.249L12.1435 320.648C13.9099 319.985 15.7942 319.697 17.6775 319.8C19.5608 319.904 21.4023 320.397 23.0856 321.249Z'
							fill='#2AFBFE'
						/>
						<g>
							<path
								opacity='0.17'
								d='M2.05969 324.257C2.05121 327.226 2.92336 330.131 4.56563 332.603C6.2079 335.076 8.54628 337.004 11.2844 338.145C14.0226 339.285 17.0373 339.586 19.9462 339.008C22.8552 338.431 25.5275 337.002 27.6245 334.902C29.7214 332.803 31.1486 330.127 31.7251 327.214C32.3015 324.302 32.0013 321.283 30.8626 318.542C29.7238 315.8 27.7977 313.458 25.3284 311.814C22.8591 310.17 19.9578 309.296 16.9923 309.305C13.0319 309.305 9.23373 310.88 6.43331 313.684C3.6329 316.488 2.05969 320.291 2.05969 324.257Z'
								fill='#DCFEFF'
							/>
							<path
								opacity='0.33'
								d='M4.11935 324.429C4.11935 326.978 4.87431 329.47 6.28881 331.59C7.70331 333.709 9.7138 335.361 12.066 336.337C14.4183 337.312 17.0066 337.568 19.5037 337.07C22.0008 336.573 24.2946 335.345 26.0949 333.543C27.8952 331.74 29.1212 329.443 29.6179 326.943C30.1146 324.443 29.8597 321.851 28.8854 319.496C27.9111 317.141 26.2611 315.128 24.1441 313.711C22.0272 312.295 19.5384 311.539 16.9923 311.539C13.5782 311.539 10.3039 312.897 7.88975 315.314C5.4756 317.732 4.11935 321.01 4.11935 324.429Z'
								fill='#B8FEFF'
							/>
							<path
								opacity='0.5'
								d='M6.17902 324.6C6.17902 326.767 6.82074 328.886 8.02306 330.687C9.22539 332.489 10.9343 333.893 12.9337 334.722C14.9331 335.552 17.1332 335.769 19.2558 335.346C21.3783 334.923 23.3279 333.88 24.8582 332.347C26.3885 330.815 27.4307 328.863 27.8529 326.738C28.2751 324.612 28.0584 322.41 27.2302 320.408C26.402 318.406 24.9995 316.695 23.2001 315.491C21.4007 314.287 19.2852 313.644 17.1211 313.644C14.2191 313.644 11.4359 314.799 9.3839 316.853C7.33187 318.908 6.17902 321.695 6.17902 324.6Z'
								fill='#95FDFF'
							/>
							<path
								opacity='0.67'
								d='M8.23868 324.772C8.23868 326.54 8.76219 328.268 9.74291 329.737C10.7236 331.207 12.1175 332.352 13.7484 333.029C15.3793 333.705 17.1739 333.882 18.9052 333.537C20.6365 333.192 22.2268 332.341 23.4751 331.092C24.7233 329.842 25.5734 328.249 25.9177 326.516C26.2621 324.782 26.0854 322.985 25.4098 321.352C24.7343 319.719 23.5903 318.324 22.1226 317.342C20.6548 316.36 18.9292 315.836 17.1639 315.836C14.7968 315.836 12.5267 316.777 10.8528 318.453C9.17903 320.129 8.23868 322.402 8.23868 324.772Z'
								fill='#71FCFE'
							/>
							<path
								opacity='0.83'
								d='M10.2126 324.944C10.2126 326.314 10.6189 327.653 11.3799 328.792C12.141 329.93 13.2225 330.817 14.4873 331.339C15.7522 331.861 17.1434 331.996 18.4847 331.725C19.826 331.455 21.0569 330.792 22.0213 329.82C22.9857 328.848 23.6403 327.612 23.902 326.267C24.1637 324.922 24.0208 323.53 23.4913 322.267C22.9618 321.004 22.0697 319.927 20.9279 319.172C19.7862 318.417 18.4463 318.018 17.0782 318.027C16.173 318.027 15.2766 318.206 14.4408 318.554C13.6051 318.902 12.8463 319.412 12.2082 320.055C11.5701 320.698 11.0653 321.461 10.7228 322.3C10.3803 323.139 10.2069 324.038 10.2126 324.944Z'
								fill='#4EFCFE'
							/>
							<path
								d='M12.4009 325.116C12.401 326.087 12.689 327.035 13.2284 327.842C13.7678 328.648 14.5344 329.276 15.4307 329.645C16.3271 330.015 17.3129 330.109 18.2629 329.916C19.213 329.724 20.0844 329.253 20.7668 328.564C21.4492 327.875 21.9117 326.998 22.0956 326.045C22.2795 325.092 22.1765 324.106 21.7996 323.212C21.4228 322.317 20.7891 321.555 19.979 321.022C19.169 320.489 18.219 320.209 17.2498 320.218C16.6094 320.218 15.9753 320.345 15.3842 320.592C14.7931 320.838 14.2567 321.2 13.8059 321.655C13.3551 322.111 12.9988 322.651 12.7576 323.245C12.5165 323.839 12.3952 324.475 12.4009 325.116Z'
								fill='#2AFBFE'
							/>
						</g>
						<path
							d='M86.0772 376.373C86.8044 376.631 87.4338 377.109 87.8789 377.739C88.3241 378.37 88.5631 379.124 88.5631 379.896C88.5631 380.669 88.3241 381.422 87.8789 382.053C87.4338 382.684 86.8044 383.161 86.0772 383.419C84.1944 384.371 82.1147 384.867 80.0055 384.867C77.8963 384.867 75.8167 384.371 73.9338 383.419C70.9301 381.701 70.5868 379.123 72.861 377.061C73.1651 376.798 73.4959 376.567 73.848 376.373C74.2557 376.13 74.687 375.928 75.1353 375.772C76.8983 375.093 78.7847 374.796 80.6706 374.9C82.5564 375.004 84.3989 375.506 86.0772 376.373Z'
							fill='#2AFBFE'
						/>
						<g>
							<path
								opacity='0.17'
								d='M65.0943 379.381C65.0943 382.346 65.9727 385.245 67.6182 387.711C69.2637 390.177 71.6026 392.099 74.339 393.234C77.0754 394.369 80.0865 394.666 82.9915 394.087C85.8965 393.509 88.5649 392.081 90.6592 389.984C92.7536 387.886 94.1799 385.215 94.7577 382.306C95.3356 379.397 95.039 376.382 93.9055 373.642C92.772 370.902 90.8526 368.561 88.3899 366.913C85.9271 365.265 83.0318 364.386 80.0699 364.386C76.0981 364.386 72.2891 365.966 69.4806 368.778C66.6721 371.59 65.0943 375.404 65.0943 379.381Z'
								fill='#DCFEFF'
							/>
							<path
								opacity='0.33'
								d='M67.1541 379.553C67.1541 382.102 67.909 384.594 69.3235 386.714C70.738 388.833 72.7485 390.485 75.1007 391.461C77.453 392.436 80.0413 392.692 82.5384 392.194C85.0355 391.697 87.3293 390.469 89.1296 388.667C90.9299 386.864 92.1559 384.568 92.6526 382.067C93.1493 379.567 92.8944 376.975 91.9201 374.62C90.9458 372.265 89.2958 370.252 87.1788 368.835C85.0619 367.419 82.5731 366.663 80.027 366.663C76.6129 366.663 73.3386 368.021 70.9245 370.438C68.5103 372.856 67.1541 376.134 67.1541 379.553Z'
								fill='#B8FEFF'
							/>
							<path
								opacity='0.5'
								d='M69.2566 379.896C69.2566 382.065 69.8995 384.185 71.1037 385.988C72.308 387.79 74.0194 389.194 76.0213 390.022C78.0232 390.85 80.2255 391.065 82.3492 390.639C84.4729 390.212 86.4225 389.165 87.9511 387.628C89.4796 386.091 90.5183 384.135 90.9356 382.007C91.353 379.879 91.1302 377.675 90.2954 375.674C89.4607 373.672 88.0515 371.964 86.2465 370.766C84.4414 369.567 82.3217 368.932 80.1558 368.94C78.7209 368.94 77.3001 369.224 75.9749 369.775C74.6498 370.326 73.4463 371.134 72.4337 372.152C71.421 373.17 70.6191 374.378 70.0739 375.707C69.5287 377.036 69.251 378.459 69.2566 379.896Z'
								fill='#95FDFF'
							/>
							<path
								opacity='0.67'
								d='M71.3163 379.896C71.3163 381.666 71.8408 383.395 72.8234 384.866C73.8061 386.336 75.2026 387.482 76.836 388.157C78.4693 388.832 80.2661 389.006 81.9986 388.658C83.7311 388.31 85.3213 387.454 86.5678 386.2C87.8143 384.946 88.661 383.35 89.0005 381.613C89.34 379.877 89.1571 378.079 88.475 376.446C87.7929 374.814 86.6422 373.422 85.1688 372.445C83.6954 371.468 81.9656 370.951 80.1986 370.96C79.0285 370.96 77.87 371.191 76.7895 371.641C75.7091 372.09 74.728 372.749 73.9026 373.58C73.0772 374.41 72.4238 375.396 71.98 376.48C71.5361 377.564 71.3106 378.725 71.3163 379.896Z'
								fill='#71FCFE'
							/>
							<path
								opacity='0.83'
								d='M73.376 379.896C73.376 381.264 73.7811 382.602 74.5403 383.739C75.2994 384.877 76.3784 385.764 77.6407 386.287C78.9031 386.811 80.2921 386.948 81.6322 386.681C82.9723 386.414 84.2034 385.755 85.1695 384.788C86.1357 383.82 86.7936 382.588 87.0602 381.246C87.3268 379.904 87.19 378.513 86.6671 377.249C86.1442 375.985 85.2587 374.905 84.1226 374.145C82.9865 373.385 81.6508 372.979 80.2845 372.979C78.4522 372.979 76.695 373.708 75.3994 375.005C74.1038 376.302 73.376 378.062 73.376 379.896Z'
								fill='#4EFCFE'
							/>
							<path
								d='M75.4356 380.24C75.4356 381.209 75.7225 382.156 76.26 382.961C76.7975 383.767 77.5616 384.394 78.4554 384.765C79.3493 385.136 80.3328 385.233 81.2817 385.044C82.2306 384.855 83.1022 384.388 83.7864 383.703C84.4705 383.018 84.9364 382.146 85.1251 381.195C85.3139 380.245 85.217 379.261 84.8467 378.366C84.4765 377.471 83.8495 376.706 83.0451 376.167C82.2406 375.629 81.2949 375.342 80.3274 375.342C79.685 375.342 79.0489 375.469 78.4554 375.715C77.8619 375.961 77.3227 376.322 76.8684 376.776C76.4142 377.231 76.0538 377.771 75.808 378.366C75.5622 378.96 75.4356 379.597 75.4356 380.24Z'
								fill='#2AFBFE'
							/>
						</g>
						<path
							d='M140.187 404.902C140.929 405.151 141.574 405.627 142.032 406.263C142.489 406.899 142.735 407.663 142.735 408.447C142.735 409.23 142.489 409.994 142.032 410.63C141.574 411.266 140.929 411.742 140.187 411.991C138.308 412.956 136.227 413.46 134.115 413.46C132.003 413.46 129.922 412.956 128.043 411.991C125.082 410.273 124.696 407.695 126.97 405.675C127.299 405.412 127.643 405.168 128 404.945L129.245 404.343C131.01 403.685 132.892 403.394 134.773 403.49C136.655 403.586 138.497 404.067 140.187 404.902Z'
							fill='#CB83FF'
						/>
						<path
							d='M300.584 412.206C301.326 412.455 301.972 412.931 302.429 413.567C302.886 414.203 303.132 414.967 303.132 415.751C303.132 416.534 302.886 417.298 302.429 417.934C301.972 418.57 301.326 419.046 300.584 419.295C298.697 420.257 296.609 420.759 294.491 420.759C292.373 420.759 290.285 420.257 288.398 419.295C285.437 417.577 285.093 414.999 287.368 412.979C287.673 412.705 288.004 412.461 288.355 412.249L289.599 411.647C291.371 410.982 293.26 410.689 295.149 410.785C297.039 410.881 298.889 411.365 300.584 412.206Z'
							fill='#2AFBFE'
						/>
						<g>
							<path
								opacity='0.17'
								d='M279.601 415.256C279.601 418.222 280.479 421.121 282.125 423.587C283.77 426.053 286.109 427.975 288.846 429.11C291.582 430.245 294.593 430.542 297.498 429.963C300.403 429.385 303.072 427.956 305.166 425.859C307.26 423.762 308.687 421.09 309.264 418.182C309.842 415.273 309.546 412.258 308.412 409.518C307.279 406.778 305.359 404.436 302.897 402.789C300.434 401.141 297.538 400.262 294.577 400.262C292.608 400.256 290.658 400.64 288.839 401.392C287.02 402.143 285.366 403.247 283.975 404.641C282.583 406.034 281.48 407.69 280.729 409.511C279.979 411.333 279.595 413.286 279.601 415.256Z'
								fill='#DCFEFF'
							/>
							<path
								opacity='0.33'
								d='M281.661 415.428C281.661 417.978 282.416 420.47 283.83 422.589C285.245 424.709 287.255 426.361 289.607 427.337C291.96 428.312 294.548 428.567 297.045 428.07C299.542 427.573 301.836 426.345 303.636 424.543C305.437 422.74 306.663 420.443 307.159 417.943C307.656 415.443 307.401 412.851 306.427 410.496C305.452 408.14 303.802 406.127 301.686 404.711C299.569 403.295 297.08 402.539 294.534 402.539C291.12 402.539 287.845 403.897 285.431 406.314C283.017 408.731 281.661 412.01 281.661 415.428Z'
								fill='#B8FEFF'
							/>
							<path
								opacity='0.5'
								d='M283.763 415.557C283.755 417.728 284.39 419.852 285.589 421.66C286.789 423.469 288.497 424.879 290.498 425.714C292.499 426.549 294.703 426.769 296.829 426.348C298.956 425.926 300.91 424.882 302.442 423.347C303.975 421.812 305.018 419.856 305.439 417.727C305.86 415.598 305.64 413.391 304.806 411.388C303.973 409.384 302.564 407.673 300.758 406.473C298.952 405.272 296.83 404.636 294.662 404.644C291.775 404.655 289.01 405.809 286.968 407.853C284.927 409.897 283.775 412.666 283.763 415.557Z'
								fill='#95FDFF'
							/>
							<path
								opacity='0.67'
								d='M285.823 415.729C285.814 417.5 286.332 419.234 287.309 420.71C288.287 422.186 289.68 423.338 291.313 424.02C292.945 424.702 294.743 424.882 296.479 424.539C298.214 424.195 299.808 423.344 301.059 422.091C302.31 420.839 303.161 419.242 303.504 417.505C303.847 415.767 303.667 413.967 302.986 412.332C302.305 410.697 301.154 409.302 299.68 408.324C298.206 407.345 296.474 406.827 294.705 406.835C292.353 406.847 290.1 407.787 288.437 409.453C286.774 411.118 285.834 413.374 285.823 415.729Z'
								fill='#71FCFE'
							/>
							<path
								opacity='0.83'
								d='M287.883 415.901C287.883 417.271 288.289 418.61 289.05 419.748C289.811 420.887 290.892 421.774 292.157 422.296C293.422 422.818 294.813 422.953 296.155 422.682C297.496 422.412 298.727 421.749 299.691 420.777C300.656 419.805 301.31 418.569 301.572 417.224C301.834 415.879 301.691 414.487 301.161 413.224C300.632 411.961 299.74 410.883 298.598 410.128C297.456 409.374 296.116 408.975 294.748 408.984C292.923 408.995 291.177 409.729 289.891 411.025C288.605 412.321 287.883 414.074 287.883 415.901Z'
								fill='#4EFCFE'
							/>
							<path
								d='M289.942 416.073C289.942 417.042 290.229 417.989 290.767 418.794C291.304 419.599 292.068 420.227 292.962 420.598C293.856 420.969 294.839 421.066 295.788 420.877C296.737 420.688 297.609 420.221 298.293 419.536C298.977 418.851 299.443 417.979 299.632 417.028C299.821 416.078 299.724 415.093 299.353 414.198C298.983 413.303 298.356 412.538 297.552 412C296.747 411.462 295.802 411.175 294.834 411.175C293.537 411.175 292.292 411.691 291.375 412.609C290.458 413.528 289.942 414.774 289.942 416.073Z'
								fill='#2AFBFE'
							/>
						</g>
						<path
							d='M409.79 381.486C413.137 383.462 413.18 386.599 409.79 388.532C407.908 389.487 405.828 389.985 403.718 389.985C401.608 389.985 399.528 389.487 397.646 388.532C394.685 386.814 394.299 384.236 396.573 382.216C396.891 381.939 397.236 381.694 397.603 381.486L398.848 380.884C400.614 380.222 402.498 379.933 404.382 380.037C406.265 380.141 408.106 380.634 409.79 381.486Z'
							fill='#2AFBFE'
						/>
						<g>
							<path
								opacity='0.17'
								d='M388.807 384.494C388.798 387.463 389.67 390.368 391.313 392.84C392.955 395.313 395.293 397.241 398.032 398.382C400.77 399.522 403.784 399.822 406.693 399.245C409.602 398.668 412.275 397.239 414.372 395.139C416.469 393.04 417.896 390.364 418.472 387.451C419.049 384.539 418.748 381.52 417.61 378.778C416.471 376.037 414.545 373.695 412.075 372.051C409.606 370.406 406.705 369.533 403.739 369.542C399.779 369.542 395.981 371.117 393.18 373.921C390.38 376.725 388.807 380.528 388.807 384.494Z'
								fill='#DCFEFF'
							/>
							<path
								opacity='0.33'
								d='M390.866 384.665C390.866 387.215 391.621 389.707 393.036 391.826C394.45 393.946 396.461 395.598 398.813 396.574C401.165 397.549 403.754 397.805 406.251 397.307C408.748 396.81 411.042 395.582 412.842 393.78C414.642 391.977 415.868 389.68 416.365 387.18C416.862 384.68 416.607 382.088 415.633 379.733C414.658 377.378 413.008 375.364 410.891 373.948C408.774 372.532 406.285 371.776 403.739 371.776C400.325 371.776 397.051 373.134 394.637 375.551C392.223 377.968 390.866 381.247 390.866 384.665Z'
								fill='#B8FEFF'
							/>
							<path
								opacity='0.5'
								d='M392.926 384.837C392.926 387.004 393.568 389.122 394.77 390.924C395.972 392.726 397.681 394.13 399.681 394.959C401.68 395.788 403.88 396.005 406.003 395.583C408.125 395.16 410.075 394.117 411.605 392.584C413.136 391.052 414.178 389.1 414.6 386.975C415.022 384.849 414.805 382.646 413.977 380.644C413.149 378.643 411.747 376.931 409.947 375.728C408.148 374.524 406.032 373.881 403.868 373.881C400.966 373.881 398.183 375.035 396.131 377.09C394.079 379.145 392.926 381.931 392.926 384.837Z'
								fill='#95FDFF'
							/>
							<path
								opacity='0.67'
								d='M394.986 385.009C394.986 386.777 395.509 388.504 396.49 389.974C397.471 391.444 398.865 392.589 400.496 393.266C402.126 393.942 403.921 394.119 405.652 393.774C407.384 393.429 408.974 392.578 410.222 391.328C411.47 390.078 412.32 388.486 412.665 386.753C413.009 385.019 412.832 383.222 412.157 381.589C411.481 379.956 410.337 378.56 408.87 377.578C407.402 376.597 405.676 376.072 403.911 376.072C402.739 376.072 401.578 376.304 400.496 376.753C399.413 377.202 398.429 377.86 397.6 378.69C396.771 379.52 396.114 380.505 395.665 381.589C395.217 382.673 394.986 383.836 394.986 385.009Z'
								fill='#71FCFE'
							/>
							<path
								opacity='0.83'
								d='M397.088 385.181C397.088 386.551 397.495 387.89 398.256 389.028C399.017 390.167 400.098 391.053 401.363 391.576C402.628 392.098 404.019 392.232 405.361 391.962C406.702 391.692 407.933 391.029 408.897 390.057C409.862 389.085 410.516 387.849 410.778 386.504C411.04 385.159 410.897 383.767 410.367 382.504C409.838 381.241 408.945 380.163 407.804 379.408C406.662 378.653 405.322 378.255 403.954 378.264C402.129 378.275 400.383 379.009 399.097 380.305C397.81 381.601 397.088 383.354 397.088 385.181Z'
								fill='#4EFCFE'
							/>
							<path
								d='M399.148 385.353C399.148 386.323 399.436 387.272 399.976 388.078C400.515 388.885 401.281 389.512 402.178 389.882C403.074 390.251 404.06 390.346 405.01 390.153C405.96 389.961 406.832 389.49 407.514 388.801C408.196 388.111 408.659 387.235 408.843 386.282C409.027 385.329 408.924 384.343 408.547 383.448C408.17 382.554 407.536 381.792 406.726 381.259C405.916 380.726 404.966 380.446 403.997 380.455C402.707 380.466 401.474 380.987 400.566 381.904C399.657 382.822 399.148 384.061 399.148 385.353Z'
								fill='#2AFBFE'
							/>
						</g>
						<path
							d='M379.753 259.122C380.48 259.38 381.109 259.857 381.554 260.488C382 261.119 382.239 261.872 382.239 262.645C382.239 263.417 382 264.171 381.554 264.802C381.109 265.433 380.48 265.91 379.753 266.168C377.871 267.123 375.791 267.621 373.681 267.621C371.571 267.621 369.491 267.123 367.609 266.168C364.648 264.449 364.262 261.872 366.536 259.852C366.854 259.575 367.199 259.33 367.566 259.122L368.811 258.52C370.577 257.858 372.461 257.569 374.345 257.673C376.228 257.776 378.069 258.27 379.753 259.122Z'
							fill='#CB83FF'
						/>
						<path
							d='M442.058 306.598C442.785 306.856 443.415 307.333 443.86 307.964C444.305 308.595 444.544 309.349 444.544 310.121C444.544 310.894 444.305 311.647 443.86 312.278C443.415 312.909 442.785 313.386 442.058 313.644C440.168 314.596 438.081 315.092 435.965 315.092C433.849 315.092 431.762 314.596 429.872 313.644C426.911 311.926 426.567 309.348 428.842 307.285C429.137 307.011 429.469 306.78 429.829 306.598C430.221 306.355 430.639 306.153 431.073 305.997C432.845 305.328 434.738 305.036 436.629 305.14C438.52 305.243 440.369 305.74 442.058 306.598Z'
							fill='#2AFBFE'
						/>
						<g>
							<path
								opacity='0.17'
								d='M421.075 309.606C421.075 312.571 421.953 315.47 423.599 317.936C425.244 320.402 427.583 322.324 430.32 323.459C433.056 324.594 436.067 324.891 438.972 324.312C441.877 323.734 444.546 322.306 446.64 320.209C448.734 318.111 450.161 315.44 450.738 312.531C451.316 309.622 451.02 306.607 449.886 303.867C448.753 301.127 446.833 298.786 444.371 297.138C441.908 295.49 439.012 294.611 436.051 294.611C432.079 294.611 428.27 296.191 425.461 299.003C422.653 301.815 421.075 305.629 421.075 309.606Z'
								fill='#DCFEFF'
							/>
							<path
								opacity='0.33'
								d='M423.135 309.778C423.135 312.327 423.89 314.819 425.304 316.939C426.719 319.058 428.729 320.71 431.081 321.686C433.434 322.661 436.022 322.917 438.519 322.419C441.016 321.922 443.31 320.694 445.11 318.892C446.911 317.089 448.137 314.792 448.633 312.292C449.13 309.792 448.875 307.2 447.901 304.845C446.926 302.49 445.276 300.477 443.16 299.06C441.043 297.644 438.554 296.888 436.008 296.888C432.594 296.888 429.319 298.246 426.905 300.663C424.491 303.081 423.135 306.359 423.135 309.778Z'
								fill='#B8FEFF'
							/>
							<path
								opacity='0.5'
								d='M425.237 309.949C425.237 312.118 425.88 314.238 427.084 316.041C428.289 317.843 430 319.248 432.002 320.075C434.004 320.903 436.206 321.118 438.33 320.692C440.454 320.265 442.403 319.218 443.932 317.681C445.46 316.145 446.499 314.188 446.916 312.06C447.334 309.932 447.111 307.728 446.276 305.727C445.441 303.726 444.032 302.017 442.227 300.819C440.422 299.62 438.302 298.985 436.136 298.993C433.242 299.005 430.47 300.164 428.427 302.217C426.384 304.271 425.237 307.051 425.237 309.949Z'
								fill='#95FDFF'
							/>
							<path
								opacity='0.67'
								d='M427.297 310.121C427.297 311.891 427.822 313.62 428.804 315.091C429.787 316.561 431.183 317.707 432.817 318.382C434.45 319.057 436.247 319.231 437.979 318.883C439.712 318.535 441.302 317.679 442.548 316.425C443.795 315.171 444.642 313.575 444.981 311.838C445.321 310.102 445.138 308.304 444.456 306.671C443.774 305.039 442.623 303.647 441.15 302.67C439.676 301.693 437.946 301.176 436.179 301.185C435.009 301.185 433.851 301.416 432.77 301.866C431.69 302.315 430.709 302.974 429.883 303.805C429.058 304.635 428.405 305.621 427.961 306.705C427.517 307.789 427.291 308.95 427.297 310.121Z'
								fill='#71FCFE'
							/>
							<path
								opacity='0.83'
								d='M429.357 310.293C429.357 311.663 429.763 313.002 430.524 314.141C431.285 315.279 432.366 316.166 433.631 316.688C434.896 317.21 436.287 317.345 437.629 317.074C438.97 316.804 440.201 316.141 441.165 315.169C442.13 314.197 442.784 312.961 443.046 311.616C443.308 310.271 443.165 308.879 442.635 307.616C442.106 306.353 441.214 305.275 440.072 304.521C438.93 303.766 437.59 303.367 436.222 303.376C435.317 303.376 434.421 303.555 433.585 303.903C432.749 304.251 431.99 304.761 431.352 305.404C430.714 306.047 430.209 306.81 429.867 307.649C429.524 308.488 429.351 309.387 429.357 310.293Z'
								fill='#4EFCFE'
							/>
							<path
								d='M431.416 310.465C431.416 311.434 431.703 312.381 432.241 313.186C432.778 313.992 433.542 314.619 434.436 314.99C435.33 315.361 436.313 315.458 437.262 315.269C438.211 315.08 439.083 314.613 439.767 313.928C440.451 313.243 440.917 312.371 441.106 311.42C441.295 310.47 441.198 309.486 440.827 308.591C440.457 307.696 439.83 306.931 439.026 306.392C438.221 305.854 437.276 305.567 436.308 305.567C435.011 305.567 433.766 306.083 432.849 307.001C431.932 307.92 431.416 309.166 431.416 310.465Z'
								fill='#2AFBFE'
							/>
						</g>
						<path
							d='M135.424 410.659L133.407 406.878C134.008 406.535 194.983 373.409 218.24 356.137C254.07 329.541 228.409 231.237 228.152 230.249L232.443 229.132C233.559 233.429 259.09 331.26 220.9 359.617C197.3 377.061 136.067 410.315 135.424 410.659Z'
							fill='#CB83FF'
						/>
						<path
							d='M281.875 320.562C280.73 320.548 279.613 320.205 278.657 319.573C267.415 312.441 268.444 259.938 268.788 249.498H273.079C272.349 272.742 273.851 311.324 280.974 315.835C281.282 316.03 281.639 316.133 282.004 316.133C282.368 316.133 282.726 316.03 283.034 315.835C298.267 308.102 371.514 262.301 372.244 261.828L374.518 265.481C371.471 267.371 300.369 311.84 285.008 319.659C284.044 320.19 282.973 320.498 281.875 320.562Z'
							fill='#CB83FF'
						/>
						<g>
							<path
								opacity='0.17'
								d='M119.204 408.597C119.195 411.566 120.067 414.471 121.71 416.944C123.352 419.416 125.69 421.345 128.429 422.485C131.167 423.625 134.181 423.926 137.09 423.349C139.999 422.771 142.672 421.342 144.769 419.243C146.866 417.143 148.293 414.467 148.869 411.555C149.446 408.642 149.145 405.623 148.007 402.882C146.868 400.14 144.942 397.799 142.472 396.154C140.003 394.51 137.102 393.637 134.136 393.645C130.176 393.645 126.378 395.22 123.577 398.024C120.777 400.828 119.204 404.631 119.204 408.597Z'
								fill='#DCFEFF'
							/>
							<path
								opacity='0.33'
								d='M121.263 408.769C121.263 411.318 122.018 413.81 123.433 415.93C124.847 418.049 126.858 419.702 129.21 420.677C131.562 421.653 134.151 421.908 136.648 421.411C139.145 420.913 141.439 419.686 143.239 417.883C145.039 416.08 146.265 413.784 146.762 411.283C147.259 408.783 147.004 406.191 146.03 403.836C145.055 401.481 143.405 399.468 141.288 398.052C139.171 396.635 136.682 395.879 134.136 395.879C130.722 395.879 127.448 397.237 125.034 399.655C122.62 402.072 121.263 405.35 121.263 408.769Z'
								fill='#B8FEFF'
							/>
							<path
								opacity='0.5'
								d='M123.323 408.941C123.323 411.107 123.965 413.226 125.167 415.027C126.369 416.829 128.078 418.233 130.078 419.063C132.077 419.892 134.277 420.109 136.4 419.686C138.522 419.263 140.472 418.22 142.002 416.688C143.533 415.155 144.575 413.203 144.997 411.078C145.419 408.953 145.202 406.75 144.374 404.748C143.546 402.746 142.144 401.035 140.344 399.831C138.545 398.627 136.429 397.984 134.265 397.984C131.363 397.984 128.58 399.139 126.528 401.193C124.476 403.248 123.323 406.035 123.323 408.941Z'
								fill='#95FDFF'
							/>
							<path
								opacity='0.67'
								d='M125.383 409.113C125.383 410.88 125.906 412.608 126.887 414.077C127.868 415.547 129.262 416.693 130.893 417.369C132.523 418.045 134.318 418.222 136.049 417.877C137.781 417.533 139.371 416.682 140.619 415.432C141.867 414.182 142.717 412.59 143.062 410.856C143.406 409.122 143.229 407.326 142.554 405.693C141.878 404.06 140.734 402.664 139.267 401.682C137.799 400.7 136.073 400.176 134.308 400.176C131.941 400.176 129.671 401.117 127.997 402.793C126.323 404.469 125.383 406.742 125.383 409.113Z'
								fill='#71FCFE'
							/>
							<path
								opacity='0.83'
								d='M127.485 409.284C127.485 410.654 127.892 411.993 128.653 413.132C129.414 414.27 130.495 415.157 131.76 415.679C133.025 416.201 134.416 416.336 135.758 416.065C137.099 415.795 138.33 415.132 139.294 414.16C140.259 413.189 140.913 411.952 141.175 410.607C141.437 409.263 141.294 407.87 140.764 406.607C140.235 405.344 139.342 404.267 138.201 403.512C137.059 402.757 135.719 402.358 134.351 402.367C133.446 402.367 132.549 402.546 131.714 402.894C130.878 403.242 130.119 403.753 129.481 404.396C128.843 405.039 128.338 405.801 127.996 406.64C127.653 407.479 127.48 408.378 127.485 409.284Z'
								fill='#4EFCFE'
							/>
							<path
								d='M129.545 409.456C129.545 410.427 129.833 411.375 130.373 412.182C130.912 412.988 131.679 413.616 132.575 413.985C133.471 414.355 134.457 414.449 135.407 414.257C136.357 414.064 137.229 413.593 137.911 412.904C138.593 412.215 139.056 411.338 139.24 410.385C139.424 409.432 139.321 408.446 138.944 407.552C138.567 406.658 137.933 405.896 137.123 405.363C136.313 404.83 135.363 404.55 134.394 404.558C133.104 404.57 131.871 405.09 130.963 406.008C130.054 406.925 129.545 408.165 129.545 409.456Z'
								fill='#2AFBFE'
							/>
						</g>
						<g>
							<path
								opacity='0.17'
								d='M358.469 262.817C358.469 265.784 359.349 268.685 360.996 271.152C362.644 273.619 364.985 275.541 367.724 276.674C370.463 277.808 373.476 278.102 376.382 277.52C379.289 276.938 381.957 275.506 384.049 273.404C386.142 271.303 387.565 268.627 388.138 265.716C388.711 262.804 388.408 259.788 387.268 257.049C386.128 254.309 384.202 251.971 381.734 250.328C379.265 248.686 376.366 247.813 373.402 247.822C369.438 247.833 365.64 249.418 362.841 252.229C360.041 255.04 358.469 258.847 358.469 262.817Z'
								fill='#DCFEFF'
							/>
							<path
								opacity='0.33'
								d='M360.529 262.989C360.529 265.538 361.284 268.03 362.699 270.15C364.113 272.269 366.124 273.921 368.476 274.897C370.828 275.873 373.416 276.128 375.913 275.63C378.411 275.133 380.704 273.905 382.505 272.103C384.305 270.3 385.531 268.004 386.028 265.503C386.524 263.003 386.269 260.411 385.295 258.056C384.321 255.701 382.671 253.688 380.554 252.271C378.437 250.855 375.948 250.099 373.402 250.099C369.988 250.099 366.714 251.457 364.3 253.874C361.885 256.292 360.529 259.57 360.529 262.989Z'
								fill='#B8FEFF'
							/>
							<path
								opacity='0.5'
								d='M362.589 263.16C362.589 265.327 363.231 267.446 364.433 269.247C365.635 271.049 367.344 272.453 369.343 273.282C371.343 274.112 373.543 274.329 375.666 273.906C377.788 273.483 379.738 272.44 381.268 270.908C382.798 269.375 383.84 267.423 384.263 265.298C384.685 263.173 384.468 260.97 383.64 258.968C382.812 256.966 381.409 255.255 379.61 254.051C377.81 252.847 375.695 252.204 373.531 252.204C372.094 252.204 370.671 252.488 369.343 253.038C368.016 253.589 366.81 254.396 365.794 255.413C364.778 256.431 363.972 257.638 363.422 258.968C362.872 260.297 362.589 261.722 362.589 263.16Z'
								fill='#95FDFF'
							/>
							<path
								opacity='0.67'
								d='M364.648 263.332C364.648 265.1 365.172 266.828 366.153 268.297C367.133 269.767 368.527 270.912 370.158 271.589C371.789 272.265 373.584 272.442 375.315 272.097C377.046 271.753 378.637 270.901 379.885 269.652C381.133 268.402 381.983 266.809 382.327 265.076C382.672 263.342 382.495 261.545 381.82 259.912C381.144 258.279 380 256.884 378.532 255.902C377.065 254.92 375.339 254.396 373.574 254.396C371.207 254.396 368.936 255.337 367.263 257.013C365.589 258.689 364.648 260.962 364.648 263.332Z'
								fill='#71FCFE'
							/>
							<path
								opacity='0.83'
								d='M366.751 263.504C366.888 265.239 367.673 266.858 368.949 268.04C370.226 269.221 371.9 269.877 373.638 269.877C375.376 269.877 377.05 269.221 378.327 268.04C379.603 266.858 380.388 265.239 380.525 263.504C380.6 262.553 380.478 261.596 380.166 260.694C379.854 259.793 379.359 258.965 378.712 258.265C378.065 257.564 377.28 257.004 376.407 256.622C375.534 256.239 374.591 256.042 373.638 256.042C372.685 256.042 371.742 256.239 370.869 256.622C369.996 257.004 369.211 257.564 368.564 258.265C367.918 258.965 367.422 259.793 367.11 260.694C366.798 261.596 366.676 262.553 366.751 263.504Z'
								fill='#4EFCFE'
							/>
							<path
								d='M368.811 263.633C368.802 264.605 369.083 265.558 369.617 266.37C370.151 267.182 370.915 267.817 371.811 268.193C372.706 268.569 373.693 268.669 374.646 268.482C375.599 268.294 376.475 267.827 377.161 267.139C377.848 266.452 378.315 265.575 378.502 264.621C378.689 263.667 378.589 262.678 378.213 261.782C377.838 260.885 377.204 260.12 376.393 259.586C375.582 259.051 374.631 258.77 373.66 258.778C372.377 258.789 371.15 259.304 370.243 260.212C369.336 261.121 368.822 262.349 368.811 263.633Z'
								fill='#2AFBFE'
							/>
						</g>
						<path
							d='M163.401 169.024L163.444 173.664L113.153 202.709V198.068L163.401 169.024Z'
							fill='#41C0FD'
						/>
						<path
							d='M268.874 120.86L268.916 154.029L197.471 195.319L197.386 162.193L268.874 120.86Z'
							fill='#41C0FD'
						/>
						<path
							d='M190.048 158.24L274.237 109.561L274.452 183.246L190.434 231.925L190.048 158.24ZM268.916 153.943V120.775L197.429 162.064V195.233L268.874 153.943H268.916Z'
							fill='#2AFBFE'
						/>
						<path
							d='M274.237 96.3275V101.913L190.048 150.592V145.007L274.237 96.3275Z'
							fill='#2AFBFE'
						/>
						<path
							d='M163.53 210.829L163.616 243.74L113.325 272.785L113.282 239.916L163.53 210.829Z'
							fill='#41C0FD'
						/>
						<path
							d='M107.575 169.626L171.94 132.504L172.24 245.717L107.876 282.839L107.575 169.626ZM163.616 243.697V210.829L113.196 239.874V272.785L163.487 243.697H163.616ZM163.358 150.678V145.995L113.196 175.082V179.723L163.487 150.678H163.358ZM163.358 173.664V168.981L113.196 198.069V202.709L163.401 173.664H163.358ZM163.358 184.105V179.465L113.196 208.509V213.192L163.487 184.105H163.358ZM163.358 161.119V156.478L113.196 185.523V190.163L163.444 161.119H163.358Z'
							fill='#2AFBFE'
						/>
						<path
							d='M100.838 150.42L281.961 45.7147L282.39 188.015L101.267 292.72L100.838 150.42ZM172.112 245.717L171.811 132.504L107.446 169.626L107.79 282.838L172.155 245.717H172.112ZM274.452 183.246L274.237 109.561L190.048 158.24L190.434 231.925L274.623 183.246H274.452ZM274.151 67.2402V54.5225L105.086 152.182V164.728L274.109 67.0253L274.151 67.2402ZM274.151 79.0555V73.3412L189.962 122.106V127.692L274.194 79.0126L274.151 79.0555ZM274.151 89.539V83.9535L190.005 132.461V138.046L274.194 89.3671L274.151 89.539ZM274.151 102.085V96.3274L190.005 145.007V150.592L274.237 101.913L274.151 102.085Z'
							fill='#41C0FD'
						/>
						<path
							d='M274.194 83.7817L274.237 89.3672L190.048 138.046L190.005 132.461L274.194 83.7817Z'
							fill='#2AFBFE'
						/>
						<path
							d='M107.575 159.873L199.359 106.811L199.317 100.925L107.575 153.943V159.873Z'
							fill='#41C0FD'
						/>
						<path
							d='M274.151 67.0683V54.5226L105.086 152.182V164.728L274.151 67.0683ZM270.762 59.5925V65.4786L234.417 86.4885V80.6023L270.719 59.5925H270.762ZM199.317 100.882V106.811L107.532 159.873V153.944L199.317 100.882Z'
							fill='#2AFBFE'
						/>
						<path
							d='M270.804 59.5924V65.5216L234.503 86.5314L234.46 80.6023L270.804 59.5924Z'
							fill='#41C0FD'
						/>
						<path
							d='M274.194 73.3412V78.9266L190.005 127.606L189.962 122.02L274.194 73.3412Z'
							fill='#2AFBFE'
						/>
						<g opacity='0.4'>
							<path
								d='M163.444 179.465V184.105L113.196 213.192L113.153 208.552L163.444 179.465Z'
								fill='#41C0FD'
							/>
						</g>
						<path
							d='M163.401 156.479V161.119L113.111 190.163V185.523L163.401 156.479Z'
							fill='#41C0FD'
						/>
						<g opacity='0.4'>
							<path
								d='M163.358 145.995V150.678L113.068 179.723V175.082L163.358 145.995Z'
								fill='#41C0FD'
							/>
						</g>
						<path
							d='M343.837 210.485C346.068 174.524 300.927 103.675 223.689 130.699C179.535 146.167 169.237 171.13 216.137 205.459C216.137 205.459 215.622 224.621 252.439 221.613C252.439 221.613 276.125 220.582 292.174 232.741C309.252 245.545 342.85 226.769 343.837 210.485Z'
							fill='url(#paint1_linear_51_182)'
						/>
						<path
							d='M202.106 149.346C209.939 141.793 220.111 137.14 230.941 136.156C236.886 136.053 242.832 136.297 248.749 136.886C262.437 137.918 279.043 138.476 292.689 132.59L289.041 130.957C276.168 136.414 262.351 134.738 249.006 133.707C242.96 133.13 236.885 132.9 230.812 133.02C219.188 133.968 208.261 138.953 199.917 147.112L202.106 149.346Z'
							fill='#D8CDFF'
						/>
						<path
							d='M252.31 135.297L254.799 134.996C254.799 136.113 254.799 133.664 254.799 133.32C255.443 132.289 260.506 128.594 273.765 126.489L268.874 125.501C257.502 127.305 253.469 128.852 251.967 132.246C251.777 132.745 251.71 133.283 251.769 133.813C251.829 134.344 252.014 134.852 252.31 135.297Z'
							fill='#D8CDFF'
						/>
						<path
							d='M292.174 158.025L295.177 155.877C288.346 147.473 279.783 140.643 270.075 135.855L267.972 134.91L264.111 136.5L268.874 138.605C277.938 143.336 285.883 149.958 292.174 158.025Z'
							fill='#D8CDFF'
						/>
						<path
							d='M335.598 176.328L333.925 173.106C329.779 172.304 325.714 171.124 321.781 169.583C318.804 168.29 316.268 166.155 314.487 163.439C311.934 159.693 308.203 156.911 303.888 155.533C301.08 154.707 298.081 154.803 295.332 155.808C292.583 156.813 290.227 158.675 288.612 161.119L291.187 162.708C292.457 160.811 294.3 159.37 296.446 158.597C298.592 157.824 300.93 157.759 303.116 158.412C306.808 159.642 309.981 162.078 312.127 165.329C314.274 168.445 317.246 170.9 320.709 172.418C325.541 174.178 330.525 175.487 335.598 176.328Z'
							fill='#D8CDFF'
						/>
						<path
							d='M319.164 169.926C326.845 155.061 304.961 143.116 304.06 142.601L302.601 145.265C302.601 145.265 322.811 156.307 316.461 168.552L319.164 169.926Z'
							fill='#D8CDFF'
						/>
						<path
							d='M330.75 228.96C331.393 228.402 344.652 217.274 338.731 205.287C336.337 200.812 333.17 196.797 329.377 193.428C326.158 190.292 323.841 188.058 323.927 184.835C323.618 182.183 322.597 179.663 320.973 177.545C319.348 175.427 317.181 173.789 314.701 172.805C312.879 171.734 310.775 171.243 308.668 171.397C306.56 171.55 304.549 172.341 302.901 173.664L304.918 175.942C306.16 175.06 307.63 174.554 309.152 174.485C310.674 174.417 312.183 174.788 313.5 175.555C315.444 176.299 317.159 177.538 318.477 179.151C319.794 180.764 320.667 182.694 321.009 184.749C321.009 189.046 323.884 192.182 327.36 195.534C330.853 198.691 333.758 202.444 335.942 206.619C340.833 216.543 325.643 231.581 325.515 231.71L330.75 228.96Z'
							fill='#D8CDFF'
						/>
						<path
							d='M341.735 192.526L340.49 188.23C337.891 186.397 334.866 185.261 331.703 184.93C328.541 184.599 325.347 185.084 322.425 186.339L323.498 189.132C323.498 189.132 336.328 184.921 341.735 192.526Z'
							fill='#D8CDFF'
						/>
						<path
							d='M321.267 223.375C334.14 211.474 333.668 199.186 333.625 199.057L330.492 198.412L330.835 200.131C328.806 207.472 325.004 214.2 319.765 219.723L321.267 223.375Z'
							fill='#D8CDFF'
						/>
						<path
							opacity='0.1'
							d='M292.302 232.741C276.254 220.582 252.568 221.613 252.568 221.613C215.751 224.621 216.266 205.458 216.266 205.458C186.229 183.503 179.707 165.372 190.52 151.108C197.287 147.01 204.481 143.668 211.975 141.14C289.213 114.072 334.182 184.921 332.123 220.926C331.768 224.349 330.443 227.599 328.304 230.292C316.847 237.081 302 240.002 292.302 232.741Z'
							fill='black'
						/>
						<path
							d='M321.181 226.382C323.369 190.378 287.368 122.837 201.033 146.596C182.968 151.58 146.58 186.984 193.481 221.313C193.481 221.313 192.966 240.518 229.783 237.51C243.736 241.732 256.92 248.173 268.831 256.587C295.006 275.835 320.194 242.623 321.181 226.382Z'
							fill='url(#paint2_linear_51_182)'
						/>
						<path
							d='M190.906 219.293L193.567 221.313C193.567 221.313 190.434 212.333 193.567 207.65C195.111 205.201 198.544 204.084 203.736 204.255H205.925C218.197 204.728 226.307 205.029 230.297 200.26C232.958 197.123 233.387 192.268 231.756 184.535C228.796 170.442 229.482 160.775 233.859 155.791C235.727 154 237.991 152.678 240.467 151.931C242.943 151.184 245.56 151.035 248.105 151.494C261.364 152.526 266.256 161.29 268.187 187.714C268.856 191.033 270.335 194.135 272.491 196.744C274.647 199.352 277.414 201.387 280.545 202.666C286.123 205.501 298.224 212.462 317.405 200.303L316.418 197.381C298.524 208.767 287.024 202.537 282.09 199.959C279.426 198.927 277.054 197.261 275.177 195.105C273.301 192.949 271.977 190.368 271.319 187.585C269.517 163.267 265.355 149.862 248.448 148.573C245.437 148.044 242.342 148.253 239.428 149.181C236.514 150.108 233.867 151.728 231.714 153.9C226.607 159.701 225.706 169.969 228.924 185.265C230.34 191.882 230.083 196.092 228.109 198.412C225.062 202.021 217.167 201.721 206.182 201.291H203.994C197.643 201.291 193.266 202.709 191.121 206.146C189.976 208.14 189.355 210.392 189.318 212.692C189.28 214.991 189.827 217.263 190.906 219.293Z'
							fill='#D8CDFF'
						/>
						<path
							d='M239.609 221.442L242.613 221.141C240.596 200.131 213.219 201.506 212.962 201.549V204.556C214.12 204.513 237.85 203.353 239.609 221.442Z'
							fill='#D8CDFF'
						/>
						<path
							d='M227.465 178.906L230.426 178.305C230.426 178.047 225.02 152.826 200.904 153.986V156.994C222.66 155.92 227.251 177.961 227.465 178.906Z'
							fill='#D8CDFF'
						/>
						<path
							d='M199.016 172.977C202.449 161.29 216.395 161.076 216.566 161.076V158.025C216.566 158.025 200.175 158.326 196.141 172.118L199.016 172.977Z'
							fill='#D8CDFF'
						/>
						<path
							d='M289.342 227.714L290.457 224.922C261.15 213.45 270.332 187.241 270.719 186.253L267.887 185.179C266.439 189.379 265.847 193.828 266.147 198.261C266.447 202.694 267.632 207.022 269.633 210.988C271.634 214.955 274.409 218.479 277.794 221.352C281.179 224.225 285.106 226.388 289.342 227.714Z'
							fill='#D8CDFF'
						/>
						<path
							d='M272.993 236.136L275.01 233.901C266.428 226.082 273.036 212.977 273.122 212.849L270.418 211.474C270.118 212.075 262.78 226.769 272.993 236.136Z'
							fill='#D8CDFF'
						/>
						<path
							d='M266.428 173.407L268.916 171.645C268.916 171.645 258.661 157.467 245.359 162.794L246.475 165.587C257.588 161.162 266.342 173.278 266.428 173.407Z'
							fill='#D8CDFF'
						/>
						<path
							d='M284.536 252.849C298.31 251.603 298.782 240.948 301.7 231.366C302.383 228.121 303.54 224.994 305.132 222.086C306.742 219.501 309.008 217.391 311.7 215.972C314.392 214.552 317.412 213.875 320.451 214.009L319.936 211.001C316.404 210.75 312.874 211.529 309.774 213.245C306.675 214.961 304.139 217.539 302.472 220.668C300.948 223.81 299.727 227.09 298.825 230.464C295.821 240.174 290.243 251.431 278.442 252.505L284.536 252.849Z'
							fill='#D8CDFF'
						/>
						<path
							d='M279.687 201.506L282.69 201.248C281.661 189.69 300.841 187.155 301.056 187.113L300.67 184.105C299.769 184.234 278.4 187.07 279.687 201.506Z'
							fill='#D8CDFF'
						/>
						<path
							d='M286.252 190.85L288.827 189.261C283.334 180.281 292.088 168.68 292.174 168.552L289.814 166.704C289.384 167.435 279.644 180.281 286.252 190.85Z'
							fill='#D8CDFF'
						/>
						<path
							d='M197.257 203.74L199.96 202.451C198.045 197.841 194.585 194.043 190.177 191.71L186.444 189.046C178.977 183.718 184.126 173.407 184.212 173.321L181.509 171.946C179.235 176.242 177.218 186.038 184.684 191.538L188.46 194.202C192.439 196.223 195.56 199.608 197.257 203.74Z'
							fill='#D8CDFF'
						/>
						<path
							d='M178.248 194.116C181.171 193.992 184.005 193.071 186.444 191.452L184.727 188.96C182.368 190.544 179.527 191.244 176.703 190.936C174.772 190.464 171.768 186.64 170.567 184.62L170.824 189.347C172.498 192.268 173.27 193.256 176.059 193.643C176.768 193.886 177.502 194.045 178.248 194.116Z'
							fill='#D8CDFF'
						/>
						<path
							d='M239.18 237.124C239.523 237.124 239.738 237.124 240.038 236.565C241.068 235.491 240.038 234.417 239.523 233.944L232.357 227.414C231.309 226.595 230.465 225.545 229.892 224.344C229.319 223.144 229.031 221.827 229.053 220.496C229.525 215.083 236.047 210.7 236.133 210.657L234.46 208.122C232.302 209.476 230.433 211.243 228.96 213.322C227.488 215.402 226.44 217.752 225.878 220.238C225.8 222.035 226.149 223.824 226.895 225.459C227.64 227.095 228.762 228.53 230.169 229.648C233.945 233.128 236.09 235.104 237.421 236.135L237.721 236.436C238.129 236.804 238.637 237.043 239.18 237.124Z'
							fill='#D8CDFF'
						/>
						<path
							d='M214.335 227.113C214.945 225.99 215.777 225.003 216.78 224.213C217.784 223.422 218.937 222.845 220.171 222.516C222.438 222.099 224.778 222.543 226.736 223.762L228.41 221.227C225.76 219.581 222.593 218.984 219.527 219.551C217.916 219.975 216.408 220.724 215.095 221.75C213.781 222.777 212.691 224.061 211.889 225.523L214.335 227.113Z'
							fill='#D8CDFF'
						/>
						<path
							d='M231.971 191.237C231.971 191.237 239.18 183.632 246.045 183.632C247.249 183.663 248.432 183.946 249.519 184.464C250.606 184.982 251.571 185.724 252.353 186.64L254.627 184.621C253.575 183.368 252.261 182.361 250.779 181.67C249.296 180.98 247.681 180.623 246.045 180.625C237.936 180.625 229.997 188.831 229.697 189.218L231.971 191.237Z'
							fill='#D8CDFF'
						/>
						<path
							d='M205.796 184.62C214.078 176.672 206.397 163.138 206.053 162.365L203.436 163.868C203.436 163.868 210.301 176.113 203.693 182.429L205.796 184.62Z'
							fill='#D8CDFF'
						/>
						<path
							d='M294.105 244.9L296.25 242.752C294.139 240.835 291.485 239.624 288.656 239.285C285.826 238.946 282.962 239.497 280.459 240.862L281.789 243.568C283.724 242.476 285.952 242.017 288.16 242.255C290.369 242.494 292.447 243.419 294.105 244.9Z'
							fill='#D8CDFF'
						/>
						<path
							d='M258.103 227.027C260.806 221.957 269.002 222.73 269.088 222.73L269.431 219.68C269.002 219.68 259.09 218.649 255.443 225.523L258.103 227.027Z'
							fill='#D8CDFF'
						/>
						<path
							opacity='0.4'
							d='M200.346 101.956C231.799 83.7818 257.374 98.3898 257.502 134.566C257.631 170.743 232.186 214.825 200.733 232.999C169.28 251.173 143.705 236.608 143.577 200.432C143.448 164.255 168.979 120.173 200.346 101.956Z'
							fill='white'
						/>
						<g opacity='0.83' className='opacity-animation-5'>
							<path
								opacity='0.5'
								d='M218.111 105.264C252.439 85.5003 280.159 101.354 280.288 140.71C280.416 180.066 252.696 228.058 218.497 247.822C184.298 267.586 156.45 251.775 156.364 212.376C156.278 172.977 183.869 125.028 218.111 105.264Z'
								fill='white'
							/>
						</g>
						<g opacity='0.67' className='opacity-animation-4'>
							<path
								opacity='0.6'
								d='M235.876 108.53C272.821 87.0471 302.944 104.233 303.073 146.897C303.201 189.561 273.25 241.42 236.305 262.903C199.359 284.385 169.194 267.199 169.108 224.535C169.022 181.871 199.016 129.926 235.876 108.53Z'
								fill='white'
							/>
						</g>
						<g opacity='0.5' className='opacity-animation-3'>
							<path
								opacity='0.7'
								d='M253.598 111.838C293.332 88.8516 325.686 107.284 325.858 153.041C326.03 198.799 293.804 254.524 254.07 277.64C214.335 300.755 181.852 282.065 181.852 236.264C181.852 190.464 213.82 134.996 253.598 111.838Z'
								fill='white'
							/>
						</g>
						<g opacity='0.33' className='opacity-animation-2'>
							<path
								opacity='0.8'
								d='M271.362 115.103C313.886 90.5272 348.6 110.248 348.6 159.185C348.6 208.122 314.272 267.758 271.877 292.377C229.482 316.996 194.639 297.232 194.639 248.295C194.639 199.357 228.796 139.722 271.362 115.103Z'
								fill='white'
							/>
						</g>
						<g opacity='0.17' className='opacity-animation-1'>
							<path
								opacity='0.9'
								d='M289.127 118.411C334.397 92.2028 371.257 113.213 371.385 165.372C371.514 217.532 334.912 280.991 289.856 307.157C244.801 333.322 207.598 312.355 207.341 260.196C207.083 208.036 243.771 144.577 289.127 118.411Z'
								fill='white'
							/>
						</g>
						<g opacity='0.6'>
							<path
								opacity='0.17'
								d='M292.088 231.968C292.088 233.754 292.617 235.5 293.609 236.985C294.602 238.47 296.011 239.626 297.66 240.308C299.31 240.989 301.124 241.165 302.873 240.814C304.622 240.462 306.227 239.599 307.486 238.332C308.744 237.066 309.599 235.455 309.942 233.702C310.285 231.949 310.1 230.133 309.412 228.485C308.723 226.837 307.561 225.431 306.074 224.445C304.586 223.459 302.84 222.937 301.056 222.945C298.674 222.957 296.393 223.912 294.712 225.603C293.031 227.294 292.088 229.582 292.088 231.968Z'
								fill='white'
							/>
							<path
								opacity='0.33'
								d='M293.418 232.054C293.41 233.604 293.861 235.122 294.716 236.414C295.571 237.707 296.79 238.716 298.219 239.313C299.648 239.91 301.222 240.068 302.741 239.768C304.259 239.468 305.655 238.722 306.75 237.626C307.844 236.53 308.589 235.132 308.889 233.612C309.189 232.091 309.031 230.515 308.434 229.084C307.838 227.654 306.83 226.433 305.54 225.577C304.249 224.721 302.733 224.269 301.185 224.277C299.128 224.288 297.159 225.111 295.705 226.567C294.251 228.023 293.429 229.995 293.418 232.054Z'
								fill='white'
							/>
							<path
								opacity='0.5'
								d='M294.577 232.183C294.577 233.483 294.962 234.754 295.683 235.835C296.404 236.916 297.43 237.758 298.629 238.256C299.829 238.753 301.149 238.884 302.423 238.63C303.696 238.376 304.866 237.75 305.784 236.831C306.702 235.912 307.328 234.74 307.581 233.465C307.834 232.19 307.704 230.868 307.207 229.667C306.71 228.466 305.869 227.439 304.789 226.717C303.71 225.995 302.44 225.609 301.142 225.609C299.401 225.609 297.731 226.302 296.499 227.534C295.268 228.767 294.577 230.439 294.577 232.183Z'
								fill='white'
							/>
							<path
								opacity='0.67'
								d='M295.821 232.269C295.821 233.333 296.137 234.373 296.728 235.257C297.319 236.141 298.16 236.829 299.142 237.234C300.125 237.64 301.205 237.743 302.247 237.533C303.289 237.322 304.244 236.806 304.993 236.051C305.741 235.295 306.248 234.334 306.451 233.29C306.653 232.245 306.54 231.164 306.128 230.183C305.715 229.203 305.021 228.367 304.133 227.782C303.246 227.197 302.204 226.889 301.142 226.898C299.727 226.909 298.373 227.48 297.377 228.486C296.38 229.492 295.821 230.852 295.821 232.269Z'
								fill='white'
							/>
							<path
								opacity='0.83'
								d='M297.065 232.355C297.065 233.204 297.317 234.035 297.788 234.742C298.26 235.448 298.93 235.999 299.714 236.324C300.498 236.649 301.361 236.734 302.193 236.569C303.026 236.403 303.79 235.994 304.391 235.393C304.991 234.792 305.399 234.026 305.565 233.193C305.73 232.359 305.645 231.495 305.321 230.71C304.996 229.925 304.446 229.254 303.74 228.782C303.035 228.31 302.205 228.058 301.356 228.058C300.218 228.058 299.127 228.511 298.322 229.317C297.517 230.122 297.065 231.215 297.065 232.355Z'
								fill='white'
							/>
							<path
								d='M298.31 232.483C298.31 232.867 298.385 233.247 298.532 233.601C298.678 233.956 298.893 234.278 299.164 234.549C299.435 234.821 299.757 235.036 300.111 235.183C300.465 235.329 300.844 235.405 301.228 235.405C301.817 235.414 302.395 235.246 302.888 234.923C303.382 234.601 303.768 234.138 303.997 233.595C304.227 233.052 304.289 232.452 304.176 231.873C304.063 231.294 303.78 230.762 303.364 230.344C302.947 229.927 302.416 229.644 301.837 229.531C301.259 229.418 300.66 229.48 300.117 229.71C299.575 229.94 299.113 230.326 298.791 230.82C298.469 231.314 298.301 231.894 298.31 232.483Z'
								fill='white'
							/>
						</g>
						<g opacity='0.6'>
							<path
								opacity='0.17'
								d='M181.037 191.108C181.003 192.901 181.503 194.664 182.474 196.171C183.445 197.679 184.843 198.862 186.489 199.57C188.135 200.278 189.954 200.479 191.714 200.147C193.475 199.815 195.096 198.965 196.372 197.706C197.648 196.447 198.52 194.836 198.877 193.078C199.234 191.32 199.059 189.496 198.376 187.838C197.693 186.18 196.531 184.764 195.04 183.77C193.549 182.776 191.796 182.249 190.005 182.257C187.652 182.268 185.397 183.2 183.721 184.854C182.045 186.508 181.082 188.753 181.037 191.108Z'
								fill='white'
							/>
							<path
								opacity='0.33'
								d='M182.281 191.194C182.273 192.744 182.725 194.262 183.58 195.554C184.434 196.847 185.654 197.856 187.082 198.453C188.511 199.05 190.085 199.209 191.604 198.908C193.123 198.608 194.518 197.862 195.613 196.766C196.708 195.67 197.452 194.273 197.752 192.752C198.052 191.231 197.894 189.655 197.298 188.225C196.701 186.794 195.694 185.573 194.403 184.717C193.112 183.861 191.596 183.409 190.048 183.417C187.992 183.429 186.023 184.252 184.569 185.708C183.114 187.164 182.293 189.135 182.281 191.194Z'
								fill='white'
							/>
							<path
								opacity='0.5'
								d='M183.526 191.323C183.534 192.621 183.926 193.888 184.653 194.963C185.379 196.039 186.408 196.875 187.608 197.366C188.808 197.857 190.127 197.981 191.398 197.722C192.669 197.464 193.834 196.834 194.748 195.913C195.662 194.992 196.283 193.821 196.533 192.547C196.783 191.273 196.65 189.953 196.152 188.755C195.654 187.556 194.813 186.532 193.734 185.811C192.655 185.091 191.388 184.706 190.091 184.706C189.225 184.706 188.368 184.878 187.569 185.211C186.769 185.544 186.044 186.032 185.433 186.647C184.823 187.262 184.341 187.992 184.013 188.794C183.686 189.597 183.52 190.456 183.526 191.323Z'
								fill='white'
							/>
							<path
								opacity='0.67'
								d='M184.77 191.409C184.77 192.473 185.086 193.513 185.677 194.397C186.268 195.281 187.109 195.969 188.091 196.375C189.074 196.78 190.154 196.884 191.196 196.673C192.238 196.462 193.193 195.946 193.942 195.191C194.69 194.436 195.198 193.475 195.4 192.43C195.602 191.385 195.489 190.304 195.077 189.324C194.664 188.343 193.97 187.507 193.082 186.922C192.195 186.337 191.154 186.03 190.091 186.038C189.389 186.038 188.693 186.177 188.045 186.448C187.396 186.718 186.808 187.115 186.313 187.614C185.819 188.113 185.428 188.706 185.163 189.357C184.898 190.008 184.764 190.706 184.77 191.409Z'
								fill='white'
							/>
							<path
								opacity='0.83'
								d='M186.143 191.495C186.143 192.345 186.395 193.175 186.866 193.882C187.338 194.588 188.008 195.139 188.792 195.464C189.576 195.79 190.439 195.875 191.271 195.709C192.104 195.543 192.868 195.134 193.468 194.533C194.069 193.932 194.477 193.167 194.643 192.333C194.808 191.5 194.723 190.636 194.399 189.851C194.074 189.066 193.524 188.395 192.818 187.922C192.113 187.45 191.283 187.198 190.434 187.198C189.296 187.198 188.205 187.651 187.4 188.457C186.595 189.263 186.143 190.355 186.143 191.495Z'
								fill='white'
							/>
							<path
								d='M187.259 191.624C187.259 192.399 187.566 193.142 188.113 193.69C188.661 194.238 189.403 194.545 190.177 194.545C190.951 194.545 191.693 194.238 192.24 193.69C192.787 193.142 193.095 192.399 193.095 191.624C193.095 190.849 192.787 190.106 192.24 189.558C191.693 189.01 190.951 188.702 190.177 188.702C189.403 188.702 188.661 189.01 188.113 189.558C187.566 190.106 187.259 190.849 187.259 191.624Z'
								fill='white'
							/>
						</g>
						<g opacity='0.6'>
							<path
								opacity='0.17'
								d='M221.458 184.706C221.45 186.493 221.971 188.241 222.956 189.731C223.941 191.22 225.345 192.383 226.991 193.073C228.637 193.762 230.45 193.947 232.201 193.604C233.951 193.261 235.561 192.405 236.826 191.145C238.09 189.884 238.953 188.277 239.304 186.525C239.655 184.774 239.479 182.958 238.798 181.307C238.118 179.655 236.963 178.244 235.48 177.25C233.997 176.257 232.253 175.727 230.469 175.727C228.087 175.727 225.801 176.671 224.112 178.354C222.424 180.037 221.469 182.321 221.458 184.706Z'
								fill='white'
							/>
							<path
								opacity='0.33'
								d='M222.702 184.835C222.702 186.384 223.162 187.897 224.022 189.184C224.882 190.471 226.104 191.473 227.533 192.064C228.963 192.654 230.535 192.807 232.051 192.501C233.568 192.196 234.959 191.447 236.049 190.349C237.14 189.251 237.88 187.854 238.177 186.334C238.473 184.815 238.312 183.241 237.715 181.813C237.117 180.385 236.109 179.167 234.82 178.313C233.53 177.459 232.016 177.007 230.469 177.016C229.446 177.016 228.432 177.218 227.487 177.612C226.542 178.005 225.684 178.582 224.962 179.309C224.24 180.035 223.669 180.898 223.281 181.846C222.893 182.795 222.697 183.81 222.702 184.835Z'
								fill='white'
							/>
							<path
								opacity='0.5'
								d='M223.947 184.921C223.947 186.221 224.332 187.492 225.053 188.573C225.775 189.654 226.8 190.497 228 190.994C229.199 191.492 230.519 191.622 231.793 191.369C233.066 191.115 234.236 190.489 235.154 189.569C236.073 188.65 236.698 187.479 236.951 186.204C237.204 184.928 237.074 183.607 236.578 182.406C236.081 181.204 235.239 180.178 234.159 179.455C233.08 178.733 231.811 178.348 230.512 178.348C228.771 178.348 227.101 179.04 225.87 180.273C224.639 181.506 223.947 183.178 223.947 184.921Z'
								fill='white'
							/>
							<path
								opacity='0.67'
								d='M225.191 185.007C225.191 186.069 225.506 187.108 226.095 187.991C226.685 188.874 227.522 189.562 228.502 189.969C229.482 190.375 230.561 190.482 231.601 190.275C232.642 190.067 233.598 189.556 234.348 188.805C235.098 188.054 235.609 187.097 235.816 186.055C236.023 185.013 235.916 183.933 235.51 182.952C235.104 181.97 234.417 181.132 233.535 180.542C232.653 179.951 231.616 179.636 230.555 179.636C229.132 179.636 227.768 180.202 226.762 181.21C225.756 182.217 225.191 183.583 225.191 185.007Z'
								fill='white'
							/>
							<path
								opacity='0.83'
								d='M226.436 185.136C226.683 186.046 227.222 186.85 227.97 187.423C228.719 187.995 229.634 188.306 230.576 188.306C231.518 188.306 232.434 187.995 233.183 187.423C233.931 186.85 234.47 186.046 234.717 185.136C234.89 184.499 234.914 183.83 234.787 183.182C234.66 182.534 234.386 181.924 233.985 181.4C233.585 180.875 233.069 180.45 232.478 180.158C231.886 179.865 231.236 179.713 230.576 179.713C229.917 179.713 229.266 179.865 228.675 180.158C228.084 180.45 227.568 180.875 227.167 181.4C226.767 181.924 226.493 182.534 226.366 183.182C226.239 183.83 226.263 184.499 226.436 185.136Z'
								fill='white'
							/>
							<path
								d='M227.68 185.222C227.68 185.8 227.851 186.365 228.172 186.845C228.492 187.326 228.948 187.7 229.481 187.921C230.014 188.142 230.601 188.2 231.167 188.087C231.733 187.975 232.253 187.696 232.661 187.288C233.069 186.879 233.347 186.359 233.46 185.792C233.572 185.225 233.514 184.638 233.294 184.104C233.073 183.57 232.699 183.114 232.219 182.793C231.739 182.472 231.175 182.3 230.598 182.3C230.213 182.295 229.831 182.366 229.474 182.511C229.118 182.656 228.794 182.871 228.522 183.143C228.25 183.416 228.035 183.74 227.89 184.097C227.746 184.454 227.674 184.837 227.68 185.222Z'
								fill='white'
							/>
						</g>
						<g opacity='0.6'>
							<path
								opacity='0.17'
								d='M273.25 134.996C273.25 136.782 273.78 138.528 274.772 140.013C275.764 141.498 277.174 142.654 278.823 143.336C280.472 144.017 282.286 144.194 284.035 143.842C285.784 143.49 287.39 142.627 288.648 141.361C289.907 140.094 290.762 138.483 291.105 136.73C291.447 134.977 291.263 133.161 290.574 131.513C289.886 129.865 288.724 128.459 287.236 127.473C285.749 126.487 284.003 125.965 282.219 125.973C279.836 125.985 277.555 126.94 275.874 128.631C274.194 130.322 273.25 132.61 273.25 134.996Z'
								fill='white'
							/>
							<path
								opacity='0.33'
								d='M274.495 134.996C274.495 136.544 274.954 138.058 275.814 139.345C276.674 140.632 277.896 141.634 279.326 142.224C280.755 142.815 282.328 142.967 283.844 142.662C285.36 142.357 286.751 141.608 287.842 140.51C288.932 139.412 289.673 138.015 289.969 136.495C290.266 134.975 290.105 133.402 289.507 131.974C288.909 130.545 287.902 129.327 286.612 128.473C285.322 127.619 283.808 127.168 282.261 127.176C281.238 127.176 280.224 127.379 279.279 127.772C278.334 128.166 277.476 128.742 276.754 129.469C276.033 130.196 275.461 131.058 275.074 132.007C274.686 132.955 274.489 133.971 274.495 134.996Z'
								fill='white'
							/>
							<path
								opacity='0.5'
								d='M275.739 135.211C275.739 136.511 276.124 137.782 276.846 138.863C277.567 139.944 278.592 140.786 279.792 141.284C280.992 141.782 282.312 141.912 283.585 141.658C284.859 141.404 286.028 140.778 286.947 139.859C287.865 138.94 288.49 137.768 288.743 136.493C288.997 135.218 288.867 133.896 288.37 132.695C287.873 131.494 287.031 130.467 285.952 129.745C284.872 129.023 283.603 128.637 282.304 128.637C280.563 128.637 278.893 129.33 277.662 130.562C276.431 131.795 275.739 133.467 275.739 135.211Z'
								fill='white'
							/>
							<path
								opacity='0.67'
								d='M276.983 135.297C276.984 136.361 277.299 137.401 277.891 138.285C278.482 139.169 279.322 139.857 280.305 140.262C281.287 140.668 282.368 140.771 283.41 140.561C284.451 140.35 285.407 139.834 286.155 139.079C286.904 138.323 287.411 137.362 287.613 136.318C287.815 135.273 287.703 134.192 287.29 133.212C286.878 132.231 286.184 131.395 285.296 130.81C284.408 130.225 283.367 129.918 282.304 129.926C280.889 129.937 279.536 130.508 278.539 131.514C277.543 132.52 276.983 133.88 276.983 135.297Z'
								fill='white'
							/>
							<path
								opacity='0.83'
								d='M278.228 135.426C278.475 136.336 279.014 137.139 279.763 137.712C280.511 138.285 281.427 138.595 282.369 138.595C283.311 138.595 284.226 138.285 284.975 137.712C285.723 137.139 286.262 136.336 286.51 135.426C286.682 134.788 286.706 134.12 286.579 133.472C286.453 132.824 286.178 132.214 285.778 131.689C285.377 131.165 284.861 130.74 284.27 130.447C283.679 130.154 283.028 130.002 282.369 130.002C281.709 130.002 281.059 130.154 280.467 130.447C279.876 130.74 279.36 131.165 278.96 131.689C278.559 132.214 278.285 132.824 278.158 133.472C278.031 134.12 278.055 134.788 278.228 135.426Z'
								fill='white'
							/>
							<path
								d='M279.472 135.512C279.466 135.897 279.538 136.279 279.683 136.636C279.827 136.993 280.042 137.318 280.314 137.59C280.586 137.863 280.91 138.078 281.267 138.223C281.623 138.367 282.005 138.439 282.39 138.433C283.164 138.433 283.906 138.125 284.453 137.577C285.001 137.03 285.308 136.286 285.308 135.512C285.308 134.737 285.001 133.994 284.453 133.446C283.906 132.898 283.164 132.59 282.39 132.59C282.005 132.584 281.623 132.656 281.267 132.801C280.91 132.945 280.586 133.16 280.314 133.433C280.042 133.705 279.827 134.03 279.683 134.387C279.538 134.744 279.466 135.126 279.472 135.512Z'
								fill='white'
							/>
						</g>
						<g opacity='0.6'>
							<path
								opacity='0.17'
								d='M312.298 163.825C312.298 165.612 312.828 167.358 313.82 168.843C314.812 170.327 316.222 171.484 317.871 172.165C319.52 172.847 321.334 173.023 323.083 172.671C324.832 172.32 326.438 171.456 327.696 170.19C328.955 168.924 329.81 167.312 330.153 165.559C330.495 163.806 330.311 161.991 329.622 160.343C328.934 158.695 327.772 157.289 326.284 156.303C324.797 155.316 323.051 154.794 321.267 154.803C318.884 154.814 316.603 155.77 314.922 157.461C313.242 159.151 312.298 161.44 312.298 163.825Z'
								fill='white'
							/>
							<path
								opacity='0.33'
								d='M313.543 163.911C313.534 165.462 313.986 166.979 314.841 168.272C315.696 169.564 316.915 170.573 318.344 171.17C319.773 171.768 321.346 171.926 322.865 171.626C324.384 171.325 325.78 170.58 326.874 169.484C327.969 168.387 328.714 166.99 329.014 165.469C329.314 163.948 329.156 162.373 328.559 160.942C327.963 159.511 326.955 158.291 325.664 157.435C324.373 156.579 322.858 156.126 321.309 156.135C319.253 156.146 317.284 156.969 315.83 158.425C314.376 159.881 313.554 161.852 313.543 163.911Z'
								fill='white'
							/>
							<path
								opacity='0.5'
								d='M314.873 164.04C314.881 165.339 315.274 166.605 316 167.681C316.727 168.756 317.755 169.592 318.955 170.083C320.156 170.574 321.474 170.698 322.745 170.439C324.016 170.181 325.182 169.551 326.095 168.63C327.009 167.709 327.63 166.538 327.88 165.264C328.13 163.99 327.998 162.671 327.499 161.472C327.001 160.273 326.16 159.249 325.081 158.529C324.002 157.808 322.735 157.424 321.438 157.424C320.572 157.424 319.715 157.595 318.916 157.928C318.116 158.261 317.391 158.749 316.781 159.364C316.17 159.979 315.688 160.709 315.36 161.511C315.033 162.314 314.867 163.173 314.873 164.04Z'
								fill='white'
							/>
							<path
								opacity='0.67'
								d='M316.032 164.126C316.032 165.188 316.346 166.227 316.936 167.11C317.525 167.993 318.363 168.682 319.343 169.088C320.323 169.495 321.401 169.601 322.442 169.394C323.482 169.186 324.438 168.675 325.188 167.924C325.938 167.173 326.449 166.216 326.656 165.174C326.863 164.132 326.757 163.052 326.351 162.071C325.945 161.09 325.257 160.251 324.375 159.661C323.493 159.071 322.456 158.756 321.395 158.756C319.973 158.756 318.608 159.321 317.603 160.329C316.597 161.336 316.032 162.702 316.032 164.126Z'
								fill='white'
							/>
							<path
								opacity='0.83'
								d='M317.276 164.212C317.276 165.062 317.528 165.893 317.999 166.599C318.471 167.306 319.141 167.856 319.925 168.182C320.709 168.507 321.572 168.592 322.404 168.426C323.236 168.26 324.001 167.851 324.601 167.25C325.201 166.649 325.61 165.884 325.775 165.05C325.941 164.217 325.856 163.353 325.531 162.568C325.207 161.783 324.657 161.112 323.951 160.64C323.245 160.168 322.416 159.916 321.567 159.916C320.429 159.916 319.337 160.368 318.533 161.174C317.728 161.98 317.276 163.073 317.276 164.212Z'
								fill='white'
							/>
							<path
								d='M318.52 164.341C318.52 165.116 318.828 165.859 319.375 166.407C319.922 166.955 320.664 167.263 321.438 167.263C321.854 167.313 322.276 167.275 322.676 167.15C323.075 167.025 323.444 166.816 323.758 166.538C324.071 166.26 324.322 165.918 324.494 165.536C324.666 165.154 324.755 164.739 324.755 164.32C324.755 163.9 324.666 163.486 324.494 163.103C324.322 162.721 324.071 162.379 323.758 162.101C323.444 161.823 323.075 161.614 322.676 161.489C322.276 161.365 321.854 161.326 321.438 161.376C321.051 161.376 320.668 161.453 320.312 161.603C319.955 161.752 319.631 161.971 319.36 162.247C319.088 162.523 318.874 162.85 318.73 163.21C318.586 163.569 318.515 163.954 318.52 164.341Z'
								fill='white'
							/>
						</g>
						<path
							d='M348.514 197.295C348.514 197.295 338.86 189.304 334.612 190.378C330.363 191.452 328.905 190.378 328.218 191.624C325.901 196.522 335.856 202.494 347.356 201.678C347.356 201.678 350.917 199.143 348.514 197.295Z'
							fill='#E6A376'
						/>
						<path
							d='M368.725 209.454C367.399 207.92 366.418 206.117 365.85 204.17C365.62 203.703 365.491 203.193 365.471 202.673C365.452 202.152 365.542 201.634 365.736 201.151C365.931 200.669 366.225 200.233 366.6 199.872C366.974 199.511 367.42 199.233 367.91 199.057L393.012 191.538C395.118 190.47 397.425 189.857 399.783 189.739C402.141 189.621 404.498 189.999 406.7 190.85C407.82 191.481 408.726 192.432 409.301 193.581C409.876 194.731 410.095 196.027 409.929 197.302C409.763 198.577 409.219 199.774 408.369 200.737C407.518 201.701 406.399 202.387 405.155 202.709C405.155 202.709 373.059 212.72 368.725 209.454Z'
							fill='#460689'
						/>
						<path
							d='M374.089 208.466C374.543 207.619 374.812 206.685 374.878 205.726C374.945 204.767 374.807 203.805 374.475 202.904C374.142 202.002 373.622 201.181 372.949 200.496C372.276 199.81 371.465 199.276 370.57 198.928L346.154 195.061C346.4 196.483 346.4 197.936 346.154 199.358C344.91 202.537 343.751 202.623 343.751 202.623C343.751 202.623 369.025 215.813 374.089 208.466Z'
							fill='#460689'
						/>
						<path
							d='M390.566 359.058C390.566 359.058 385.631 360.734 387.434 363.355C389.236 365.976 396.016 369.026 397.174 370.616C398.333 372.206 404.469 377.619 405.756 376.76C407.043 375.901 411.42 376.029 409.489 368.983C407.558 361.937 397.346 357.512 390.566 359.058Z'
							fill='#111114'
						/>
						<path
							d='M408.288 257.145C413.148 266.538 414.43 277.381 411.892 287.651C409.491 302.213 408.7 316.996 409.532 331.733C409.532 331.733 413.137 364.601 410.433 370.401C407.911 371.783 405.04 372.397 402.174 372.168C399.308 371.939 396.571 370.877 394.299 369.112L388.12 308.188L385.503 263.418C385.503 263.418 403.611 261.27 408.288 257.145Z'
							fill='#37007F'
						/>
						<path
							d='M388.12 308.403L386.061 273.3L393.055 271.753C393.055 271.753 397.346 303.247 395.115 310.723C393.441 316.609 399.406 353.473 399.148 371.862C397.384 371.337 395.737 370.477 394.299 369.327L388.12 308.403Z'
							fill='#43008D'
						/>
						<path
							d='M350.145 355.75C350.145 355.75 347.699 360.992 343.108 358.8C338.516 356.609 341.735 355.449 331.908 350.551C322.082 345.653 325.129 341.185 325.944 340.326C327.875 338.306 333.796 342.603 335.942 343.634C338.285 344.605 340.694 345.409 343.151 346.04L351.733 354.633L350.145 355.75Z'
							fill='#111114'
						/>
						<path
							d='M397.131 262.301C397.131 262.301 402.838 277.854 393.398 284.299C384.473 290.579 376.864 298.549 370.999 307.758C368.467 313 354.565 355.02 354.565 355.02C351.618 356.168 348.411 356.478 345.299 355.917C342.187 355.356 339.291 353.945 336.929 351.84C336.929 351.84 347.914 302.43 351.904 295.986C355.895 289.541 373.617 258.091 373.617 258.091C381.09 261.071 389.089 262.503 397.131 262.301Z'
							fill='#37007F'
						/>
						<path
							d='M336.929 351.969C336.929 351.969 347.914 302.559 351.904 296.115C355.895 289.67 371.3 262.344 373.359 258.649L380.311 271.195C375.09 277.233 370.191 283.544 365.635 290.1C363.404 294.396 347.742 333.065 344.481 356.137C341.613 355.509 338.99 354.062 336.929 351.969Z'
							fill='#43008D'
						/>
						<path
							d='M407.001 189.647C407.001 189.647 414.639 190.292 411.034 204.256C407.897 215.445 406.28 227.006 406.228 238.628C407.282 246.02 408.859 253.328 410.948 260.497C410.948 260.497 412.15 271.066 387.348 279.401C387.348 279.401 366.622 281.077 369.197 266.898C371.322 252.955 371.869 238.816 370.827 224.75C369.969 219.379 366.15 208.853 378.852 200.775C391.553 192.698 398.547 189.647 407.001 189.647Z'
							fill='#5D0CA6'
						/>
						<path
							d='M370.656 224.664C371.697 238.73 371.15 252.869 369.025 266.813C367.223 276.652 376.62 279.401 382.542 279.272C384.044 272.957 382.542 260.325 386.146 246.404C389.53 234.208 388.48 221.206 383.186 209.712C382.641 207.959 381.57 206.416 380.119 205.294C378.668 204.172 376.907 203.524 375.076 203.439C367.008 211.044 370.098 219.895 370.656 224.664Z'
							fill='#460689'
						/>
						<path
							d='M400.049 183.16C399.389 186.967 399.684 190.88 400.907 194.545C402.795 198.842 386.533 201.248 386.404 197.553C387.375 195.437 387.878 193.136 387.878 190.807C387.878 188.479 387.375 186.178 386.404 184.062C384.559 180.195 400.049 183.16 400.049 183.16Z'
							fill='#EFB185'
						/>
						<path
							d='M400.693 194.803C399.515 191.546 399.161 188.048 399.663 184.62L387.82 189.819C388.249 189.819 396.016 192.483 397.389 198.412C399.792 197.553 401.508 196.264 400.693 194.803Z'
							fill='#DD9569'
						/>
						<path
							d='M384.258 163.654C382.6 167.319 381.681 171.276 381.555 175.297C381.77 181.183 380.826 182.3 381.04 184.921C380.968 186.306 381.344 187.677 382.113 188.831C383.562 189.961 385.295 190.667 387.12 190.872C388.945 191.076 390.791 190.772 392.454 189.991C398.547 188.144 405.327 180.152 403.224 172.461C401.122 164.771 393.613 158.068 384.258 163.654Z'
							fill='#EFB185'
						/>
						<path
							d='M402.452 180.582C400.434 175.109 397.98 169.807 395.115 164.728C393.892 163.534 392.539 162.482 391.081 161.591C388.661 161.64 386.301 162.354 384.258 163.654C383.686 164.952 383.199 166.287 382.799 167.649C384.13 169.368 387.09 171.387 387.09 172.805C387.729 176.278 387.341 179.862 385.975 183.117C384.902 185.222 388.549 188.659 387.605 190.507C387.588 190.592 387.588 190.679 387.605 190.765C389.209 190.751 390.801 190.49 392.325 189.991C394.564 189.195 396.616 187.95 398.357 186.332C400.099 184.714 401.492 182.757 402.452 180.582Z'
							fill='#E6A376'
						/>
						<path
							d='M382.671 160.99C382.373 162.596 382.585 164.256 383.278 165.736C383.971 167.215 385.109 168.441 386.533 169.239C389.579 171.001 388.978 176.156 388.764 177.102C388.205 178.99 387.859 180.936 387.734 182.902C388.421 184.062 391.081 180.84 392.24 181.14C395.157 181.914 393.827 184.664 390.952 187.499C388.077 190.335 395.243 192.182 399.019 189.819C400.95 188.573 401.68 187.757 404.426 182.945C408.374 175.985 409.06 167.306 400.993 162.193C392.926 157.08 387.82 163.396 382.671 160.99Z'
							fill='#8C2DDF'
						/>
						<path
							d='M399.019 189.819C400.95 188.573 401.68 187.757 404.426 182.945C408.374 175.985 409.06 167.306 400.993 162.193C392.926 157.08 387.734 163.396 382.671 160.99C382.561 161.702 382.561 162.426 382.671 163.138C385.288 163.74 392.411 165.63 394.085 168.938C396.187 172.977 398.805 171.903 398.376 176.758C397.947 181.613 398.762 187.628 390.909 189.647C392.109 190.436 393.505 190.87 394.94 190.9C396.374 190.931 397.788 190.556 399.019 189.819Z'
							fill='#8C2DDF'
						/>
						<path
							d='M386.962 195.147L380.783 199.443C380.783 199.443 397.475 205.158 402.71 197.338C403.087 196.196 403.023 194.953 402.53 193.856C402.038 192.758 401.153 191.885 400.049 191.409C400.049 191.409 399.105 197.725 386.962 195.147Z'
							fill='#DEF0F9'
						/>
						<path
							d='M328.947 210.485C328.947 210.485 322.511 199.701 318.177 199.315C313.843 198.928 312.899 197.166 311.741 198.326C307.922 202.15 315.302 211.216 326.373 214.223C326.373 214.223 330.578 213.063 328.947 210.485Z'
							fill='#E6A376'
						/>
						<path
							d='M347.184 230.206C345.468 229.39 343.408 225.566 343.408 225.566C343.1 225.145 342.882 224.663 342.769 224.153C342.656 223.643 342.649 223.115 342.75 222.602C342.851 222.09 343.057 221.603 343.354 221.174C343.652 220.745 344.036 220.383 344.481 220.11L367.781 208.079C369.633 206.623 371.772 205.575 374.057 205.004C376.342 204.434 378.722 204.355 381.04 204.771C382.259 205.181 383.327 205.946 384.108 206.969C384.888 207.992 385.344 209.226 385.418 210.511C385.492 211.796 385.18 213.074 384.522 214.179C383.864 215.285 382.89 216.169 381.727 216.715C381.727 216.715 352.033 232.612 347.184 230.206Z'
							fill='#460689'
						/>
						<path
							d='M349.201 229.519C349.915 228.882 350.487 228.101 350.878 227.227C351.27 226.353 351.472 225.407 351.472 224.449C351.472 223.491 351.27 222.544 350.878 221.671C350.487 220.797 349.915 220.016 349.201 219.379L327.531 207.521C327.234 208.947 326.697 210.313 325.944 211.559C323.712 214.137 322.597 213.837 322.597 213.837C322.597 213.837 342.035 234.846 349.201 229.519Z'
							fill='#460689'
						/>
						<g opacity='0.83' className='opacity-animation-5'>
							<path
								opacity='0.17'
								d='M335.598 327.694C314.487 339.939 314.573 359.574 335.341 371.69C356.109 383.806 390.051 383.935 411.206 371.69C432.36 359.445 432.274 339.853 411.463 327.694C390.652 315.535 356.538 315.449 335.598 327.694Z'
								fill='#FCFEFD'
							/>
						</g>
						<g opacity='0.67' className='opacity-animation-4'>
							<path
								opacity='0.33'
								d='M333.453 295.685C311.14 308.574 311.226 329.369 333.196 342.173C355.165 354.977 390.995 355.063 413.351 342.173C435.707 329.284 435.621 308.489 413.609 295.685C391.596 282.881 355.594 282.881 333.453 295.685Z'
								fill='#F9FDFC'
							/>
						</g>
						<g opacity='0.5' className='opacity-animation-3'>
							<path
								opacity='0.5'
								d='M331.307 263.891C307.793 277.511 307.879 299.38 331.05 312.871C354.221 326.362 391.939 326.491 415.497 312.871C439.054 299.251 438.925 277.382 415.754 263.891C392.583 250.4 354.65 250.185 331.307 263.891Z'
								fill='#F6FCFA'
							/>
						</g>
						<g opacity='0.33' className='opacity-animation-2'>
							<path
								opacity='0.67'
								d='M329.162 231.667C304.446 245.974 304.532 268.918 328.862 283.225C353.192 297.532 392.883 297.532 417.642 283.225C442.401 268.918 442.273 245.932 417.9 231.667C393.527 217.403 353.492 217.446 329.162 231.667Z'
								fill='#F3FBF8'
							/>
						</g>
						<g opacity='0.17' className='opacity-animation-1'>
							<path
								opacity='0.83'
								d='M327.017 199.658C301.271 214.653 301.271 238.713 326.716 253.579C352.162 268.445 393.827 268.617 419.788 253.579C445.748 238.541 445.534 214.524 420.088 199.658C394.643 184.792 352.72 184.749 327.017 199.658Z'
								fill='#F0FAF7'
							/>
						</g>
						<defs>
							<linearGradient
								id='paint0_linear_51_182'
								x1='75.4786'
								y1='167.563'
								x2='307.364'
								y2='167.563'
								gradientUnits='userSpaceOnUse'>
								<stop stop-color='#2F95E4' />
								<stop offset='1' stop-color='#7133DE' />
							</linearGradient>
							<linearGradient
								id='paint1_linear_51_182'
								x1='185.585'
								y1='180.84'
								x2='343.923'
								y2='180.84'
								gradientUnits='userSpaceOnUse'>
								<stop stop-color='#9ACEFF' />
								<stop offset='1' stop-color='#C185FE' />
							</linearGradient>
							<linearGradient
								id='paint2_linear_51_182'
								x1='170.567'
								y1='202.021'
								x2='321.267'
								y2='202.021'
								gradientUnits='userSpaceOnUse'>
								<stop stop-color='#AAB1FF' />
								<stop offset='1' stop-color='#C986FF' />
							</linearGradient>
						</defs>
					</svg>
					<div className='text-white col-12 col-md-7'>
						<h2>Sign Up For Early Access</h2>
						<p className='mt-10 mb-30'>
							We guarantee you 6-months free for basic tier upon launch
						</p>
						{/* <div className='col-12 col-sm-10 position-relative d-flex align-items-center'>
							<input
								type='text'
								className='w-100 bg-white py-20 border-0 rounded px-20'
								placeholder='Enter Your Email'
							/>
							<Button className='btn-blue auth__section-btn position-absolute'>
								Get Your Ai Model
							</Button>
						</div> */}
						<div>
							<form
								action='https://Codenull.us21.list-manage.com/subscribe/post?u=5b9ae7d1d8f43d3b9652b0323&amp;id=36aebf529a&amp;f_id=0000cbe1f0'
								method='post'
								id='mc-embedded-subscribe-form'
								name='mc-embedded-subscribe-form'
								className='validate'
								target='_blank'
								noValidate
								onSubmit={(event) => {
									event.preventDefault();
									// Add your code here to handle the form submission
									alert(
										"Thanks for signing up! You're guaranteed 6 months free upon our official launch"
									);
								}}>
								<div className='col-12 col-sm-10 position-relative d-flex align-items-center'>
									<input
										type='text'
										className='w-100 bg-white py-20 border-0 rounded px-20'
										name='EMAIL'
										placeholder='Enter Your Email'
										required
									/>
									<Button
										type='submit'
										className='btn-blue auth__section-btn position-absolute'>
										Sign Up
									</Button>
								</div>
							</form>
						</div>
						<div className='d-flex align-items-center gap-20 display-18 mt-30'>
							<p>
								<Check2 /> Completely free
							</p>
							<p>
								<Check2 /> No Credit Card Required
							</p>
							<p>
								<Check2 /> Cancel Anytime
							</p>
						</div>
					</div>
				</div>
			</div>
			<div className='w-100 position-relative my-50'>
				<div className='col-11 mx-auto py-30 d-flex flex-wrap gap-30 align-items-center justify-content-between br--b-light'>
					<img src={logo} alt='logo' width='177' className='cursor--pointer' />
					<ul className='d-flex align-items-center gap-20 display-18'>
						<li>
							<a href='/legal'>Privacy Policy</a>
						</li>
						{/* <li>FAQ</li> */}
						<li>
							<a href='/legal#TermsandConditions'>Terms & Condition </a>
						</li>
					</ul>
					<div className='d-flex align-items-center gap-20'>
						<Twitter color='#5A5653' size='20px' className='cursor--pointer' />
						<Instagram
							color='#5A5653'
							size='20px'
							className='cursor--pointer'
						/>
						<Facebook color='#5A5653' size='20px' className='cursor--pointer' />
					</div>
				</div>
				<p className='text-center py-30'>
					@Copyright 2022{" "}
					<span className='text-primary'>
						<a href='#'>Codenull.ai</a>
					</span>
				</p>
			</div>
		</StyledHome>
	);
}
