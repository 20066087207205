import React from "react";
import { Link } from "react-router-dom";
import { Navbar, Button } from "react-bootstrap";
import logo from "../../assets/Frame 54.png";
import StyledNavigation from "./navigation.style";
import { useMediaQuery } from "react-responsive";

// export default function Navigation() {
//     return (
//         <StyledNavigation>
//             <Navbar bg="dark" className="z-10 w-100 d-flex align-items-center justify-content-between py-10 px-84">
//                 <Navbar.Brand>
//                     <img src={logo} alt="logo" width="170" className="cursor--pointer" />
//                 </Navbar.Brand>
//                 <ul className="d-flex align-items-center text-white gap-30 p-0 m-0">
//                     <li className="cursor--pointer">Home</li>
//                     <li className="cursor--pointer">Services</li>
//                     <li className="cursor--pointer">About</li>
//                     <li className="cursor--pointer">Pricing</li>
//                 </ul>
//                 <ul className="d-flex align-items-center text-white gap-30 p-0 m-0">
//                     <Link to="/dashboard">
//                         <li className='text-white'>Login</li>
//                     </Link>
//                     <Link to="/dashboard">
//                         <Button className="btn-primary">Sign Up</Button>
//                     </Link>

//                 </ul>
//             </Navbar>
//         </StyledNavigation>
//     );
// }

// export default function Navigation() {
// 	const isMobile = useMediaQuery({ maxWidth: 600 });

// 	return (
// 		<StyledNavigation>
// 			<Navbar
// 				bg='dark'
// 				className={`z-10 w-100 d-flex align-items-center justify-content-between py-10 ${
// 					isMobile ? "px-20" : "px-84"
// 				}`}>
// 				<Navbar.Brand>
// 					<img
// 						src={logo}
// 						alt='logo'
// 						width={isMobile ? "120" : "170"}
// 						className='cursor--pointer'
// 					/>
// 				</Navbar.Brand>
// 				<ul
// 					className={`d-flex align-items-center text-white gap-30 p-0 m-0 ${
// 						isMobile ? "d-flex flex-column" : "d-flex"
// 					}`}>
// 					<li className='cursor--pointer'>Home</li>
// 					<li className='cursor--pointer'>Services</li>
// 					<li className='cursor--pointer'>About</li>
// 					<li className='cursor--pointer'>Pricing</li>
// 				</ul>
// 				<ul
// 					className={`d-flex align-items-center text-white gap-30 p-0 m-0 ${
// 						isMobile ? "d-flex flex-column" : "d-flex"
// 					}`}>
// 					<Link to='/dashboard'>
// 						<li className='text-white'>Login</li>
// 					</Link>
// 					<Link to='/dashboard'>
// 						<Button className='btn-primary'>Sign Up</Button>
// 					</Link>
// 				</ul>
// 			</Navbar>
// 		</StyledNavigation>
// 	);
// }

import { Dropdown, DropdownButton } from "react-bootstrap";

export default function Navigation() {
	const isMobile = useMediaQuery({ maxWidth: 600 });

	return (
		<StyledNavigation>
			<Navbar
				bg='dark'
				className={`z-10 w-100 d-flex align-items-center justify-content-between py-10 ${
					isMobile ? "px-20" : "px-84"
				}`}>
				<Navbar.Brand href='/'>
					<img
						src={logo}
						alt='logo'
						width={isMobile ? "120" : "170"}
						className='cursor--pointer'
					/>
				</Navbar.Brand>
				{isMobile ? (
					<DropdownButton id='dropdown-basic-button' title='Menu'>
						<a href='https://discord.gg/bSBr22kx'>
							<Dropdown.Item href='https://discord.gg/bSBr22kx'>
								Discord
							</Dropdown.Item>
						</a>
						<a href='https://calendly.com/codenull-ai/demo'>
							<Dropdown.Item href='https://calendly.com/codenull-ai/demo'>
								Book a demo
							</Dropdown.Item>
						</a>

						<Dropdown.Item href='/#signup'>
							Sign up for early access
						</Dropdown.Item>
						{/* <Dropdown.Item href='#/action-4'>API</Dropdown.Item> */}
						<Dropdown.Divider />
						{/* <Dropdown.Item href='/dashboard'></Dropdown.Item> */}
						<Dropdown.Item href='/codenullai.streamlit.app'>
							Try free Beta
						</Dropdown.Item>
					</DropdownButton>
				) : (
					<>
						<ul className='d-flex align-items-center text-white gap-30 p-0 m-0'>
							<a href='https://discord.gg/bSBr22kx' className='text-white'>
								Discord
							</a>
							<a
								href='https://calendly.com/codenull-ai/demo'
								className='text-white'>
								Book a demo
							</a>
							<a href='/#signup' className='text-white'>
								Sign up for early access
							</a>

							{/* <li className='cursor--pointer'>API</li> */}
						</ul>
						<ul className='d-flex align-items-center text-white gap-30 p-0 m-0'>
							{/* <Link to='/dashboard'>
								<li className='text-white'>Login</li>
							</Link> */}
							<a href='https://codenullai.streamlit.app'>
								<Button className='btn-primary'>Try free Beta</Button>
							</a>
						</ul>
					</>
				)}
			</Navbar>
		</StyledNavigation>
	);
}
